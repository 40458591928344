// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#past-session-container {
  padding: 35px;
}
#past-session-container .new-session-block .category-clock {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 3rem;
  display: flex;
  overflow: scroll;
  border-bottom: 2px solid var(--f3f3);
}
#past-session-container .new-session-block ::-webkit-scrollbar {
  width: 0px !important;
}

@media screen and (max-width: 768px) {
  #past-session-container {
    padding: 15px;
  }
}
@media screen and (max-width: 576px) {
  #past-session-container {
    padding: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/App/PastSessions/PastSessions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACI;EAEE,mBAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,oCAAA;AAAN;AAGI;EACE,qBAAA;AADN;;AAMA;EACE;IACE,aAAA;EAHF;AACF;AAKA;EACE;IACE,YAAA;EAHF;AACF","sourcesContent":["#past-session-container {\n  padding: 35px;\n  .new-session-block {\n    .category-clock {\n      // width: 100%;\n      padding-top: 0.5rem;\n      padding-bottom: 0.5rem;\n      margin-left: 0.5rem;\n      margin-bottom: 3rem;\n      display: flex;\n      overflow: scroll;\n      border-bottom: 2px solid var(--f3f3);\n\n    }\n    ::-webkit-scrollbar {\n      width: 0px !important;\n    }\n  }\n}\n\n@media screen and (max-width: 768px) {\n  #past-session-container {\n    padding: 15px;\n  }\n}\n@media screen and (max-width: 576px) {\n  #past-session-container {\n    padding: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
