// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#home-container {
  padding: 35px;
}
#home-container .session-container {
  display: flex;
  justify-content: space-between;
}

.session-block {
  flex-grow: 1;
  min-width: 32%;
}
.session-block p {
  line-height: 29px;
}

.session-details-block {
  display: flex;
  border: 1px solid var(--0000);
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 40px;
}
.session-details-block .session-date {
  max-width: 75px;
  text-align: center;
  border-right: 1px solid var(--0000);
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: 14px;
}
.session-details-block .session-title {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.session-details-block:hover {
  background-color: var(--f3f3);
}

@media screen and (max-width: 768px) {
  #home-container {
    padding: 15px;
  }
  .session-block {
    padding: 15px;
  }
}
@media screen and (max-width: 576px) {
  #home-container {
    padding: 0px;
  }
  .session-details-block {
    margin-bottom: 10px;
  }
  .session-details-block .session-date {
    padding: 0 5px;
    min-width: 45px;
    max-width: 45px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/App/Home/Home.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;AAEJ;;AAEA;EACE,YAAA;EACA,cAAA;AACF;AAAE;EACE,iBAAA;AAEJ;;AACA;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AAEF;AADE;EACE,eAAA;EACA,kBAAA;EACA,mCAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AAGJ;AADE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAGJ;AADE;EACE,6BAAA;AAGJ;;AACA;EACE;IACE,aAAA;EAEF;EAAA;IAEE,aAAA;EACF;AACF;AACA;EACE;IACE,YAAA;EACF;EACA;IACE,mBAAA;EACF;EAAE;IACE,cAAA;IACA,eAAA;IACA,eAAA;EAEJ;AACF","sourcesContent":["#home-container {\n  padding: 35px;\n  .session-container {\n    display: flex;\n    justify-content: space-between;\n  }\n}\n\n.session-block {\n  flex-grow: 1;\n  min-width: 32%;\n  p {\n    line-height: 29px;\n  }\n}\n.session-details-block {\n  display: flex;\n  border: 1px solid var(--0000);\n  border-radius: 10px;\n  margin-bottom: 15px;\n  min-height: 40px;\n  .session-date {\n    max-width: 75px;\n    text-align: center;\n    border-right: 1px solid var(--0000);\n    display: flex;\n    flex-grow: 1;\n    align-items: center;\n    font-size: 14px;\n  }\n  .session-title {\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n  }\n  &:hover {\n    background-color: var(--f3f3);\n  }\n}\n\n@media screen and (max-width: 768px) {\n  #home-container {\n    padding: 15px;\n  }\n  .session-block {\n    // flex-grow: 0;\n    padding: 15px;\n  }\n}\n@media screen and (max-width: 576px) {\n  #home-container {\n    padding: 0px;\n  }\n  .session-details-block {\n    margin-bottom: 10px;\n    .session-date {\n      padding: 0 5px;\n      min-width: 45px;\n      max-width: 45px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
