// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#session-info-container .info-block {
  padding: 20px;
}
#session-info-container .info-block .button-container {
  display: flex;
  flex-direction: column;
}
#session-info-container .info-block .button-container #button-container {
  justify-content: center;
  margin-bottom: 10px;
}
#session-info-container .info-block .button-container #button-container .btn-block {
  min-width: 160px;
}
#session-info-container .info-block .button-container #button-container .btn-block span {
  justify-content: center;
}

@media screen and (max-width: 576px) {
  #session-info-container .info-block {
    margin-top: 70px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/App/SessionInfo/SessionInfo.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAII;EAEE,aAAA;EACA,sBAAA;AAHN;AAIM;EACE,uBAAA;EACA,mBAAA;AAFR;AAGQ;EACE,gBAAA;AADV;AAEU;EACE,uBAAA;AAAZ;;AAOA;EAEI;IACE,gBAAA;EALJ;AACF","sourcesContent":["#session-info-container {\n  .info-block {\n    padding: 20px;\n    // display: flex;\n    // flex-direction: column;\n    // height: calc(100vh - 75px);\n    .button-container {\n      // margin-top: auto;\n      display: flex;\n      flex-direction: column;\n      #button-container {\n        justify-content: center;\n        margin-bottom: 10px;\n        .btn-block {\n          min-width: 160px;\n          span {\n            justify-content: center;\n          }\n        }\n      }\n    }\n  }\n}\n@media screen and (max-width: 576px) {\n  #session-info-container {\n    .info-block {\n      margin-top: 70px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
