import menuIcon from "../../assets/images/icons/menu-3.svg";
import enpowerLogo from "assets/images/common/EmpowerU_logo.png";
import rightGoIcon from "assets/images/icons/Next-arrow.svg";
import backArrowIcon from "assets/images/icons/Back-arrow.svg";
import Profile96Icon from "assets/images/icons/Profile-96.svg";
import SettingWIcon from "assets/images/icons/Setting-white.svg";
import SettingBIcon from "assets/images/icons/Setting-black.svg";
import AddIcon from "assets/images/icons/Add.svg";
import LogoutIcon from "assets/images/icons/Logout.svg";
import closeIcon from "assets/images/icons/close.svg";
import sessionIcon from "assets/images/icons/laptop.svg";
import sessionGreyIcon from "assets/images/icons/leptop_grey.svg";
import arrowNextIcon from "assets/images/icons/ArrowNext.svg";
import refreshIcon from "assets/images/icons/Refresh.svg";
import helpIcon from "assets/images/icons/Help.svg";
import Profile40Icon from "assets/images/icons/Profile-40.svg";
import sendIcon from "assets/images/icons/Send-icon.svg";
import editIcon from "assets/images/icons/edit.svg";
import subscriptionmodel from "assets/images/icons/subscription-model.png";
import crossicon from "assets/images/icons/crossicon.svg";

export const icons = {
  menuIcon,
  enpowerLogo,
  rightGoIcon,
  backArrowIcon,
  Profile96Icon,
  SettingWIcon,
  SettingBIcon,
  LogoutIcon,
  closeIcon,
  sessionIcon,
  arrowNextIcon,
  refreshIcon,
  helpIcon,
  Profile40Icon,
  AddIcon,
  sendIcon,
  editIcon,
  subscriptionmodel,
  crossicon,
  sessionGreyIcon,
};
