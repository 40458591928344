import Button from "../../../components/inputs/Button/Button";
import TextInput from "../../../components/inputs/TextInput/TextInput";
import Card from "../../../components/layouts/Card/Card";
import { Formik } from "formik";
import * as Yup from "yup";
import "./ResetPassword.scss";
import { useNavigate } from "react-router-dom";
import SliderLayout from "../SliderLayout/SliderLayout";
import { commonRoute, icons } from "untils/constants";
import { useState } from "react";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import { storeLocalStorageData } from "untils/helpers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleResetPassword } from "store/slices";
import { toast } from "react-toastify";
import PreLoader from "components/layouts/PreLoader";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.session);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [loadButton, setLoadButton] = useState(false);

  const [initialValues, setInitialValues] = useState({
    email: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required.")
      .email("Email must be a valid email"),
  });

  const handelreset = async (values, { resetForm }) => {
    setLoadButton(true);
    localStorage.setItem("resetEmail", values.email);

    if (values) {
      const response = await dispatch(
        handleResetPassword({ email: values?.email })
      );
      toast.dismiss();
      if (response?.status === 201) {
        toast.success("Varification code sent to your email");
        navigate(commonRoute.VerifyResetPassword);
      } else if (response?.status === 500) {
        toast.error("Invalid email. Please enter a valid email.");
        resetForm();
      }
    }
    resetForm();
    setLoadButton(false);
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("resetEmail");
    if (storedEmail) {
      setInitialValues((prev) => ({ ...prev, email: storedEmail }));
      localStorage.removeItem("resetEmail");
    }
  }, []);

  return (
    <SliderLayout title="">
      {/* {isLoading && <PreLoader />} */}
      <div id="reset-password-container">
        <div className="d-block d-sm-none">
          <MoblieNavBar
            rightIcon={icons.backArrowIcon}
            handleRightClick={() => navigate(-1)}
          />
        </div>
        <Card className="m-auto bg-ffff card-padding pt-4">
          {!isEmailSent ? (
            <>
              <div className="text-20-400 mb-4">
                <p>Please enter your email to get your varification code:-</p>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) =>
                  handelreset(values, { resetForm })
                }
                enableReinitialize
              >
                {(props) => {
                  const { values, errors, handleChange, handleSubmit } = props;
                  const { email } = values;
                  return (
                    <form>
                      <div className="mb-4">
                        <TextInput
                          placeholder="E-Mail"
                          id="email"
                          value={email}
                          error={errors?.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-4 ">
                        <Button
                          type="submit"
                          btnText="Reset Password Now"
                          btnStyle="GO"
                          className="w-100 m-2  bg-eaea position-relative"
                          text
                          rightIcon={icons.rightGoIcon}
                          iconClass="icon-right-end"
                          onClick={handleSubmit}
                          btnLoading={loadButton}
                          disabled={isLoading}
                        />
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </>
          ) : (
            <>
              <div className="text-20-400 mb-4">
                <p>
                  Vielen Dank. Du erhältst in wenigen Minuten eine Email, mit
                  der du dein Passwort zurücksetzen kannst. Logge dich dann mit
                  dem neuen Passwort hier ein.
                </p>
                <div className="mb-4 ">
                  <Button
                    type="submit"
                    btnText="Zurück zum Login"
                    btnStyle="GO"
                    className="w-100 m-2  bg-eaea position-relative"
                    text
                    rightIcon={icons.rightGoIcon}
                    iconClass="icon-right-end"
                    onClick={() => {
                      navigate(commonRoute.login);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </Card>
      </div>
    </SliderLayout>
  );
};

export default ResetPassword;
