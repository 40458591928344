import Layouts from "pages/Layout/Layouts";
import "./SessionChat.scss";
import React, { useEffect, useRef, useState } from "react";
import { commonRoute, icons } from "untils/constants";
import TextInput from "components/inputs/TextInput/TextInput";
import SessionDetailModal from "../NewSession/SessionDetailModal";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import { useNavigate, useParams } from "react-router";
import BottomNavigationBar from "pages/Layout/BottomNavigationBar/BottomNavigationBar";
import {
  SetChatHistory,
  SetLoading,
  SetSessionQA,
  SwitchToChat,
  SwitchToMenu,
  sendNewSessionOption,
} from "store/slices";
import { useDispatch, useSelector } from "react-redux";
import { getDataFromLocalStorage } from "untils/helpers";
const SessionChat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sessionId } = useParams();
  const [newMessage, setNewMessage] = useState("");
  const [showSessionDetail, setShowSessionDetail] = useState(false);
  const messageContainerRef = useRef(null);
  const userInfo = getDataFromLocalStorage("userInfo");
  const { chatHistory, isLoading } = useSelector((state) => ({
    chatHistory: state.chat.chatHistory,
    isLoading: state.session.isLoading,
  }));

  useEffect(() => {
    if (messageContainerRef.current) {
      const lastChildElement = messageContainerRef.current?.lastElementChild;
      lastChildElement?.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory, isLoading]);
  // fetch chat  history
  useEffect(() => {
    if (sessionId) {
      const query = new URLSearchParams({
        sessionid: sessionId,
      }).toString();
      dispatch(SwitchToChat(query));
    }
  }, [sessionId]);

  useEffect(() => {
    window.addEventListener("popstate", () => {
      dispatch(SetSessionQA({}));
    });
  }, []);
  //send new message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    const newList = [
      ...chatHistory,
      {
        sender_type: "user",
        msg_id: chatHistory?.[chatHistory?.length - 1]?.msg_id,
        message: newMessage,
      },
    ];
    dispatch(SetChatHistory(newList));
    const payload = {
      mode: "chat",
      previous_msg_id: chatHistory?.[chatHistory?.length - 1]?.msg_id,
      session_id: sessionId,
      option: newMessage,
    };
    dispatch(SetLoading(true));
    setNewMessage("");
    const res = await dispatch(sendNewSessionOption(payload));
    if (res.status === 201) {
      if (res?.data?.options !== null) {
        dispatch(
          SetChatHistory([
            ...newList,
            {
              sender_type: "assistant",
              msg_id: res?.data?.msg_id,
              message: res?.data?.question,
            },
          ])
        );
        // if ((res.data?.msg_id - 6) % 5 === 0) {
        //   handleSessionSummaryShow();
        // }
      } else {
        handleSessionSummaryShow();
      }
    }
    if (res.status === 403) {
      navigate(commonRoute.home);
    }
  };
  // switch to menu mode
  const handleSwitchToMenu = async () => {
    const msgId = chatHistory?.[chatHistory?.length - 1]?.msg_id;
    const query = new URLSearchParams({
      sessionid: sessionId,
      msgid: msgId,
    }).toString();
    const data = {
      msg_id: msgId,
      session_id: sessionId,
    };
    const res = await dispatch(SwitchToMenu(query, data));
    if (res) {
      navigate(commonRoute.sessionMenu + `/${msgId}`);
    }
  };
  // show summary
  const handleSessionSummaryShow = () => {
    if (isBrowser) {
      setShowSessionDetail(!showSessionDetail);
    }
    if (isMobile) {
      navigate(
        commonRoute.sessionSummary +
          `/${chatHistory?.[chatHistory?.length - 1]?.msg_id}`
      );
    }
  };
  return (
    <Layouts id="session-chat-container">
      <MobileView>
        <MoblieNavBar
          rightIcon={icons.menuIcon}
          leftIcon={icons.Profile40Icon}
          handleRightClick={handleSwitchToMenu}
          handleLeftClick={() => navigate(commonRoute.home)}
        ></MoblieNavBar>
      </MobileView>
      <div className="row">
        <div className="col-sm-10 col-12 mx-auto fa-center  mb-4  d-sm-flex d-none">
          <button onClick={handleSwitchToMenu}>
            <img src={icons.menuIcon} alt="Go to menu" />
          </button>
          <h2 className="text-center flex-grow-1">Chat with Experts</h2>
        </div>
      </div>
      <div className="p-md-5 b-sm-0000 br-sm-10 col-sm-10 col-12 mx-auto custom-shadow flex-grow-1">
        <div className="chat-container">
          <div className="chat-message-group ">
            <div className="chat-message-area" ref={messageContainerRef}>
              {chatHistory?.map((msgDetails, index) => {
                return (
                  <>
                    <div
                      className={`message-box  ${
                        msgDetails.sender_type === "user" && "flex-row-reverse"
                      }`}
                      key={index}
                    >
                      {msgDetails.sender_type === "assistant" ? (
                        <img src={icons.Profile40Icon} alt="assistant" />
                      ) : (
                        <span className="f-center px-2 text-32-400 lh-40 b-0000 rounded-circle h-max-42 text-uppercase">
                          {userInfo?.first_name?.charAt(0)}
                        </span>
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${msgDetails.message}`,
                        }}
                      ></div>
                    </div>
                  </>
                );
              })}
              {isLoading && (
                <div className="message-box">
                  <img src={icons.Profile40Icon} alt="assistant" />
                  <div>Typing..</div>
                </div>
              )}
            </div>
            <form className="chat-message-field" onSubmit={handleSendMessage}>
              <TextInput
                placeholder={`${userInfo?.first_name}, write something...`}
                value={newMessage}
                onChange={(e) => {
                  setNewMessage(e.target.value);
                }}
              />
              <button type="submit" disabled={isLoading}>
                <img src={icons.sendIcon} alt="send" />
              </button>
            </form>
          </div>
          {/* for browser view */}
          <div className=" bt-0000 position-relative my-5 d-sm-block d-none">
            <img
              src={icons.helpIcon}
              alt="help"
              className="help-icon"
              onClick={handleSessionSummaryShow}
            />
          </div>
          {/* for moblie view */}
          <div className="d-block d-sm-none">
            <BottomNavigationBar
              iconName={icons.helpIcon}
              handleClick={handleSessionSummaryShow}
            />
          </div>
        </div>
      </div>
      {showSessionDetail && (
        <SessionDetailModal handleClose={setShowSessionDetail} />
      )}
    </Layouts>
  );
};

export default SessionChat;
