import React, { useCallback, useEffect } from "react";
import "./SessionInfo.scss";
import Button from "components/inputs/Button/Button";
import Modal from "components/layouts/Modal/Modal";
import Layouts from "pages/Layout/Layouts";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { commonRoute, icons } from "untils/constants";
import { getDataFromLocalStorage, storeLocalStorageData } from "untils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  SetContinueSession,
  SetSessionQA,
  SetSessionSummary,
  continueSessionDetails,
  getStoredSummary,
} from "store/slices";
import PreLoader from "components/layouts/PreLoader/PreLoader";
import moment from "moment";
import BrowserNavbar from "pages/Layout/BrowserNavbar";

const SessionInfo = ({ onClose, sessionTitle }) => {
  const navigate = useNavigate();
  const session_id = getDataFromLocalStorage("session_id");
  const sessionDate = getDataFromLocalStorage("session_date");
  const dispatch = useDispatch();
  const { isLoading, sessionSummary, sessionContinue, isSummaryError } =
    useSelector((state) => ({
      sessionSummary: state.session.sessionSummary,
      isLoading: state.session.isLoading,
      sessionContinue: state.session.sessionContinue,
      isSummaryError: state.session.isSummaryError,
    }));
  const { userDetails } = useSelector((state) => state.user);
  const handleModalClose = () => {
    onClose(false);
    dispatch(SetSessionSummary(""));
    dispatch(SetContinueSession({}));
  };

  useEffect(() => {
    if (session_id) {
      const queryParams = new URLSearchParams({
        sessionid: session_id,
      }).toString();
      dispatch(getStoredSummary(queryParams));
      // dispatch(continueSessionDetails(queryParams));
    }
  }, []);
  // useEffect(() => {
  //   if (isSummaryError) {
  //     if (isMobile) {
  //       navigate(-1);
  //     } else {
  //       handleModalClose();
  //     }
  //     dispatch(SetSessionSummary(false));
  //   }
  // }, [isSummaryError]);
  return (
    <div id="session-info-container">
      {isLoading ? (
        <PreLoader />
      ) : (
        <>
          <BrowserView>
            <Modal closeBtn title={sessionTitle} onHide={handleModalClose}>
              <div>
                <p className="mb-4 text-20-400">
                  {sessionSummary?.catch_phrase}
                </p>
                <p className="mb-3 text-16-400">
                  Summary of your session{" "}
                  {sessionDate && moment(sessionDate).format("DD.MM.YYYY")}
                </p>
                <p
                  className="mb-3 "
                  dangerouslySetInnerHTML={{
                    __html: sessionSummary?.summary?.replace(/\n/g, "<br>"),
                  }}
                ></p>
                <p className="my-3">
                  Would you like to pick up where you left off and continue the
                  session?
                </p>
                <div className="f-center gap-2">
                  <Button
                    disabled={userDetails.sub_status !== "active"}
                    btnText="Continue Session"
                    className="b-0000 bg-ffff"
                    // disabled={sessionContinue?.SessionIsActive !== "true"}
                    onClick={async () => {
                      const queryParams = new URLSearchParams({
                        sessionid: session_id,
                      }).toString();
                      const res = await dispatch(continueSessionDetails(queryParams));
                      if (res) {
                        dispatch(SetSessionQA({}));
                        navigate(
                          commonRoute.sessionMenu + `/${sessionContinue?.msg_id || res.msg_id}`,
                          {
                            state: { sessionId: session_id },
                          }
                        );
                      }
                    }}
                  ></Button>
                  <Button
                    btnText="Return"
                    className="b-0000 bg-ffff"
                    onClick={handleModalClose}
                  ></Button>
                </div>
              </div>
            </Modal>
          </BrowserView>
          <MobileView>
            <MoblieNavBar
              rightIcon={icons.backArrowIcon}
              leftIcon={icons.Profile40Icon}
              handleRightClick={() => navigate(-1)}
              handleLeftClick={() => navigate(commonRoute.home)}
            />
            <div className="info-block">
              <p className="mb-3 text-20-400">{sessionSummary?.catch_phrase}</p>
              <p className="mb-3 text-16-400">
                Summary of your session{" "}
                {sessionDate && moment(sessionDate).format("DD.MM.YYYY")}
              </p>
              <p
                className="mb-3 text-14-400"
                dangerouslySetInnerHTML={{
                  __html: sessionSummary?.summary?.replace(/\n/g, "<br>"),
                }}
              ></p>
              <p className="my-3 text-14-400">
                Would you like to pick up where you left off and continue the
                session?
              </p>
              <div className="button-container">
                <Button
                  disabled={userDetails.sub_status !== "active"}
                  btnText="Continue Session"
                  className="b-0000 bg-ffff"
                  // disabled={sessionContinue?.SessionIsActive !== "true"}
                  onClick={async () => {
                    const queryParams = new URLSearchParams({
                      sessionid: session_id,
                    }).toString();
                    const res = await dispatch(continueSessionDetails(queryParams));
                    if (res) {
                      dispatch(SetSessionQA({}));
                      navigate(
                        commonRoute.sessionMenu + `/${sessionContinue?.msg_id || res.msg_id} `,
                        {
                          state: { sessionId: session_id },
                        }
                      );
                    }
                  }}
                ></Button>
                <Button
                  btnText="Return"
                  className="b-0000 bg-ffff"
                  onClick={() => navigate(-1)}
                ></Button>
              </div>
            </div>
          </MobileView>
        </>
      )}
    </div>
  );
};

export default SessionInfo;
