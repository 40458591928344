// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal-container {
  position: relative;
  border-radius: 6px;
  padding-top: 25px;
}
#modal-container .modal-close-button {
  position: absolute;
  right: 18px;
  top: 25px;
  font-size: 20px;
  color: #a9a9a9;
  height: 30px;
  width: 30px;
}

.modal-block-custom {
  padding-right: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/Modal/Modal.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,iBAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;AAEJ;;AACA;EACE,+BAAA;AAEF","sourcesContent":["#modal-container {\n  position: relative;\n  border-radius: 6px;\n  padding-top: 25px;\n  .modal-close-button {\n    position: absolute;\n    right: 18px;\n    top: 25px;\n    font-size: 20px;\n    color: #a9a9a9;\n    height: 30px;\n    width: 30px;\n  }\n}\n.modal-block-custom {\n  padding-right: unset !important;\n \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
