import React, { useEffect, useRef, useState } from "react";
import "./NewSession.scss";
import { MobileView, isBrowser, isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import OptionLayout from "components/inputs/OptionLayout/OptionLayout";
import PreLoader from "components/layouts/PreLoader/PreLoader";
import Layouts from "pages/Layout/Layouts";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import {
  SetLoading,
  SetSessionQA,
  SwitchToMenu,
  fetchMoreOptions,
  sendNewSessionOption,
} from "store/slices";
import { commonRoute, icons } from "untils/constants";
import { getDataFromLocalStorage } from "untils/helpers";
import SessionDetailModal from "./SessionDetailModal";
import BottomNavigationBar from "pages/Layout/BottomNavigationBar/BottomNavigationBar";
import BrowserNavbar from "pages/Layout/BrowserNavbar";

const AddSessionOptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divRef = useRef(null);
  const { previousMSgId } = useParams();
  const [isSelectedOpt, setIsSelectedOpt] = useState("");
  const [showSessionDetail, setShowSessionDetail] = useState(false);
  const { sessionQADetails, isLoading } = useSelector((state) => ({
    sessionQADetails: state.session.sessionQADetails,
    userDetails: state.user.userDetails,
    isLoading: state.session.isLoading,
  }));

  useEffect(() => {
    console.log("called", divRef.current.height);
    setTimeout(() => {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }, []);

  const session_id = getDataFromLocalStorage("session_id");
  useEffect(() => {
    if (!sessionQADetails.options && !sessionQADetails.msg_id) {
      const payload = {
        sessionid: sessionQADetails.session_id || session_id,
        msgid: parseInt(previousMSgId),
      };
      const query = new URLSearchParams(payload).toString();
      dispatch(SwitchToMenu(query, payload));
    }
  }, [previousMSgId]);
  // for browser back button click
  useEffect(() => {
    window.addEventListener("popstate", () => {
      dispatch(SetSessionQA({}));
    });
  }, []);

  //save new session option
  const saveSessionOption = async (value, i) => {
    setIsSelectedOpt(i);
    dispatch(SetLoading(true));
    const res = await dispatch(
      sendNewSessionOption({
        session_id: sessionQADetails.session_id || session_id,
        option: value,
        previous_msg_id: parseInt(previousMSgId),
      })
    );

    if (res.status === 201 && (res.data?.msg_id - 8) % 7 === 0) {
      if (isBrowser) setShowSessionDetail(!showSessionDetail);
      if (isMobile)
        navigate(commonRoute.sessionSummary + `/${res.data.msg_id}`);
      setIsSelectedOpt("");
      return;
    }
    if (res.status === 201) {
      if (res.data.options !== null) {
        navigate(commonRoute.sessionMenu + "/" + `${res.data.msg_id}`);
        setIsSelectedOpt("");
      } else {
        handleSessionSummaryShow();
      }
    }
    if (res.status === 403) {
      navigate(commonRoute.home);
    }
  };

  const handleRefresh = async () => {
    const payload = {
      session_id: sessionQADetails.session_id || session_id,
      mode: "menu",
      previous_msg_id: parseInt(previousMSgId),
    };
    dispatch(SetLoading(true));
    const res = await dispatch(fetchMoreOptions(payload));
    if (res.status === 201) {
      if (res.data.options !== null) {
        navigate(commonRoute.sessionMenu + "/" + `${res.data.msg_id}`);
        setIsSelectedOpt("");
      } else {
        handleSessionSummaryShow();
      }
    }
  };
  const handleSessionSummaryShow = () => {
    if (isBrowser) {
      setShowSessionDetail(!showSessionDetail);
    }
    if (isMobile) {
      navigate(commonRoute.sessionSummary + `/${previousMSgId}`);
    }
  };
  return (
    <Layouts id="session-menu-container">
      {isLoading && <PreLoader />}
      <MobileView>
        <MoblieNavBar
          rightIcon={icons.backArrowIcon}
          leftIcon={icons.Profile40Icon}
          handleRightClick={() => {
            dispatch(SetSessionQA({}));
            navigate(-1);
          }}
          handleLeftClick={() => navigate(commonRoute.home)}
        />
      </MobileView>
      <div className="moblie-container pb-0" ref={divRef}>
        <div className="container-fluid h-100">
          <div className="position-relative">
            {isBrowser && <BrowserNavbar />}
          </div>
          <div className="row h-100">
            <div className="col-sm-10 mx-auto px-sm-3 px-0">
              <div className="d-flex flex-column h-100">
                <div className="b-sm-0000 br-sm-10 p-md-5 p-sm-3 px-0 new-session-block flex-grow-1 d-flex flex-column">
                  <div className="px-4">
                    <div>
                      <p className="text-16-400 mb-4">
                        {sessionQADetails?.question}
                      </p>
                    </div>
                    <div className="d-flex justify-content-end flex-grow-1 align-items-end mt-sm-5 mt-0 ">
                      <img
                        src={icons.refreshIcon}
                        alt="next"
                        className="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                    <div className="option-list">
                      {sessionQADetails?.options?.map((opt, index) => {
                        return (
                          <OptionLayout
                            otpValue={opt}
                            key={index}
                            isSelected={isSelectedOpt === index}
                            handleClick={() => saveSessionOption(opt, index)}
                          />
                        );
                      })}
                      <OptionLayout
                        otpValue="Enter my own answer..."
                        iconName={icons.arrowNextIcon}
                        iconAlt="arrow"
                        handleClick={() =>
                          navigate(
                            commonRoute.sessionChat +
                            `/${sessionQADetails?.session_id || session_id}`
                          )
                        }
                      />
                    </div>
                  </div>
                  <>
                    {/* for browser view */}
                    <div className="bt-0000 position-relative mb-5 mt-auto d-none d-sm-block">
                      <img
                        src={icons.helpIcon}
                        alt="help"
                        className="help-icon"
                        onClick={handleSessionSummaryShow}
                      />
                    </div>
                    {/* for mobile view */}
                    <div className="d-block d-sm-none">
                      <BottomNavigationBar
                        iconName={icons.helpIcon}
                        handleClick={handleSessionSummaryShow}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSessionDetail && (
        <SessionDetailModal handleClose={setShowSessionDetail} />
      )}
    </Layouts>
  );
};

export default AddSessionOptions;
