import { createSlice } from "@reduxjs/toolkit";
import { handelCatch, handelResponse } from "./globalSlice";
import { storeLocalStorageData } from "untils/helpers";
import { api } from "services/api";
import { toast } from "react-toastify";
import { resetUserState } from "./userSlice";
import { SetLoading, resetSessionState } from "./sessionSlice";

const initialState = { isAuthenticate: false, userData: {}, authError: "" };
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: (state) => initialState,
    storeUserAuth(state, action) {
      state.isAuthenticate = action.payload;
    },
    storeUserData(state, action) {
      state.userData = action.payload;
    },
    removeUserData(state) {
      state.userData = {};
    },
    setError(state, action) {
      state.authError = action.payload;
    },
  },
});

export const {
  resetAuthState,
  storeUserAuth,
  storeUserData,
  removeUserData,
  setError,
} = authSlice.actions;

export const handelLogin = (credentials) => async (dispatch) => {
  try {
    const basicAuthCredentials = btoa(
      `${credentials.Username}:${credentials.Password}`
    );
    const res = await api.post(
      "/login",
      {},
      { Authorization: `Basic ${basicAuthCredentials}` }
    );
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(setError(""));
      storeLocalStorageData({ token: responseData?.data?.access_token });
      dispatch(storeUserData(responseData?.data));
    }
    return responseData;
  } catch (error) {
    toast.dismiss();
    if (error.response.status == 401) {
      dispatch(setError("Invalid credential."));
      toast.error("Invalid credential.");
    } else {
      toast.error("Error: Please try again!.");
      dispatch(setError("Error: Please try again!."));
    }
    return dispatch(handelCatch(error));
  }
};

export const handelRegister = (credentials) => async (dispatch) => {
  try {
    const res = await api.post("/register", credentials);
    if (res?.status === 201) {
      dispatch(setError(""));
      return res;
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const handleResetPassword = (credentials) => async (dispatch) => {
  try {
    dispatch(SetLoading(true));
    const res = await api.post("/forget-password", credentials);
    if (res?.status === 201) {
      dispatch(setError(""));
      return res;
    }
    dispatch(SetLoading(false));
    return res;
  } catch (error) {
    dispatch(SetLoading(false));
    return dispatch(handelCatch(error));
  }
};

export const handelLogout = () => async (dispatch) => {
  localStorage.clear();
  caches?.keys().then((names) => {
    names.forEach((name) => {
      caches?.delete(name);
    });
  });
  dispatch(storeUserAuth(false));
  dispatch(removeUserData());
  dispatch(resetUserState());
  dispatch(resetSessionState());
};

export const verifyOTP = (Data) => async (dispatch) => {
  try {
    const res = await api.post("/verify-otp", Data);
    toast.dismiss();
    if (res?.status === 201) {
      dispatch(setError(""));
      storeLocalStorageData({ token: res?.data?.access_token });
      dispatch(storeUserData(res?.data));
      return res;
    } else if (res?.status === 409) {
      toast.error("Invalid Otp. Please enter a valid Otp.");
    } else if (res?.status === 410) {
      toast.error("otp expired. Please resend the otp.");
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const verifyNewPassword = (Data) => async (dispatch) => {
  try {
    const res = await api.post("/forget-password/verify", Data);
    toast.dismiss();
    if (res?.status === 200) {
      return res;
    } else if (res?.status === 409) {
      toast.error("Invalid Otp. Please enter a valid Otp.");
    } else if (res?.status === 410) {
      toast.error("otp expired. Please resend the otp.");
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const handelResendSendOTP = (credentials) => async (dispatch) => {
  try {
    const res = await api.post("/resend-otp", credentials);
    toast.dismiss();
    if (res?.status === 200) {
      return res;
    } else if (res?.status === 409) {
      toast.error("Invalid Otp. Please enter a valid Otp.");
    } else if (res?.status === 410) {
      toast.error("otp expired. Please resend the otp.");
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const handelUserUpdatePassword = (credentials) => async (dispatch) => {
  try {
    const res = await api.post("/update-password", credentials);
    if (res?.status === 201) {
      dispatch(setError(""));
      return res;
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export default authSlice.reducer;
