import Button from "components/inputs/Button/Button";
import Label from "components/inputs/Label";
import PillCheckbox from "components/inputs/PillCheckbox/PillCheckbox";
import Modal from "components/layouts/Modal/Modal";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdatePreferences,
  fetchPreferenceList,
  fetchUserPreferences,
} from "store/slices";
import { icons } from "untils/constants";
import * as Yup from "yup";

const AlterPreferences = ({ handleClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPreferenceList());
    dispatch(fetchUserPreferences());
  }, []);
  const { topicList, userDetails } = useSelector((state) => ({
    topicList: state.user.preferenceList,
    userDetails: state.user.userDetails,
  }));

  const initialValues = {
    topics: userDetails?.preferences || [],
  };

  const validationSchema = Yup.object().shape({
    topics: Yup.array()
      .min(1, "Select at least one topic.")
      .max(5, "You can select maximum five topics."),
  });
  const handleSubmit = (e) => {
    dispatch(UpdatePreferences({ preferences: e.topics }));
    onHideModal();
  };
  const onHideModal = () => {
    handleClose(false);
  };
  return (
    <>
      <Modal title="Change Focus Topics" onHide={onHideModal} closeBtn>
        <div className="container-fluid">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(props) => {
              const { values, errors, handleSubmit, setFieldValue } = props;
              const { topics } = values;
              return (
                <form className="row">
                  <div className="mb-2 col-12 ">
                    <Label
                      label="Topics"
                      required
                      className="text-16-600 mb-2"
                    />
                    <div>
                      {topics &&
                        topicList?.map((ele) => {
                          return (
                            <PillCheckbox
                              label={ele.topic}
                              isChecked={topics?.includes(ele.topic)}
                              onChange={(isChecked) => {
                                if (isChecked) {
                                  setFieldValue("topics", [
                                    ...topics,
                                    ele.topic,
                                  ]);
                                } else {
                                  setFieldValue(
                                    "topics",
                                    topics?.filter((pref) => pref !== ele.topic)
                                  );
                                }
                              }}
                            />
                          );
                        })}
                    </div>
                    {errors?.topics && (
                      <span className="text-13-400 pt-1 d-flex align-items-center gap-2">
                        <span style={{ color: "red" }}>{errors?.topics}</span>
                      </span>
                    )}
                  </div>
                  <div className="d-flex justify-content-end w-100">
                    <div className="mb-4 w-max-200 w-100">
                      <Button
                        type="submit"
                        btnText="Continue"
                        btnStyle="GO"
                        className="w-100 m-2 bg-eaea position-relative"
                        text
                        disabled={errors?.topics}
                        rightIcon={icons.rightGoIcon}
                        iconClass="icon-right-end"
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default AlterPreferences;
