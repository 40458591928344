import React from "react";

function OptionLayout({
  handleClick,
  otpValue,
  iconName,
  iconAlt,
  isSelected,
}) {
  // const optionValue =  otpValue.length < 65 ? otpValue : otpValue.slice(0, 35) + "..."
  return (
    <div
      className={`session-option fb-center br-15 p-2 b-0000 m-2 ${
        isSelected && "color-ffff bg-2121"
      } pointer`}
      onClick={handleClick}
    >
      <p className="text-14-400 mb-0">{otpValue}</p>
      {iconName && <img src={iconName} alt={iconAlt} />}
    </div>
  );
}

export default OptionLayout;
