// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#session-chat-container {
  height: calc(100vh - 38px);
  padding: 35px;
  display: flex;
  flex-direction: column;
}
#session-chat-container .chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  margin: 0 auto;
}
#session-chat-container .chat-message-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
#session-chat-container .chat-message-group .chat-message-area {
  height: calc(100vh - 550px);
  flex-grow: 1;
  overflow-y: auto;
}
#session-chat-container .chat-message-field {
  display: flex;
  margin-top: 16px;
  gap: 8px;
}
#session-chat-container .chat-message-field #TextInput-container {
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  #session-chat-container {
    padding: 15px;
  }
}
@media screen and (max-width: 567px) {
  #session-chat-container {
    padding: 0px;
  }
  #session-chat-container .chat-container {
    width: 100%;
  }
  #session-chat-container .chat-message-group {
    padding: 0 15px 15px;
  }
  #session-chat-container .chat-message-group .chat-message-area {
    height: calc(100vh - 240px);
  }
}
.message-box {
  display: flex;
  gap: 8px;
  margin: 10px 5px;
}
.message-box div {
  padding: 8px;
  border: 1px solid var(--0000);
  max-width: calc(100% - 40px);
  border-radius: 10px;
  max-width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/pages/App/SessionChat/SessionChat.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,UAAA;EACA,cAAA;AAEJ;AAAE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAEJ;AAAI;EACE,2BAAA;EACA,YAAA;EACA,gBAAA;AAEN;AACE;EACE,aAAA;EACA,gBAAA;EACA,QAAA;AACJ;AAAI;EACE,YAAA;AAEN;;AAEA;EACE;IACE,aAAA;EACF;AACF;AACA;EACE;IACE,YAAA;EACF;EAAE;IACE,WAAA;EAEJ;EAAE;IACE,oBAAA;EAEJ;EADI;IACE,2BAAA;EAGN;AACF;AACA;EACE,aAAA;EACA,QAAA;EACA,gBAAA;AACF;AAAE;EACE,YAAA;EACA,6BAAA;EACA,4BAAA;EACA,mBAAA;EACA,cAAA;AAEJ","sourcesContent":["#session-chat-container {\n  height: calc(100vh - 38px);\n  padding: 35px;\n  display: flex;\n  flex-direction: column;\n  .chat-container {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 80%;\n    margin: 0 auto;\n  }\n  .chat-message-group {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    // height: calc(100% - 50px);\n    .chat-message-area {\n      height: calc(100vh - 550px);\n      flex-grow: 1;\n      overflow-y: auto;\n    }\n  }\n  .chat-message-field {\n    display: flex;\n    margin-top: 16px;\n    gap: 8px;\n    #TextInput-container {\n      flex-grow: 1;\n    }\n  }\n}\n@media screen and (max-width: 768px) {\n  #session-chat-container {\n    padding: 15px;\n  }\n}\n@media screen and (max-width: 567px) {\n  #session-chat-container {\n    padding: 0px;\n    .chat-container {\n      width: 100%;\n    }\n    .chat-message-group {\n      padding:0 15px 15px;\n      .chat-message-area {\n        height: calc(100vh - 240px);\n      }\n    }\n  }\n}\n.message-box {\n  display: flex;\n  gap: 8px;\n  margin: 10px 5px;\n  div {\n    padding: 8px;\n    border: 1px solid var(--0000);\n    max-width: calc(100% - 40px);\n    border-radius: 10px;\n    max-width: 70%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
