import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { verifyNewPassword } from "store/slices";

import { useNavigate } from "react-router-dom";
import Button from "components/inputs/Button/Button";
import SliderLayout from "../SliderLayout";
import MoblieNavBar from "pages/Layout/MoblieNavBar";
import { commonRoute, icons } from "untils/constants";
import Card from "components/layouts/Card";
import * as Yup from "yup";
import PasswordInput from "components/inputs/PasswordInput";
import TextInput from "components/inputs/TextInput";
import { toast } from "react-toastify";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadButton, setLoadButton] = useState(false);

  const initialValues = {
    newPassword: "",
    // confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("New Password is required.")
      .min(6, "New Password must be at least 6 characters long."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSetPassword = async (values, { resetForm }) => {
    setLoadButton(true);
    const data = {
      otp: localStorage.getItem("otp"),
      email: localStorage.getItem("resetEmail"),
      password: values?.newPassword,
      method: "forgot password",
    };
    if (values) {
      const response = await dispatch(verifyNewPassword(data));
      toast.dismiss();
      if (response?.status === 201) {
        toast.success("Password successfully changed");
        navigate(commonRoute.login);
      } else if (response?.status === 410) {
        toast.error("OTP is Expired please try again");
        resetForm();
      } else if (response?.status === 409) {
        toast.error("OTP is Invalid please try again");
        resetForm();
        navigate(commonRoute.resetPassword);
      }
    }
    resetForm();
    localStorage.clear();
    setLoadButton(false);
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("otp");
    if (!storedEmail) {
      navigate(commonRoute.resetPassword);
    }
  }, []);

  return (
    <SliderLayout title="">
      <div id="reset-password-container">
        <div className="d-block d-sm-none">
          <MoblieNavBar
            rightIcon={icons.backArrowIcon}
            handleRightClick={() => navigate(-1)}
          />
        </div>
        <Card className="m-auto bg-ffff card-padding pt-4">
          <div className="position-relative f-center mb-4">
            <div className="text-24-600">Create New Password</div>
          </div>
          <div className="text-15-400 color-title-navy f-center">
            Your new password must be different from previously used passwords
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) =>
              handleSetPassword(values, { resetForm })
            }
          >
            {(props) => {
              const { values, errors, handleChange, handleSubmit } = props;
              const { confirmPassword, newPassword } = values;

              return (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <div className="mb-3 mt-4">
                    <PasswordInput
                      id="newPassword"
                      placeholder="New Password"
                      name="newPassword"
                      value={newPassword}
                      onChange={handleChange}
                      error={errors.newPassword}
                      label="Add New Password"
                      isRequired
                    />
                  </div>
                  <div className="mb-5 mt-4">
                    <PasswordInput
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={handleChange}
                      error={errors.confirmPassword}
                      label="Confirm Password"
                      isRequired
                    />
                  </div>
                  <div className="ps-5 pe-5 f-center">
                    <Button
                      type="submit"
                      btnText="Reset Password"
                      btnStyle="primary-dark rounded-pill mw-135 cp-10 border border-dark"
                      onClick={handleSubmit}
                      btnLoading={loadButton}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        </Card>
      </div>
    </SliderLayout>
  );
};

export default SetNewPassword;
