import React from "react";
import "./BrowserNavbar.scss";
import { icons } from "untils/constants";
import { useNavigate } from "react-router";

const BrowserNavbar = () => {
  const navigate = useNavigate();
  return (
    <div id="browser-navbar">
      <button onClick={() => navigate(-1)} className="pointer">
        <img src={icons.backArrowIcon} alt="back" />
      </button>
    </div>
  );
};

export default BrowserNavbar;
