import TextArea from "components/inputs/TextArea/TextArea";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { commonRoute, icons } from "untils/constants";

const AddDescription = ({ setSelectOption }) => {

  const [description, setDescription] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const submitDescription = () => {
    let error = false;
    if (description === "") {
      error = true;
      setErrorMsg("Please add description.");
    }
    if (!error) {
      setSelectOption(description)
    }
  };
  return (
    <div className="col-sm-10 col-12 mx-auto">
      <div className="discriotion-block">
        <div>
          <p className="text-16-400 mb-4">
            Please briefly describe what it is about.
          </p>
          <TextArea
            placeholder="Describe your topic..."
            className="br-10 b-0000"
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={errorMsg}
          />
        </div>
        <div
          className="d-flex justify-content-end flex-grow-1 align-items-end mt-2"
          onClick={submitDescription}
        >
          <div className="img-ring">
            <img src={icons.arrowNextIcon} alt="next" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDescription;
