// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#register-container {
  width: 100%;
  min-height: 500px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Registration/Registration.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;AACJ","sourcesContent":["#register-container{\n    width : 100%;\n    min-height: 500px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
