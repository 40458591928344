// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#moblie-navbar {
  position: fixed;
  top: 0;
  background-color: var(--ffff);
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Layout/MoblieNavBar/MoblieNavBar.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;EACA,MAAA;EACA,6BAAA;EACA,aAAA;EACA,8BAAA;EACA,WAAA;EACA,aAAA;AACH","sourcesContent":["#moblie-navbar{\n   position: fixed;\n   top: 0;\n   background-color: var(--ffff);\n   display: flex;\n   justify-content: space-between;\n   width: 100%;\n   padding:15px; \n   // position: fixed;\n   // top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
