import React from "react";
import moment from "moment";

const SessionDetail = ({ date, title, handleClick, showdetail }) => {
  return (
    <div
      className="session-details-block shadow-sm pointer"
      onClick={() => handleClick()}
    >
      {date && (
        <div className="p-sm-2 session-date">
          {moment(date).format("DD MMM")}
        </div>
      )}
      <div className="px-sm-2 px-1 session-title">
        {title}
        {/* {title.length < 35  ? title : title.slice(0, 35) + "..."} */}
      </div>
    </div>
  );
};

export default SessionDetail;
