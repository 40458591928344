import React, { useEffect, useState } from "react";
import { MobileView, isBrowser } from "react-device-detect";
import { useNavigate } from "react-router";
import { commonRoute, icons } from "untils/constants";
import { Formik } from "formik";
import * as Yup from "yup";
import Layouts from "pages/Layout/Layouts";
import MoblieNavBar from "pages/Layout/MoblieNavBar";
import Button from "components/inputs/Button";
import TextInput from "components/inputs/TextInput";
import "./Subscription.scss";

import { useDispatch, useSelector } from "react-redux";
import { CiStar } from "react-icons/ci";
import { CiBellOn } from "react-icons/ci";
import { CiUnlock } from "react-icons/ci";
import BrowserNavbar from "pages/Layout/BrowserNavbar";
import { toast } from "react-toastify";
import {
  VerifyPromocode,
  activeFreeSubscription,
  cancelSubscription,
  getSubscriptionData,
  getUserSubscription,
  startSubscriptionByPromo,
} from "store/slices/subscriptionSlice";
import { fetchUserDetails } from "store/slices";
import moment from "moment";

const Subscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Yearly");
  const [LoadButton, setLoadButton] = useState("Yearly");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [showPromocodeFormCard, setShowPromocodeFormCard] = useState(false);
  const [appliedPromocode, setAppliedPromocode] = useState(null);
  const [isApplied, setIsApplied] = useState(false);

  const { userDetails } = useSelector((state) => state.user);

  const { subscriptionData, userSubDetails, appliedPromoDetails } = useSelector(
    (state) => state.subscription
  );
  const fetchData = async () => {
    await dispatch(getSubscriptionData());
    await dispatch(getUserSubscription());
  };

  useEffect(() => {
    fetchData();
  }, []);
  const initialValues1 = {
    promocode: "",
  };

  const validationSchema = Yup.object().shape({
    promocode: Yup.string().required("Promocode is required"),
  });

  const handleStartSubscription = async () => {
    if (showPromocodeFormCard && isApplied) {
      const payload = {
        user_id: userDetails?.user_id,
        promo_id: appliedPromoDetails.promo_id,
        sub_type: "promo",
        sub_tier_id: subscriptionData?.[0]?.sub_tier_id,
        duration_type: activeTab.toLowerCase(),
      };
      const response = await dispatch(startSubscriptionByPromo(payload));
      if (response?.status === 201) {
        await dispatch(getUserSubscription());
        dispatch(fetchUserDetails());
      }
    } else {
      const payload = {
        user_id: userDetails?.user_id,
        sub_type: "free",
        sub_tier_id: subscriptionData?.[0]?.sub_tier_id,
        duration_type: "custom",
      };
      const response = await dispatch(activeFreeSubscription(payload));
      if (response?.status === 201) {
        await dispatch(getUserSubscription());
        dispatch(fetchUserDetails());
      }
    }
    clearPromocode();
    setShowPromocodeFormCard(false);
  };

  const handleApplyPromocode = async (values, { resetForm }) => {
    setAppliedPromocode(values.promocode);
    setLoadButton(true);

    if (values) {
      const response = await dispatch(VerifyPromocode(values.promocode));
      if (response.promo_id) {
        toast.dismiss();
        toast.success("Verify Promocode successfully");
        setIsApplied(true);
      } else if (response?.status === 403) {
        toast.dismiss();
        toast.error("Promcode does not exists");
      }
    }
    resetForm();

    setLoadButton(false);
    resetForm();
  };

  const clearPromocode = () => {
    setAppliedPromocode(null);
    setIsApplied(false);
  };

  const handlePromoClick = () => {
    setShowPromocodeFormCard(!showPromocodeFormCard);
  };

  const handelCancelSubscription = () => {
    if (!userSubDetails.sub_id) return;
    dispatch(cancelSubscription({ sub_id: userSubDetails.sub_id }));
  };

  return (
    <Layouts>
      <MobileView>
        <MoblieNavBar
          rightIcon={icons.backArrowIcon}
          leftIcon={icons.Profile40Icon}
          handleRightClick={() => navigate(-1)}
          handleLeftClick={() => navigate(commonRoute.home)}
        />
      </MobileView>
      <div id="subscription-container">
        <div className="">
          <div className="col-sm-11 mx-auto px-sm-3 px-0">
            <div className="d-flex flex-column h-100">
              <div className="position-relative">
                {isBrowser && <BrowserNavbar />}
                <h4 className="text-center text-32-400 my-2 ">Subscription</h4>
              </div>

              <div className="mt-5">
                <div className="row row-gap-8">
                  {userDetails?.sub_status &&
                  userDetails?.sub_status === "active" ? (
                    <div className="col-md-7 col-11 mx-auto px-sm-3 px-0 h-100">
                      <div className="d-flex flex-column rounded session-block py-md-5 py-sm-3 py-1 shadow b-0000 px-sm-2 px-1 br-15 text-center">
                        <h5 className="text-center my-2">
                          Details subscription plan
                        </h5>
                        <div className="row justify-content-center">
                          <div className="col-md-11 col-lg-10 col">
                            <div className=" mt-4 px-sm-3 row gx-1">
                              <p className="col-sm-6 col-12 ms-sm-0 ms-3 text-start fw-bold">
                                Type Of subscription :
                              </p>
                              <p className="col-sm-6 col-12 ms-sm-0 ms-3 text-start text-capitalize">
                                {userSubDetails?.sub_type}
                              </p>
                              <p className="col-sm-6 col-12 ms-sm-0 ms-3 text-start fw-bold">
                                Member since :
                              </p>
                              <p className="col-sm-6 col-12 ms-sm-0 ms-3 text-start">
                                {moment(userSubDetails?.sub_start_date).format(
                                  "MMMM DD, YYYY"
                                )}
                              </p>
                              <p className="col-sm-6 col-12 ms-sm-0 ms-3 text-start fw-bold">
                                Subscription valid until :
                              </p>
                              <p className="col-sm-6 col-12 ms-sm-0 ms-3 text-start">
                                {moment(userSubDetails?.sub_end_date).format(
                                  "MMMM DD, YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="f-center gap-3 mt-4">
                          <Button
                            className="h-40 text-center br-10 GO"
                            btnText="Cancel Subscription"
                            onClick={handelCancelSubscription}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-11 col-sm-7 col-md-5 mx-auto px-sm-3 px-0">
                        <div className="d-flex flex-column py-md-5 py-sm-3 py-1 shadow b-0000 px-sm-2 px-1 br-15 text-center">
                          <h5 className="text-center my-2">
                            Activate Your Subscription
                          </h5>

                          <p className="text-16-400 my-2">
                            Enjoy the first{" "}
                            {subscriptionData?.[0]?.free_trial_duration} days
                            for free, then continue at
                            {activeTab === "Yearly"
                              ? ` only ${
                                  subscriptionData?.[0]?.amount_yearly
                                }€/Year (${
                                  subscriptionData?.[0]?.amount_yearly / 12
                                }€/Month).`
                              : `only  ${subscriptionData?.[0]?.amount_monthly}€/month.`}
                          </p>
                          {/* // ) : (
                          //   <p className="text-16-400 my-2">
                          //     Enjoy the first 7 days for free, then continue at
                             
                          //   </p>
                          // )} */}

                          <div className="f-center subscription-tab">
                            <div
                              className={`monthlyTab ${
                                activeTab === "Yearly" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("Yearly")}
                            >
                              Yearly
                            </div>
                            <div
                              className={`monthlyTab ${
                                activeTab === "Monthly" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("Monthly")}
                            >
                              Monthly
                            </div>
                          </div>

                          <div className="tagen-section">
                            <div className="tagen-card">
                              <div className="tagen-img">
                                <div className="icon">
                                  <CiUnlock color="#ffffff" />
                                </div>
                              </div>
                              <div className="tagen-text">
                                {activeTab === "Yearly" ? (
                                  <>
                                    <h6>Today</h6>
                                    <p>Unlock the App with all features.</p>
                                  </>
                                ) : (
                                  <>
                                    <h6>Today</h6>
                                    <p>Unlock the App with all features.</p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="tagen-card">
                              <div className="tagen-img">
                                <div className="icon">
                                  <CiBellOn color="#ffffff" />
                                </div>
                              </div>
                              <div className="tagen-text">
                                {/* {activeTab === "Yearly" ? ( */}
                                <>
                                  <h6>In 8 Days</h6>
                                  <p>
                                    We will send you a reminder that your trial
                                    is ending.
                                  </p>
                                </>
                                {/* ) : (
                                  <>
                                    <h6>In 5 Days</h6>
                                    <p>
                                      We will send you a reminder that your
                                      trial is ending.
                                    </p>
                                  </>
                                )} */}
                              </div>
                            </div>
                            <div className="tagen-card">
                              <div className="tagen-img">
                                <div className="icon">
                                  <CiStar color="#ffffff" />
                                </div>
                              </div>
                              <div className="tagen-text">
                                {/* {activeTab === "Yearly" ? ( */}
                                {/* <> */}
                                <h6>In 10 Days</h6>
                                <p>
                                  Your subscription starts. You can cancel any
                                  time before{" "}
                                  {moment()
                                    .add(10, "days")
                                    .format("MMMM DD, YYYY")}
                                </p>
                                {/* </>
                                ) : (
                                  <>
                                    <h6>In 7 Days</h6>
                                    <p>
                                      Your subscription starts. You can cancel
                                      anytime before February 1.
                                    </p>
                                  </>
                                )} */}
                              </div>
                            </div>
                          </div>

                          <div>
                            <span
                              onClick={handlePromoClick}
                              className="color-54ca text-14-400 text-center cursor-pointer text-decoration-underline pointer"
                            >
                              I have a Promocode
                            </span>
                          </div>

                          {showPromocodeFormCard && (
                            <Formik
                              initialValues={initialValues1}
                              validationSchema={validationSchema}
                              onSubmit={(values, actions) => {
                                handleApplyPromocode(values, actions);
                              }}
                            >
                              {(props) => {
                                const {
                                  values,
                                  errors,
                                  handleSubmit,
                                  handleChange,
                                } = props;
                                const { promocode } = values;
                                return (
                                  <form className="d-flex align-items-center justify-content-center">
                                    <div className="mb-2 col-md-6">
                                      <div className="col-md-12 mt-2">
                                        {}
                                        {isApplied && (
                                          <div className="d-flex flex-row align-items-center justify-content-start cms-10 gap-2">
                                            <p>
                                              <img
                                                src={icons.crossicon}
                                                alt="cross"
                                                className="w-10 h-10"
                                                onClick={clearPromocode}
                                              />
                                            </p>
                                            {appliedPromocode}
                                            <span>applied.</span>
                                          </div>
                                        )}
                                        <div className="d-flex flex-row align-items-center justify-content-center cms-10">
                                          <TextInput
                                            labelClass="text-16-600"
                                            isRequired
                                            placeholder="Enter Promocode"
                                            id="promocode"
                                            value={promocode}
                                            error={errors?.promocode}
                                            onChange={handleChange}
                                          />
                                          <div className="d-flex align-items-center justify-content-center">
                                            <div className="w-max-200 w-100">
                                              <Button
                                                type="submit"
                                                btnText="Apply"
                                                btnStyle="GO ml-2"
                                                className="m-1 bg-eaea position-relative cp-10 ml-2"
                                                text
                                                iconClass="icon-right-end"
                                                onClick={handleSubmit}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                );
                              }}
                            </Formik>
                          )}

                          <div className="d-flex align-items-center justify-content-center">
                            {showPromocodeFormCard ? (
                              <Button
                                btnText={`Start Subscription`}
                                btnStyle="GO"
                                className="w-200 m-2 bg-eaea position-relative"
                                text
                                iconClass="icon-right-end"
                                onClick={handleStartSubscription}
                                disabled={!isApplied ? true : false}
                              />
                            ) : (
                              <Button
                                btnText={`Activate Your Free Trial`}
                                btnStyle="GO"
                                className="w-200 m-2 bg-eaea position-relative"
                                text
                                iconClass="icon-right-end"
                                onClick={handleStartSubscription}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-sm-5 mx-auto px-sm-3 px-0">
                        <div className="d-flex flex-column py-md-5 py-sm-3 py-1 shadow b-0000 px-sm-2 px-1 br-15 text-center">
                          <h5 className="text-center my-2">
                            Activate Your Subscription
                          </h5>
                          <p className="text-16-400 my-2">
                            Enjoy the first 7 days for free, then continue at
                            only 29€/month.
                          </p>

                          <div className="f-center subscription-tab">
                            <div className="monthlyTab active">Yearly</div>
                            <div className="monthlyTab">Monthly</div>
                          </div>

                          <div className="tagen-section">
                            <div className="tagen-card">
                              <div className="tagen-img">
                                <div className="icon">
                                  <CiUnlock color="#ffffff" />
                                </div>
                              </div>
                              <div className="tagen-text">
                                <h6>Today</h6>
                                <p>Unlock the App with all features.</p>
                              </div>
                            </div>
                            <div className="tagen-card">
                              <div className="tagen-img">
                                <div className="icon">
                                  <CiBellOn color="#ffffff" />
                                </div>
                              </div>
                              <div className="tagen-text">
                                <h6>In 5 Days</h6>
                                <p>
                                  We will send you a reminder that your trial is
                                  ending.
                                </p>
                              </div>
                            </div>
                            <div className="tagen-card">
                              <div className="tagen-img">
                                <div className="icon">
                                  <CiStar color="#ffffff" />
                                </div>
                              </div>
                              <div className="tagen-text">
                                <h6>In 7 Days</h6>
                                <p>
                                  Your subscription starts. You can cancel
                                  anytime before February 1.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div>
                            <span
                              onClick={handlePromoClick2}
                              className="color-54ca text-14-400 text-center cursor-pointer text-decoration-underline pointer"
                            >
                              I have a Promocode
                            </span>
                          </div>

                          {showPromocodeFormCard2 && (
                            <Formik
                              initialValues={initialValues2}
                              validationSchema={validationSchema}
                              onSubmit={(values, actions) => {
                                handleApplyPromocode2(values, actions);
                              }}
                            >
                              {(props) => {
                                const {
                                  values,
                                  errors,
                                  handleSubmit,
                                  handleChange,
                                } = props;
                                const { promocode } = values;
                                return (
                                  <form className="d-flex align-items-center justify-content-center">
                                    <div className="mb-2 col-md-6">
                                      <div className="col-md-12 mt-2">
                                        {appliedPromocode2 && (
                                          <div className="d-flex flex-row align-items-center justify-content-start cms-10 gap-2">
                                            <p>
                                              <img
                                                src={icons.crossicon}
                                                alt="cross"
                                                className="w-10 h-10"
                                                onClick={clearPromocode2}
                                              />
                                            </p>
                                            {appliedPromocode2}
                                            <span>applied.</span>
                                          </div>
                                        )}
                                        <div className="d-flex flex-row align-items-center justify-content-center cms-10">
                                          <TextInput
                                            labelClass="text-16-600"
                                            isRequired
                                            placeholder="Enter Promocode"
                                            id="promocode"
                                            value={promocode}
                                            error={errors?.promocode}
                                            onChange={handleChange}
                                          />
                                          <div className="d-flex align-items-center justify-content-center">
                                            <div className="w-max-200 w-100">
                                              <Button
                                                type="submit"
                                                btnText="Apply"
                                                btnStyle="GO"
                                                className="m-2 bg-eaea position-relative cp-12"
                                                text
                                                iconClass="icon-right-end"
                                                onClick={handleSubmit}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                );
                              }}
                            </Formik>
                          )}

                          <div className="d-flex align-items-center justify-content-center">
                            <Button
                              btnText="Activate Your Free Trial"
                              btnStyle="GO"
                              className="w-200 m-2 bg-eaea position-relative"
                              text
                              iconClass="icon-right-end"
                              onClick={handleStartSubscription}
                            />
                          </div>
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default Subscription;
