import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import globalSlice from "./slices/globalSlice";
import userSlice from "./slices/userSlice";
import sessionSlice from "./slices/sessionSlice";
import chatSlice from "./slices/chatSlice";
import subscriptionSlice from "./slices/subscriptionSlice";

const store = configureStore({
  reducer: {
    global: globalSlice,
    auth: authSlice,
    user: userSlice,
    session: sessionSlice,
    chat: chatSlice,
    subscription: subscriptionSlice,
  },
});

export default store;
