import React from "react";
import "./PillCheckbox.scss";
const PillCheckbox = ({ label, isChecked, onChange ,className }) => {
  const toggleCheckbox = (e) => {
    e.preventDefault();
    onChange(!isChecked);
  };

  return (
    <button
      className={`pill-button ${isChecked ? "checked" : ""} ${className ? className : ""}`}
      onClick={toggleCheckbox}
    >
      {label}
    </button>
  );
};

export default PillCheckbox;
