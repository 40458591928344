// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  width: 300px;
  height: 100%;
  background-color: var(--2121);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 700;
  padding-top: 100px;
  overflow-y: auto;
  transition: all 0.2s;
}

.sidebar-overlay {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 699;
  background-color: var(--0000);
  opacity: 0.2;
  display: none;
}

@media (max-width: 992px) {
  .sidebar {
    left: -120%;
  }
  .sidebar-wrap.active .sidebar-overlay {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Layout/LeftContainer/LeftContainer.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,6BAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,eAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,6BAAA;EACA,YAAA;EACA,aAAA;AACJ;;AAEA;EACI;IACI,WAAA;EACN;EACE;IACI,cAAA;EACN;AACF","sourcesContent":[".sidebar{\n    width: 300px;\n    height: 100%; \n    background-color: var(--2121);\n    position: fixed;\n    top: 0;\n    left: 0; \n    z-index: 700;\n    padding-top:100px;\n    overflow-y: auto;\n    transition: all 0.2s;\n} \n\n.sidebar-overlay{\n    position: fixed;\n    inset: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 699;\n    background-color: var(--0000);\n    opacity: 0.2;\n    display: none;\n}\n\n@media (max-width: 992px) {\n    .sidebar{\n        left: -120%;\n    }\n    .sidebar-wrap.active .sidebar-overlay{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
