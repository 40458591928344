import { decrypt, encrypt } from "./encryptdecrypt";

// convert string into title case
export const titleCaseString = (value) => {
  if (typeof value !== "string") return "";
  return value.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase()); // Capital first character of each word
};

// Trim all left space
export const trimLeftSpace = (value) => value.replace(/^\s+/g, ""); // Remove white space from left side

// Create array by given year
export const getYearByCount = (startYear, endYear) => {
  let returnValue = [];
  while (startYear <= endYear) {
    returnValue.push(`${startYear++}`);
  }
  return returnValue;
};

export const objectToFormData = (obj) => {
  let formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};

export function getHeaderData() {
  let header = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (getDataFromLocalStorage("token")) {
    header = {
      ...header,
      ...{ Authorization: `Bearer ${getDataFromLocalStorage("token")}` },
    };
  }
  return header;
}
export const storeLocalStorageData = (newData) => {
  const decryptData = decrypt(localStorage.userData || {});
  localStorage.userData = encrypt({ ...decryptData, ...newData });
};
export function getDataFromLocalStorage(key = "") {
  let returnValue = "";
  if (localStorage.userData) {
    const localObjectData = decrypt(localStorage.userData);
    if (key) {
      returnValue = localObjectData[key] ? localObjectData[key] : "";
    } else {
      returnValue = localObjectData;
    }
  }
  return returnValue;
}
