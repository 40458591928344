// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pill-button {
  border: 1px solid var(--0000);
  border-radius: 20px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  background-color: var(--ffff);
  transition: background-color 0.3s;
}

.pill-button.checked {
  border: 1px solid var(--2121);
  background-color: var(--2121);
  color: var(--ffff);
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/PillCheckbox/PillCheckbox.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,mBAAA;EACA,iBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,6BAAA;EACA,iCAAA;AACJ;;AAEE;EACE,6BAAA;EACA,6BAAA;EACA,kBAAA;AACJ","sourcesContent":[".pill-button {\n    border: 1px solid var(--0000);\n    border-radius: 20px;\n    padding: 5px 10px;\n    margin: 5px;\n    cursor: pointer;\n    font-size: 16px;\n    background-color: var(--ffff);\n    transition: background-color 0.3s;\n  }\n  \n  .pill-button.checked {\n    border:1px solid var(--2121);\n    background-color: var(--2121);\n    color: var(--ffff);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
