// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-wrap {
  display: flex;
  min-height: 100vh;
}

.moblie-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  padding-bottom: 30px;
}

.margin-set {
  margin-top: 100px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Layout/Layouts.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;AACJ;;AACA;EACI,aAAA;EACA,sBAAA;EAEA,2BAAA;EACA,oBAAA;AACJ;;AACA;EACI,iBAAA;AAEJ","sourcesContent":[".sidebar-wrap{\n    display: flex;\n    min-height: 100vh;\n}\n.moblie-container {\n    display: flex;\n    flex-direction: column;\n    // justify-content: space-between;\n    height: calc(100vh - 150px);\n    padding-bottom: 30px;\n}\n.margin-set{\n    margin-top: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
