import { createSlice } from "@reduxjs/toolkit";
import { handelCatch } from "./globalSlice";

import { api } from "services/api";
import { storeLocalStorageData } from "untils/helpers";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  sessionCounts: {},
  pastSessionList: [],
  sessionQADetails: {},
  sessionSummary: "",
  sessionContinue: {},
  isSummaryError: false,
};
const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    resetSessionState: (state) => initialState,
    SetSessionStatus(state, action) {
      state.sessionStatus = action.payload;
    },
    setPastSession(state, action) {
      state.pastSessionList = action.payload;
    },
    SetSessionQA(state, action) {
      state.sessionQADetails = action.payload;
    },
    SetSessionSummary(state, action) {
      state.sessionSummary = action.payload;
    },
    SetLoading(state, action) {
      state.isLoading = action.payload;
    },
    SetContinueSession(state, action) {
      state.sessionContinue = action.payload;
    },
    SetIsSummaryError(state, action) {
      state.isSummaryError = action.payload;
    },
  },
});

export const {
  resetSessionState,
  SetSessionStatus,
  setPastSession,
  SetSessionQA,
  SetLoading,
  SetSessionSummary,
  SetContinueSession,
  SetIsSummaryError,
} = sessionSlice.actions;
export const fetchSessionStatus = () => async (dispatch) => {
  try {
    const res = await api.get("/user/stats");
    dispatch(SetSessionStatus(res));
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const fetchPastSession = (query) => async (dispatch) => {
  try {
    if (query) {
      const res = await api.get("/sessions" + `?${query}`);
      dispatch(setPastSession(res));
      return res;
    } else {
      const res = await api.get("/sessions");
      dispatch(setPastSession(res));
      return res;
    }
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const addSessionOpt = (payload) => async (dispatch, getState) => {
  try {
    if (getState().session.isLoading) return;
    dispatch(SetLoading(true));

    const res = await api.post("/session/start", payload);
    if (res.status === 201) {
      storeLocalStorageData({ session_id: res.data.session_id });
      dispatch(SetSessionQA(res.data));
      dispatch(SetLoading(false));
    }
    return res;
  } catch (error) {
    toast.dismiss();
    if (error.response.status === 403) {
      toast.error("No active subscription found!");
    } else {
      toast.error("Error: Please try again!");
    }

    dispatch(SetLoading(false));
    return dispatch(handelCatch(error));
  }
};
export const sendNewSessionOption = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/session/chat", payload);
    if (res.status === 201) {
      dispatch(SetSessionQA(res.data));
      dispatch(SetLoading(false));
    }
    return res;
  } catch (error) {
    toast.dismiss();
    if (error.response.status === 403) {
      toast.error("No active subscription found!");
    } else {
      toast.error("Error: Please try again!");
    }
    dispatch(SetLoading(false));
    return dispatch(handelCatch(error));
  }
};
export const fetchMoreOptions = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/session/more/options", payload);
    if (res.status === 201) {
      dispatch(SetSessionQA(res.data));
      dispatch(SetLoading(false));
    }
    return res;
  } catch (error) {
    toast.dismiss();
    if (error.response.status === 403) {
      toast.error("No active subscription found!");
    }
    if (error?.response?.status == 500) {
      toast.error("No more options available, Enter manually");
    }
    dispatch(SetLoading(false));
    return dispatch(handelCatch(error));
  }
};
export const handleSessionEnd = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/session/end", payload);
    if (res.status === 201) {
      // toast.success(res.data);
      dispatch(SetLoading(false));
    }
    return res;
  } catch (error) {
    dispatch(SetLoading(false));
    return dispatch(handelCatch(error));
  }
};
export const fetchSessionSummary = (query) => async (dispatch, getState) => {
  try {
    if (getState().session.isLoading) return;
    dispatch(SetLoading(true));
    const res = await api.get("/summary?" + query);
    if (res) {
      dispatch(SetSessionSummary(res));
      dispatch(SetLoading(false));
    }
    return res;
  } catch (error) {
    toast.dismiss();
    dispatch(SetLoading(false));
    dispatch(SetIsSummaryError(true));
    toast.error("Error: Please try again!");
    return dispatch(handelCatch(error));
  }
};
export const getStoredSummary = (query) => async (dispatch, getState) => {
  try {
    if (getState().session.isLoading) return;
    dispatch(SetLoading(true));
    const res = await api.get("/summary/stored?" + query);
    if (res) {
      dispatch(SetSessionSummary(res));
    }
    dispatch(SetLoading(false));
    return res;
  } catch (error) {
    toast.dismiss();
    dispatch(SetLoading(false));
    dispatch(SetIsSummaryError(true));
    toast.error("Error: Please try again!.");
    return dispatch(handelCatch(error));
  }
};
export const continueSessionDetails = (query) => async (dispatch) => {
  try {
    const res = await api.get("/session/continue?" + query);
    if (res) {
      dispatch(SetContinueSession(res));
    }
    return res;
  } catch (error) {
    if (error.response.status === 403) {
      toast.dismiss();

      toast.error("No active subscription found!");
    }
    return dispatch(handelCatch(error));
  }
};
export default sessionSlice.reducer;
