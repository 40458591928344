// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 400px) {
  .logoTest {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/TermAndConditions/DataProtection.scss"],"names":[],"mappings":"AAAA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":["@media screen and (max-width: 400px) {\n  .logoTest {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
