// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#slider-layout-container {
  padding: 35px;
}
#slider-layout-container .content-block {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}
#slider-layout-container .content-block .block {
  height: calc(100% - 62px);
  flex-grow: 1;
}
#slider-layout-container .content-block .card-padding {
  padding: 44px 30px;
  width: 80%;
}
#slider-layout-container .content-block .singup-card {
  padding: 50px 45px;
  width: 90%;
}
#slider-layout-container .content-block .box-height {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 576px) {
  #slider-layout-container {
    padding: 0px;
    margin-top: 70px;
  }
  #slider-layout-container #custom-card-container {
    box-shadow: none;
    border-radius: 0px;
  }
  #slider-layout-container .content-block .card-padding {
    padding: 20px;
    width: 90%;
  }
}
.v-hide {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/SliderLayout/SliderLayout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,2BAAA;EACA,aAAA;EACA,sBAAA;AAEJ;AADI;EACE,yBAAA;EACA,YAAA;AAGN;AAAI;EACE,kBAAA;EACA,UAAA;AAEN;AACI;EACE,kBAAA;EACA,UAAA;AACN;AAEI;EACE,aAAA;EACA,mBAAA;AAAN;;AAKA;EACE;IACE,YAAA;IACA,gBAAA;EAFF;EAGE;IACE,gBAAA;IACA,kBAAA;EADJ;EAII;IACE,aAAA;IACA,UAAA;EAFN;AACF;AAMA;EACE,aAAA;AAJF","sourcesContent":["#slider-layout-container {\n  padding: 35px;\n  .content-block {\n    height: calc(100vh - 100px);\n    display: flex;\n    flex-direction: column;\n    .block {\n      height: calc(100% - 62px);\n      flex-grow: 1;\n    }\n\n    .card-padding {\n      padding: 44px 30px;\n      width: calc(100% - 20%);\n    }\n\n    .singup-card {\n      padding: 50px 45px;\n      width: calc(100% - 10%);\n    }\n\n    .box-height {\n      display: flex;\n      align-items: center;\n    }\n  }\n}\n\n@media only screen and (max-width: 576px) {\n  #slider-layout-container {\n    padding: 0px;\n    margin-top: 70px;\n    #custom-card-container {\n      box-shadow: none;\n      border-radius: 0px;\n    }\n    .content-block {\n      .card-padding {\n        padding: 20px;\n        width: calc(100% - 10%);\n      }\n    }\n  }\n}\n.v-hide {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
