// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  width: 100%;
  padding-left: 275px;
}

.sidebar-toggle {
  display: none;
}

.sidebar-wrap.active .sidebar {
  left: 0;
}

@media (max-width: 992px) {
  .main-container {
    padding-left: 0;
  }
  .main-container .sidebar-toggle {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Layout/RightContainer/RightContainer.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,OAAA;AACJ;;AAEA;EACI;IACI,eAAA;EACN;EACM;IACI,cAAA;EACV;AACF","sourcesContent":[".main-container{\n    width: 100%;\n    padding-left: 275px; \n}\n\n.sidebar-toggle{\n    display: none;\n}\n\n.sidebar-wrap.active .sidebar{\n    left: 0;\n} \n\n@media (max-width: 992px) {\n    .main-container{\n        padding-left: 0;\n\n        .sidebar-toggle{\n            display: block;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
