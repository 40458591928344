// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
}
.bottom-navigation .navigation-img-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 18px;
}
.bottom-navigation .navigation-img-wrapper::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  background-color: var(--0000);
  z-index: -1;
}
.bottom-navigation .navigation-img-wrapper::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -18px;
  margin: auto;
  width: 100%;
  height: calc(50% + 17px);
  background-color: var(--ffff);
  z-index: -1;
}
.bottom-navigation .navigation-addicon {
  max-width: 58px;
  height: auto;
  object-fit: contain;
}

.navigation-space {
  opacity: 0;
  visibility: hidden;
}
.navigation-space .bottom-navigation {
  position: static;
}`, "",{"version":3,"sources":["webpack://./src/pages/Layout/BottomNavigationBar/BottomNavigationBar.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AACR;AACQ;EACI,WAAA;EACA,kBAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,6BAAA;EACA,WAAA;AACZ;AAEQ;EACI,WAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,wBAAA;EACA,6BAAA;EACA,WAAA;AAAZ;AAII;EACI,eAAA;EACA,YAAA;EACA,mBAAA;AAFR;;AAMA;EACI,UAAA;EACA,kBAAA;AAHJ;AAKI;EACI,gBAAA;AAHR","sourcesContent":[".bottom-navigation{\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    margin: auto; \n    width: 100%; \n\n    .navigation-img-wrapper{\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        position: relative;\n        margin-bottom: 18px;\n\n        &::before{\n            content: \"\";\n            position: absolute;\n            inset: 0;\n            margin: auto;\n            width: 100%;\n            height: 1px;\n            background-color: var(--0000);\n            z-index: -1;\n        }\n\n        &::after{\n            content: \"\";\n            position: absolute;\n            left: 0;\n            right: 0;\n            bottom: -18px;\n            margin: auto;\n            width: 100%;\n            height: calc(50% + 17px);\n            background-color: var(--ffff);\n            z-index: -1;\n        }\n    }\n    \n    .navigation-addicon{\n        max-width: 58px;\n        height: auto;\n        object-fit: contain;\n    }\n}\n\n.navigation-space{\n    opacity: 0;\n    visibility: hidden;\n\n    .bottom-navigation{\n        position: static;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
