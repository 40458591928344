import Button from "components/inputs/Button/Button";
import Modal from "components/layouts/Modal/Modal";
import PreLoader from "components/layouts/PreLoader/PreLoader";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import React, { useEffect } from "react";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  SetChatHistory,
  SetContinueSession,
  SetSessionQA,
  SetSessionSummary,
  SwitchToMenu,
  continueSessionDetails,
  fetchSessionSummary,
  handleSessionEnd,
} from "store/slices";
import { commonRoute, icons } from "untils/constants";
import { getDataFromLocalStorage, storeLocalStorageData } from "untils/helpers";

const SessionDetailModal = ({ handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sessionId } = useParams();
  const { sessionQADetails, isLoading, sessionSummary, chatHistory } =
    useSelector((state) => ({
      sessionQADetails: state.session.sessionQADetails,
      sessionSummary: state.session.sessionSummary,
      isLoading: state.session.isLoading,
      chatHistory: state.chat.chatHistory,
    }));
  const { userDetails } = useSelector((state) => state.user);
  const session_id = getDataFromLocalStorage("session_id");
  //clear store details
  const clearSessionDetails = () => {
    dispatch(SetSessionSummary(""));
    dispatch(SetContinueSession({}));
  };
  //close modal
  const handleModalClose = () => {
    if (isBrowser && sessionQADetails.mode === "chat") {
      const newList = [
        ...chatHistory,
        {
          sender_type: "assistant",
          msg_id: sessionQADetails?.msg_id,
          message: sessionQADetails?.question,
        },
      ];
      dispatch(SetChatHistory(newList));
    }
    handleClose(false);
    clearSessionDetails();
    if (sessionQADetails?.question === "") {
      navigate(commonRoute.sessionMenu + `/${sessionQADetails?.msg_id - 2}`);
      const payload = {
        sessionid: sessionQADetails.session_id || session_id,
        msgid: parseInt(sessionQADetails?.msg_id - 2),
      };
      const query = new URLSearchParams(payload).toString();
      dispatch(SwitchToMenu(query, payload));
    }
    if (!sessionId && (sessionQADetails?.msg_id - 8) % 7 === 0) {
      // if page open after every 5th answer
      navigate(commonRoute.sessionMenu + `/${sessionQADetails?.msg_id}`);
      dispatch(SetSessionQA(sessionQADetails));
    }
  };

  //end session
  const handleEndSession = async () => {
    const payload = { session_id };
    const res = await dispatch(handleSessionEnd(payload));
    if (res.status === 201) {
      navigate(commonRoute.home);
      storeLocalStorageData({ session_id: "" });
      clearSessionDetails();
    }
  };
  const handleContinue = () => {
    if (
      (sessionQADetails?.msg_id - 8) % 7 === 0 &&
      sessionQADetails?.mode === "chat"
    ) {
      navigate(commonRoute.sessionChat + `/${sessionQADetails?.session_id}`);
    } else if ((sessionQADetails?.msg_id - 8) % 7 === 0) {
      navigate(commonRoute.sessionMenu + `/${sessionQADetails?.msg_id}`);
      dispatch(SetSessionQA(sessionQADetails));
    } else {
      dispatch(SetSessionQA({}));
      navigate(-1);
    }
    clearSessionDetails();
  };
  useEffect(() => {
    if (session_id) {
      const queryParams = new URLSearchParams({
        sessionid: session_id,
      }).toString();
      dispatch(fetchSessionSummary(queryParams));
      // dispatch(continueSessionDetails(queryParams));
    }
  }, []);

  return (
    <div id="session-summary-container">
      {/* // <Layouts id="session-summary-container"> */}
      {isLoading ? (
        <PreLoader />
      ) : (
        <>
          <BrowserView>
            <Modal closeBtn onHide={handleModalClose}>
              <div className="container-fluid">
                <p className="mb-4 text-20-400">
                  {sessionSummary?.catch_phrase}
                </p>
                {/* <p className="mb-4">
                  Thank you for your participation in today's session.
                </p> */}
                <p className="mb-4">
                  <span className="fw-bold me-2">Key Insights:</span>
                  {sessionSummary ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sessionSummary?.summary?.replace(/\n/g, "<br>"),
                      }}
                    ></span>
                  ) : (
                    "Not found."
                  )}
                </p>
                <div className="fe-center gap-3">
                  <Button
                    className="h-40 text-center br-10 GO"
                    btnText="Conclude session"
                    onClick={handleEndSession}
                  />
                  <Button
                    disabled={userDetails.sub_status !== "active"}
                    className="h-40 text-center br-10 GO"
                    btnText="Continue session"
                    onClick={async () => {
                      const queryParams = new URLSearchParams({
                        sessionid: session_id,
                      }).toString();
                      const res = await dispatch(continueSessionDetails(queryParams))
                      if (res) { handleModalClose() }
                    }}
                  />
                </div>
              </div>
            </Modal>
          </BrowserView>
          <MobileView>
            <MoblieNavBar
              rightIcon={icons.backArrowIcon}
              leftIcon={icons.Profile40Icon}
              handleRightClick={handleContinue}
              handleLeftClick={() => {
                navigate(commonRoute.home);
                clearSessionDetails();
              }}
            />
            <div className="info-block container-fluid">
              <p className="mb-4 text-20-400">{sessionSummary?.catch_phrase}</p>
              <p className="mb-4">
                <span className="fw-bold me-2">Key Insights:</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sessionSummary?.summary?.replace(/\n/g, "<br>"),
                  }}
                ></span>
              </p>
              <div className="button-container">
                <Button
                  btnText="Conclude session"
                  className="b-0000 bg-ffff"
                  onClick={handleEndSession}
                ></Button>
                <Button
                  disabled={userDetails.sub_status !== "active"}
                  btnText="Continue session"
                  className="b-0000 bg-ffff"
                  onClick={handleContinue}
                ></Button>
              </div>
            </div>
          </MobileView>
        </>
      )}
      {/* // </Layouts> */}
    </div>
  );
};

export default SessionDetailModal;
