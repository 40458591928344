// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#text-area-container textarea {
  width: 100%;
  border-radius: 10px;
  outline: none;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--0000);
  padding: 13px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
}
#text-area-container :disabled {
  background-color: #e6e6e6;
  color: #212121;
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/TextArea/TextArea.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAEE;EACE,yBAAA;EACA,cAAA;AAAJ","sourcesContent":["#text-area-container {\n  textarea {\n    width: 100%;\n    border-radius: 10px;\n    outline: none;\n    display: flex;\n    flex-direction: column;\n    border: 1px solid var(--0000);\n    padding: 13px 16px;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 19.5px;\n  }\n  :disabled {\n    background-color: #e6e6e6;\n    color: #212121;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
