import React, { useEffect } from "react";
import "./Layouts.scss";
import LeftContainer from "./LeftContainer/LeftContainer";
import RightContainer from "./RightContainer/RightContainer";
import { BrowserView, MobileView } from "react-device-detect";
import MoblieNavBar from "./MoblieNavBar/MoblieNavBar";
import BottomNavigationBar from "./BottomNavigationBar/BottomNavigationBar";
import { getDataFromLocalStorage } from "untils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "store/slices";
import PreLoader from "components/layouts/PreLoader";

const Layouts = ({ children, id, isAuthenticated }) => {
  const isLogin = getDataFromLocalStorage("token");
  const dispatch = useDispatch();
  const { userDetails, isDetailsLoading } = useSelector((state) => state.user);
  useEffect(() => {
    if (!userDetails.user_id) {
      dispatch(fetchUserDetails());
    }
  }, []);

  return (
    <>
      <BrowserView>
        {isDetailsLoading && <PreLoader />}
        <main className="sidebar-wrap">
          {isLogin ? <LeftContainer /> : ""}
          <RightContainer id={id}>{children}</RightContainer>
        </main>
      </BrowserView>
      <MobileView id={id}>
        <div className="margin-set">{children}</div>
      </MobileView>
      {/* <section className="d-sm-block d-none">
        <main className="sidebar-wrap">
          <LeftContainer />
          <RightContainer id={id}>{children}</RightContainer>
        </main>
      </section>
      <section className="d-block d-sm-none" id={id}>
        {children}
      </section> */}
    </>
  );
};

export default Layouts;
