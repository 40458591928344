import Layouts from "pages/Layout/Layouts";
import React, { useEffect, useState } from "react";
import "./PastSessions.scss";
import PillCheckbox from "components/inputs/PillCheckbox/PillCheckbox";
import SessionDetail from "../Home/SessionDetail";
import { MobileView, isBrowser, isMobile } from "react-device-detect";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import { commonRoute, icons } from "untils/constants";
import { useNavigate } from "react-router";
import Label from "components/inputs/Label";
import BottomNavigationBar from "pages/Layout/BottomNavigationBar/BottomNavigationBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchPastSession } from "store/slices";
import SessionInfo from "../SessionInfo/SessionInfo";
import { storeLocalStorageData } from "untils/helpers";
import BrowserNavbar from "pages/Layout/BrowserNavbar";
import { toast } from "react-toastify";

function PastSessions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState("Career");
  const [showModal, setShowModal] = useState(false);
  const [sessionTitle, setSessionTitle] = useState("");

  const { userDetails, pastSessionList } = useSelector((state) => ({
    userDetails: state.user.userDetails,
    pastSessionList: state.session.pastSessionList,
  }));
  useEffect(() => {
    const queryParams = new URLSearchParams({
      all: "true",
    }).toString();
    dispatch(fetchPastSession(queryParams));
  }, []);
  const handleSessionDetailShow = (data) => {
    storeLocalStorageData({ session_id: data.session_id });
    storeLocalStorageData({ session_date: data.date });
    if (isBrowser) {
      if (showModal) {
        storeLocalStorageData({ session_id: "" });
        storeLocalStorageData({ session_date: "" });
      }
      setSessionTitle(data?.session_title);
      setShowModal(!showModal);
    }
    if (isMobile) {
      navigate(commonRoute.sessionInfo + "/" + data.session_id);
    }
  };
  return (
    <Layouts id="past-session-container">
      <MobileView>
        <MoblieNavBar
          rightIcon={icons.backArrowIcon}
          handleRightClick={() => navigate(-1)}
        />
        <div className="profile-block f-center flex-column mt-3">
          <img src={icons.Profile96Icon} alt="Profile"></img>
          <Label
            label={userDetails?.first_name}
            className="my-2 text-20-400"
          ></Label>
        </div>
      </MobileView>

      <div className="position-relative col-sm-10 col-12 mx-auto">
        {isBrowser && <BrowserNavbar />}
        <h2 className="text-sm-center text-center mb-sm-4 px-3 mt-sm-0 mt-5 my-2">
          Your Past Sessions
        </h2>
      </div>

      <div className="col-sm-10 col-12 mx-auto custom-shadow b-sm-0000 br-sm-10 p-md-5 new-session-block">
        {/* <div className="category-clock">
          <PillCheckbox
            label="Career"
            isChecked={tabName === "Career"}
            onChange={() => setTabName("Career")}
            className="text-nowrap"
          />{" "}
          <PillCheckbox
            label="Work-Life Balance"
            isChecked={tabName === "Work-Life Balance"}
            onChange={() => setTabName("Work-Life Balance")}
            className="text-nowrap"
          />
          <PillCheckbox
            label="Relationships"
            isChecked={tabName === "Relationships"}
            onChange={() => setTabName("Relationships")}
            className="text-nowrap"
          />
          <PillCheckbox
            label="Health"
            isChecked={tabName === "Health"}
            onChange={() => setTabName("Health")}
            className="text-nowrap"
          />
          <PillCheckbox
            label="Personal Vigilance"
            isChecked={tabName === "Personal Vigilance"}
            onChange={() => setTabName("Personal Vigilance")}
            className="text-nowrap"
          />
        </div> */}
        <div className="row p-3">
          {pastSessionList?.map((ele) => {
            return (
              <div className="col-md-6  text-16-400">
                <SessionDetail
                  title={ele.session_title}
                  date={ele.date}
                  handleClick={() => handleSessionDetailShow(ele)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <MobileView>
        <BottomNavigationBar
          iconName={icons.AddIcon}
          handleClick={() => {
            if (userDetails.sub_status !== "active") {
              toast.dismiss();
              toast.error("No active subscription found.");
              return;
            }
            navigate(commonRoute.newSessions + "/0");
          }}
        />
      </MobileView>
      <div className="container-fluid">
        {showModal && (
          <SessionInfo
            onClose={handleSessionDetailShow}
            sessionTitle={sessionTitle}
          />
        )}
      </div>
    </Layouts>
  );
}

export default PastSessions;
