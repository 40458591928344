import BootrsapModal from "react-bootstrap/Modal";
import "./Modal.scss";
import { icons } from "untils/constants";

const Modal = ({
  children,
  onHide,
  width,
  title,
  size,
  fullscreen,
  closeBtn,
  scrollable,
}) => {
  let newWidth = window.innerWidth < 700 ? "100%" : width || "500px";
  return (
    <BootrsapModal
      className="iferp-scroll modal-block-custom"
      onHide={onHide}
      size={size || "lg"}
      fullscreen={fullscreen}
      centered
      show
      scrollable
    >
      <BootrsapModal.Body id="modal-container" style={{ minWidth: newWidth }}>
        {closeBtn && (
          <img
            src={icons.closeIcon}
            alt="close"
            className="pointer modal-close-button"
            onClick={onHide}
          />
        )}
        <div className="col-md-12 f-center text-20-600 text-sm-24-600  mb-3">
          {title}
        </div>

        {children}
      </BootrsapModal.Body>
    </BootrsapModal>
  );
};

export default Modal;
