import React from "react";
import "./MoblieNavBar.scss";

const MoblieNavBar = ({
  rightIcon,
  leftIcon,
  handleRightClick,
  handleLeftClick,
}) => {
  return (
    <div id="moblie-navbar">
      <button onClick={handleRightClick} className="pointer">
        <img src={rightIcon} alt="back" />
      </button>
      {leftIcon && (
        <button onClick={handleLeftClick}>
          <img src={leftIcon} alt="back" />
        </button>
      )}
    </div>
  );
};

export default MoblieNavBar;
