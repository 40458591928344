import React from "react";
import "./UserSetting.scss";
import Layouts from "pages/Layout/Layouts";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import { MobileView, isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { commonRoute, icons } from "untils/constants";
import Button from "components/inputs/Button/Button";
import { useDispatch } from "react-redux";
import { handelLogout } from "store/slices";

const UserSetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Layouts>
      <MobileView>
        <MoblieNavBar
          rightIcon={icons.backArrowIcon}
          leftIcon={icons.Profile40Icon}
          handleRightClick={() => navigate(-1)}
          handleLeftClick={() => navigate(commonRoute.home)}
        />
      </MobileView>
      <div id="setting-container">
        <div className="row h-100">
          <div className="col-sm-10 mx-auto px-sm-3 px-0">
            <div className="d-flex flex-column h-100">
              <h2 className="text-center my-2 ">Settings</h2>
              <div className="b-sm-0000 br-sm-10 p-md-5 p-sm-3 px-0 new-session-block flex-grow-1 d-flex flex-column">
                <div className="d-flex flex-column flex-grow-1">
                  <div className="p-2 mt-3">
                    <div className="fb-center b-0000 br-15 py-2 px-3 my-2">
                      <div className="text-16-400">Account</div>
                      <img
                        src={icons.rightGoIcon}
                        alt="next"
                        className="pointer"
                        onClick={() => {
                          navigate(commonRoute.AccountDetail);
                        }}
                      />
                    </div>
                    <div className="fb-center b-0000 br-15 py-2 px-3 my-2">
                      <div className="text-16-400">Subscription</div>
                      <img
                        src={icons.rightGoIcon}
                        alt="next"
                        className="pointer"
                        onClick={() => {
                          navigate(commonRoute.subscription);
                        }}
                      />
                    </div>
                    {/* <div className="fb-center b-0000 br-15 py-2 px-3 my-2">
                      <div className="text-16-400">Notifications</div>
                      <img
                        src={icons.rightGoIcon}
                        alt="next"
                        className="pointer"
                      />
                    </div> */}
                    <div className="fb-center b-0000 br-15 py-2 px-3 my-2">
                      <div className="text-16-400">Service & Help</div>
                      <img
                        src={icons.rightGoIcon}
                        alt="next"
                        className="pointer"
                      />
                    </div>
                    <div className="fb-center b-0000 br-15 py-2 px-3 my-2">
                      <div className="text-16-400">Reset Password</div>
                      <img
                        src={icons.rightGoIcon}
                        alt="next"
                        className="pointer"
                        onClick={() => navigate(commonRoute.UserResetPassword)}
                      />
                    </div>
                    <div className="fb-center b-0000 br-15 py-2 px-3 my-2">
                      <div className="text-16-400">Terms and Conditions</div>
                      <img
                        src={icons.rightGoIcon}
                        alt="next"
                        className="pointer"
                        onClick={() => navigate(commonRoute.TermsandCondition)}
                      />
                    </div>
                    <div className="fb-center b-0000 br-15 py-2 px-3 my-2">
                      <div className="text-16-400">Data Privacy</div>
                      <img
                        src={icons.rightGoIcon}
                        alt="next"
                        className="pointer"
                        onClick={() => navigate(commonRoute.DataProtection)}
                      />
                    </div>
                  </div>
                  {isMobile && (
                    <div className="mt-auto p-2">
                      <Button
                        btnText="Log out"
                        className="b-0000 bg-f3f3  text-16-400 py-3 px-3 br-15 w-100 justify-content-center"
                        onClick={() => dispatch(handelLogout())}
                      ></Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default UserSetting;
