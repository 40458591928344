// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custom-card-container {
  box-shadow: 0px 4px 16px 0px rgba(54, 197, 240, 0.24);
  border-radius: 8px;
  background-color: var(--0000);
  max-width: calc(100vw - 0px);
  word-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/Card/Card.scss"],"names":[],"mappings":"AAAA;EACE,qDAAA;EACA,kBAAA;EACA,6BAAA;EACA,4BAAA;EACA,qBAAA;AACF","sourcesContent":["#custom-card-container {\n  box-shadow: 0px 4px 16px 0px rgba(54, 197, 240, 0.24);\n  border-radius: 8px;\n  background-color: var(--0000);\n  max-width: calc(100vw - 0px);\n  word-wrap: break-word;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
