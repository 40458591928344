// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#left-slider-block {
  -webkit-user-select: none;
          user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Login/LeftSlider/LeftSlider.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;UAAA,iBAAA;AACF","sourcesContent":["#left-slider-block {\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
