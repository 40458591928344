import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "components/inputs/Button/Button";
import MoblieNavBar from "pages/Layout/MoblieNavBar";
import { commonRoute, icons } from "untils/constants";
import Card from "components/layouts/Card";
import { handelUserUpdatePassword } from "store/slices";
import { toast } from "react-toastify";
import Layouts from "pages/Layout/Layouts";
import { MobileView, isBrowser } from "react-device-detect";
import PasswordInput from "components/inputs/PasswordInput";
import BrowserNavbar from "pages/Layout/BrowserNavbar";

const UserResetPassword = () => {
  const navigate = useNavigate();
  const [loadButton, setLoadButton] = useState(false);
  const dispatch = useDispatch();

  const initialValues = {
    current_password: "",
    new_password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required("Old Password is required."),
    new_password: Yup.string()
      .required("New Password is required.")
      .min(6, "New Password must be at least 6 characters long.")
      .notOneOf(
        [Yup.ref("current_password"), null],
        "New Password must be different from Old Password"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSetPassword = async (values, { resetForm }) => {
    setLoadButton(true);
    const data = {
      current_password: values?.current_password,
      new_password: values?.new_password,
    };
    if (values) {
      const response = await dispatch(handelUserUpdatePassword(data));
      if (response?.status === 201) {
        toast.dismiss();
        toast.success("Password successfully changed");
      } else if (response?.status === 401) {
        toast.dismiss();
        toast.error("Current Password is Invalid please try again");
        resetForm();
      }
    }
    resetForm();
    setLoadButton(false);
  };

  return (
    <Layouts>
      <MobileView>
        <MoblieNavBar
          rightIcon={icons.backArrowIcon}
          leftIcon={icons.Profile40Icon}
          handleRightClick={() => navigate(-1)}
          handleLeftClick={() => navigate(commonRoute.home)}
        />
      </MobileView>
      <div id="setting-container">
        <div className="row h-100">
          <div className="col-sm-10 mx-auto px-sm-3 px-0">
            <div className="d-flex flex-column h-100">
              <div className="position-relative">
                {isBrowser && <BrowserNavbar />}
                <h4 className="text-center text-32-400 my-2 ">
                  Change Your Password
                </h4>
              </div>
              <div className="b-sm-0000 br-sm-10 p-md-5 p-sm-3 px-0 new-session-block flex-grow-1 d-flex flex-column">
                <div className="d-flex flex-column flex-grow-1">
                  <div className="p-2 mt-3">
                    <Card className="m-auto bg-ffff card-padding pt-4 shadow-none">
                      <div className="position-relative f-center mb-4">
                        {/* <div className="text-24-600">Reset Your Password</div> */}
                      </div>
                      <div className="text-15-400 color-title-navy f-center">
                        Your new password must be different from previously used
                        passwords
                      </div>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) =>
                          handleSetPassword(values, { resetForm })
                        }
                      >
                        {(props) => {
                          const { values, errors, handleChange, handleSubmit } =
                            props;
                          const {
                            confirmPassword,
                            new_password,
                            current_password,
                          } = values;

                          return (
                            <form
                              onSubmit={handleSubmit}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSubmit();
                                }
                              }}
                            >
                              <div className="mb-3 mt-4 px-5">
                                <PasswordInput
                                  id="current_password"
                                  name="current_password"
                                  value={current_password}
                                  onChange={handleChange}
                                  error={errors.current_password}
                                  label="Current Password"
                                  isRequired
                                  placeholder="Enter Current Password"
                                />
                              </div>
                              <div className="mb-3 mt-4 px-5">
                                <PasswordInput
                                  id="new_password"
                                  name="new_password"
                                  value={new_password}
                                  onChange={handleChange}
                                  error={errors.new_password}
                                  label="New Password"
                                  isRequired
                                  placeholder="Enter New Password"
                                />
                              </div>
                              <div className="mb-5 mt-4 px-5">
                                <PasswordInput
                                  id="confirmPassword"
                                  name="confirmPassword"
                                  value={confirmPassword}
                                  onChange={handleChange}
                                  error={errors.confirmPassword}
                                  label="Confirm Password"
                                  isRequired
                                  placeholder={"Enter Confirm Password"}
                                />
                              </div>
                              <div className="ps-5 pe-5 f-center cpb-20">
                                <Button
                                  type="submit"
                                  btnText="Change Password"
                                  btnStyle="primary-dark rounded-pill mw-135 cp-10 border border-dark"
                                  onClick={handleSubmit}
                                  btnLoading={loadButton}
                                />
                              </div>
                            </form>
                          );
                        }}
                      </Formik>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default UserResetPassword;
