import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { verifyOTP, handelResendSendOTP, storeUserData } from "store/slices";
import { getDataFromLocalStorage, storeLocalStorageData } from "untils/helpers";
import { useNavigate } from "react-router-dom";
import InputOTP from "components/inputs/InputOtp";
import Button from "components/inputs/Button/Button";
import SliderLayout from "../SliderLayout";
import MoblieNavBar from "pages/Layout/MoblieNavBar";
import { commonRoute, icons } from "untils/constants";
import Card from "components/layouts/Card";
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(300);
  const [loadButton, setLoadButton] = useState(false);
  const [resetOTP, setResetOTP] = useState(true);
  const [otp, setOTP] = useState("");
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);

  const handelVerifyOTP = async () => {
    setLoadButton(true);
    const Data = {
      email: localStorage.getItem("registerEmail"),
      otp: otp,
      method: "register",
    };
    const response = await dispatch(verifyOTP(Data));
    toast.dismiss();
    if (response?.status === 201) {
      toast.success("OTP verified successfully, Redirecting...");
      navigate("/");
      setResetOTP(true);
      setOTP("");
      localStorage.removeItem("registerEmail");
      storeLocalStorageData({ token: response?.data?.access_token });
      dispatch(storeUserData(response?.data));
    } else if (response?.status === 409) {
      toast.error("Invalid Otp. Please enter a valid Otp.");
      setResetOTP(true);
    } else if (response?.status === 410) {
      toast.error("Otp expired. Please resend the otp.");
      setResetOTP(true);
    }
    setLoadButton(false);
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("registerEmail");
    if (!storedEmail) {
      navigate(commonRoute.register);
    }
    // localStorage.removeItem("registerEmail");
  }, []);

  const handleOtp = (newOtp) => {
    if (newOtp) {
      setOTP(newOtp);
    }
  };

  const handleResendButtonClick = async () => {
    if (!isResendButtonDisabled) {
      setIsResendButtonDisabled(true);
      setCounter(300);
      const response = await dispatch(
        handelResendSendOTP({ email: localStorage.getItem("registerEmail") })
      );
      if (response?.status === 201) {
        toast.dismiss();
        toast.success("OTP is sent successfully.");
        setResetOTP(true);
        setOTP("");
      }
      // const response = await dispatch(
      //   handelSendOTP(objectToFormData({ email: "bibhash" }))
      // );

      // if (response?.status === 200) {
      //   setIsOTPSent(true);
      // }
      setTimeout(() => {
        setIsResendButtonDisabled(false);
      }, 300000);
    }
  };

  useEffect(() => {
    const timer =
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setIsResendButtonDisabled(false);
    }
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (getDataFromLocalStorage("registration_email_otp_status") === "1") {
      navigate("/");
    }
    setResetOTP(true);
    setOTP("");
    setTimeout(() => {
      setResetOTP(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerEmail = localStorage.getItem("registerEmail");
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <SliderLayout title="">
      <div id="reset-password-container">
        <div className="d-block d-sm-none">
          <MoblieNavBar
            rightIcon={icons.backArrowIcon}
            handleRightClick={() => navigate(-1)}
          />
        </div>
        <Card className="m-auto bg-ffff card-padding pt-4">
          <div className="position-relative f-center mb-4">
            <div className="text-24-600">Verify Your Email</div>
          </div>
          <div className="text-15-400 color-title-navy f-center">
            Enter the OTP you received on your entered email
          </div>
          <div className="text-15-400 color-title-navy mb-4 f-center">
            ({registerEmail})
          </div>
          <div className="mb-4 text-14-500 color-raisin-black text-center">
            Enter verification Code
          </div>
          <Formik
            initialValues={{}}
            onSubmit={(values, { resetForm }) => {
              handelVerifyOTP(resetForm);
            }}
          >
            {(props) => {
              const { handleSubmit } = props;
              return (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <div className="center-flex flex-column mb-1">
                    <InputOTP
                      blockSize={6}
                      resetOTP={resetOTP}
                      onSubmit={handleOtp}
                      setResetOTP={setResetOTP}
                      isDisableTyping={loadButton}
                    />
                  </div>

                  <div className="mt-3 mb-5">
                    <div className="d-flex justify-content-around mt-4">
                      <span
                        className={`text-decoration-underline ps-1 ${
                          isResendButtonDisabled ? "text-muted" : "text-primary"
                        }`}
                        role="button"
                        onClick={handleResendButtonClick}
                        style={{
                          cursor: isResendButtonDisabled
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        Resend
                      </span>
                      <span className="color-new-car text-16-500">{` ${formatTime(
                        counter
                      )}`}</span>
                    </div>
                  </div>

                  <div className="ps-5 pe-5 f-center">
                    <Button
                      type="submit"
                      btnText="Verify"
                      btnStyle="primary-dark rounded-pill mw-135 cp-10 border border-dark"
                      onClick={handelVerifyOTP}
                      disabled={loadButton || !otp || (otp && otp.length !== 6)}
                      btnLoading={loadButton}
                    />
                    {/* {otpError && (
                      <div className="text-danger mt-2">{otpError}</div>
                    )} */}
                  </div>
                </form>
              );
            }}
          </Formik>
        </Card>
      </div>
    </SliderLayout>
  );
};
export default VerifyEmail;
