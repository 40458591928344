import React, { useEffect, useState } from "react";
import Layouts from "pages/Layout/Layouts";
import SessionStatus from "./SessionStatus";
import DataAndTerms from "./DataAndTerms";
import "./Home.scss";
import Button from "components/inputs/Button/Button";
import { commonRoute, icons } from "untils/constants";
import SessionDetail from "./SessionDetail";
import { MobileView, isBrowser, isMobile } from "react-device-detect";
import Preferences from "./Preferences";
import { useNavigate } from "react-router";
import Label from "components/inputs/Label";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import BottomNavigationBar from "pages/Layout/BottomNavigationBar/BottomNavigationBar";
import SessionInfo from "../SessionInfo/SessionInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPastSession,
  fetchSessionStatus,
  fetchUserPreferences,
  resetSessionState,
} from "store/slices";
import { getDataFromLocalStorage, storeLocalStorageData } from "untils/helpers";
import AlterPreferences from "./AlterPreferences";
import { toast } from "react-toastify";
const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [isAlterPreferences, setIsAlterPreferences] = useState(false);
  const [userTopics, setUserTopics] = useState([]);
  const [sessionTitle, setSessionTitle] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    sessionStatus,
    pastSessionList,
    userDetails,
    isPreferences,
    isAcceptedTC,
  } = useSelector((state) => ({
    sessionStatus: state.session.sessionStatus,
    pastSessionList: state.session.pastSessionList,
    userDetails: state.user.userDetails,
    isPreferences: state.user.isPreferences,
    isAcceptedTC: state.user.isAcceptedTC,
  }));

  useEffect(() => {
    dispatch(fetchSessionStatus());
    dispatch(fetchUserPreferences());
    dispatch(fetchPastSession());
  }, [isPreferences]);

  useEffect(() => {
    if (userDetails?.preferences) {
      setUserTopics(userDetails.preferences.slice(0, 3));
    }
    if (
      (userDetails.first_name || userDetails.first_name !== "") &&
      userDetails.sub_status &&
      userDetails.sub_status !== "active" &&
      !getDataFromLocalStorage("is_Visit_subscription_details")
    ) {
      storeLocalStorageData({ is_Visit_subscription_details: true });
      navigate(commonRoute.subscription);
    }
  }, [userDetails]);

  const handleSessionDetailShow = (data) => {
    storeLocalStorageData({ session_id: data.session_id });
    storeLocalStorageData({ session_date: data.date });
    if (isBrowser) {
      if (showModal) {
        storeLocalStorageData({ session_id: "" });
        storeLocalStorageData({ session_date: "" });
      }
      setSessionTitle(data?.session_title);
      setShowModal(!showModal);
    }
    if (isMobile) {
      navigate(commonRoute.sessionInfo + "/" + data.session_id);
    }
  };

  return (
    <Layouts id="home-container">
      <MobileView>
        <MoblieNavBar
          rightIcon={icons.backArrowIcon}
          leftIcon={icons.SettingBIcon}
          handleRightClick={() => navigate(-1)}
          handleLeftClick={() => navigate(commonRoute.userSettings)}
        />
        <div className="profile-block f-center flex-column">
          <img src={icons.Profile96Icon} alt="Profile"></img>
          <Label
            label={userDetails?.first_name}
            className="my-2 text-20-400"
          ></Label>
        </div>
      </MobileView>
      {/* <BrowserView> */}
      <h2 className="text-center d-sm-block d-none">
        Welcome {userDetails?.first_name}!
      </h2>
      <p className="text-sm-20-600 text-16-400 p-2">Your Statistics</p>
      <div className="session-container w-100 gap-md-4 gap-sm-2 gap-1 py-sm-4 pb-4 px-2">
        <SessionStatus
          count={sessionStatus?.total_sessions || 0}
          title="Sessions overall"
          Textclass="text-sm-16-500 text-14-400"
        />
        <SessionStatus
          count={sessionStatus?.last_30_Days_sessions_count || 0}
          title="Sessions in last 30 days"
          Textclass="text-sm-16-500 text-14-400"
        />
        <SessionStatus
          count={sessionStatus?.duration || "00:00"}
          title="Time invested in you"
          Textclass="text-sm-16-500 text-14-400"
        />
      </div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-6 col-12">
            <p className="text-16-400">Your past sessions</p>
            {pastSessionList ? (
              <>
                <div className="p-2 h-md-100 text-16-400 pb-0">
                  {pastSessionList?.map((ele, i) => {
                    return (
                      <SessionDetail
                        key={i}
                        title={ele.session_title}
                        date={ele.date}
                        handleClick={() => handleSessionDetailShow(ele)}
                      />
                    );
                  })}
                </div>
                <div className="mt-sm-4 mt-0 fe-center px-2 pb-3">
                  <Button
                    btnText="View all your sessions"
                    className="b-0000 bg-ffff position-relative w-min-250"
                    rightIcon={icons.rightGoIcon}
                    iconClass="icon-right-end"
                    onClick={() => navigate(commonRoute.pastSession)}
                  ></Button>
                </div>
              </>
            ) : (
              <div className="mb-5 ms-2">No session found.</div>
            )}
          </div>
          <div className="col-md-6 col-12">
            <div className="fb-center">
              <p className="text-16-400">Your current focus topics</p>
              <img
                src={icons.editIcon}
                alt="edit topics"
                className="me-2 pointer"
                onClick={() => setIsAlterPreferences(true)}
              />
            </div>

            {userTopics?.length > 0 ? (
              <>
                <div className="p-2 h-md-100 text-16-400 pb-0">
                  {userTopics?.map((topic, index) => {
                    return (
                      <SessionDetail
                        key={index}
                        title={topic}
                        handleClick={() => {
                          if (userDetails.sub_status !== "active") {
                            toast.dismiss();
                            toast.error("No active subscription found.");
                            return;
                          }
                          storeLocalStorageData({ session_id: "" });
                          navigate(commonRoute.newSessions + "/0", {
                            state: { selectedTopic: topic },
                          });
                        }}
                      />
                    );
                  })}
                </div>
                {userDetails?.preferences?.length !== userTopics?.length && (
                  <div className="mt-sm-4 mt-0 fe-center px-2 pb-3">
                    <Button
                      btnText="View all your topics"
                      className="b-0000 bg-ffff position-relative w-min-250"
                      rightIcon={icons.rightGoIcon}
                      iconClass="icon-right-end"
                      onClick={() => setUserTopics(userDetails?.preferences)}
                    ></Button>
                  </div>
                )}
              </>
            ) : (
              "Topics not found."
            )}
          </div>
        </div>
      </div>
      {/* <DataAndTerms /> */}
      <Preferences />
      {showModal && (
        <SessionInfo
          onClose={handleSessionDetailShow}
          sessionTitle={sessionTitle}
        />
      )}
      {isAlterPreferences && (
        <AlterPreferences handleClose={setIsAlterPreferences} />
      )}

      <MobileView className="d-sm-none d-block">
        <BottomNavigationBar
          iconName={icons.AddIcon}
          handleClick={() => {
            if (userDetails.sub_status !== "active") {
              toast.dismiss();
              toast.error("No active subscription found.");
              return;
            }
            storeLocalStorageData({ sessionId: "" });
            dispatch(resetSessionState());
            navigate(commonRoute.newSessions + "/0");
          }}
        />
      </MobileView>
    </Layouts>
  );
};
export default Home;
