import React from "react";
import "./LeftContainer.scss";
import { commonRoute, icons } from "untils/constants";
import Label from "components/inputs/Label";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { handelLogout, resetSessionState } from "store/slices";
import { storeLocalStorageData } from "untils/helpers";
import { IoMdAddCircleOutline } from "react-icons/io";
const LeftContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleToggle = () => {
    const element = document.getElementsByClassName("sidebar-wrap");
    element[0].classList.remove("active");
  };
  const { userDetails } = useSelector((state) => ({
    userDetails: state.user.userDetails,
  }));
  return (
    <>
      <div className="sidebar-overlay" onClick={handleToggle}></div>
      <div className="sidebar">
        <div
          className="profile-block f-center flex-column pointer"
          onClick={() => navigate(commonRoute.home)}
        >
          <img src={icons.Profile96Icon} alt="Profile"></img>
          <Label
            label={userDetails?.first_name}
            className="my-4 text-20-400 color-ffff"
          ></Label>
        </div>
        <div className="nav-block">
          <Navbar>
            <Container className="justify-content-center">
              <Nav className="flex-column">
                <Nav.Link
                  href=""
                  className="fa-center"
                  onClick={() => {
                    navigate(commonRoute.userSettings);
                  }}
                >
                  <img
                    src={icons.SettingWIcon}
                    alt="setting"
                    className="mr-3"
                  ></img>
                  <Label
                    label="Attitude"
                    className="color-ffff mx-4 pointer"
                  ></Label>
                </Nav.Link>
                {/* <Nav.Link
                  href=""
                  className="fa-center"
                  onClick={() => {
                    navigate(commonRoute.subscription);
                  }}
                >
                  <img
                    src={icons.subscriptionmodel}
                    alt="setting"
                    className="mr-3 w-24 h-24"
                  ></img>
                  <Label
                    label="Subscription"
                    className="color-ffff mx-4 pointer"
                  ></Label>
                </Nav.Link> */}
                <Nav.Link
                  href=""
                  className="fa-center"
                  disabled={userDetails?.sub_status !== "active"}
                  onClick={() => {
                    storeLocalStorageData({ session_id: "" });
                    dispatch(resetSessionState());
                    navigate(commonRoute.newSessions + "/0");
                  }}
                >
                  {/* <img
                    src={
                      userDetails?.sub_status !== "active"
                        ? icons.sessionGreyIcon
                        : icons.sessionIcon
                    }
                    alt="session"
                    className="mr-3"
                  ></img> */}
                  {userDetails?.sub_status !== "active" ? (
                    <IoMdAddCircleOutline color="#9e9e9e" size={24} />
                  ) : (
                    <IoMdAddCircleOutline color="#ffffff" size={24} />
                  )}
                  <Label
                    label="Start New Session"
                    className={`${
                      userDetails?.sub_status !== "active"
                        ? "color-9e9e"
                        : "color-ffff"
                    } mx-4 pointer`}
                  ></Label>
                </Nav.Link>
                <Nav.Link
                  href=""
                  className="fa-center"
                  onClick={() => dispatch(handelLogout())}
                >
                  <img
                    src={icons.LogoutIcon}
                    alt="logOut"
                    className="mr-3"
                  ></img>
                  <Label
                    label="Logout"
                    className="color-ffff mx-4 pointer"
                  ></Label>
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};
export default LeftContainer;
