import { icons } from "untils/constants";
import "./LeftSlider.scss";

const LeftSlider = ({title}) => {
  return (
    <div id="left-slider-block" className="f-center flex-column">
      <h2>{title}</h2>
       <img className="img-logo1" src={icons.enpowerLogo} alt="img" />
    </div>
  );
};
export default LeftSlider;
