// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.preloader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.preloader-bulb {
  z-index: 1;
  animation: vibrationAndZoom 7s ease-in-out infinite;
}

.bulb-icon {
  font-size: 48px;
  transform-origin: center;
}

@keyframes vibrationAndZoom {
  0%, 100% {
    transform: translateX(0) rotate(0deg) scale(1);
  }
  10%, 90% {
    transform: translateX(-5px) rotate(-10deg) scale(1);
  }
  20%, 80% {
    transform: translateX(5px) rotate(10deg) scale(1);
  }
  30%, 70% {
    transform: translateX(-5px) rotate(-10deg) scale(1);
  }
  40%, 60% {
    transform: translateX(5px) rotate(10deg) scale(1);
  }
  50% {
    transform: translateX(0) rotate(0deg) scale(0.9);
  }
  60%, 80% {
    transform: translateX(0) rotate(0deg) scale(1.2);
  }
  70% {
    transform: translateX(0) rotate(0deg) scale(0.8);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/PreLoader/PreLoader.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACJ;;AAEE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;AACJ;;AAEE;EACE,UAAA;EACA,mDAAA;AACJ;;AAEE;EACE,eAAA;EACA,wBAAA;AACJ;;AAEE;EACE;IACE,8CAAA;EACJ;EACE;IACE,mDAAA;EACJ;EACE;IACE,iDAAA;EACJ;EACE;IACE,mDAAA;EACJ;EACE;IACE,iDAAA;EACJ;EACE;IACE,gDAAA;EACJ;EACE;IACE,gDAAA;EACJ;EACE;IACE,gDAAA;EACJ;AACF","sourcesContent":[".preloader {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 9999;\n  }\n  \n  .preloader-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n  }\n  \n  .preloader-bulb {\n    z-index: 1;\n    animation: vibrationAndZoom 7s ease-in-out infinite;\n  }\n  \n  .bulb-icon {\n    font-size: 48px;\n    transform-origin: center;\n  }\n  \n  @keyframes vibrationAndZoom {\n    0%, 100% {\n      transform: translateX(0) rotate(0deg) scale(1);\n    }\n    10%, 90% {\n      transform: translateX(-5px) rotate(-10deg) scale(1);\n    }\n    20%, 80% {\n      transform: translateX(5px) rotate(10deg) scale(1);\n    }\n    30%, 70% {\n      transform: translateX(-5px) rotate(-10deg) scale(1);\n    }\n    40%, 60% {\n      transform: translateX(5px) rotate(10deg) scale(1);\n    }\n    50% {\n      transform: translateX(0) rotate(0deg) scale(0.9);\n    }\n    60%, 80% {\n      transform: translateX(0) rotate(0deg) scale(1.2);\n    }\n    70% {\n      transform: translateX(0) rotate(0deg) scale(0.8);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
