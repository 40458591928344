import TextInput from "components/inputs/TextInput";
import Card from "components/layouts/Card";
import { Formik } from "formik";
import * as Yup from "yup";
import PasswordInput from "components/inputs/PasswordInput";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import SliderLayout from "../SliderLayout/SliderLayout";
import { commonRoute, icons } from "untils/constants";
import { handelLogin } from "store/slices";
import { useDispatch, useSelector } from "react-redux";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import Button from "components/inputs/Button/Button";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authError } = useSelector((state) => ({
    authError: state.auth.authError,
  }));

  const initialValues = {
    Username: "",
    Password: "",
  };
  const validationSchema = Yup.object().shape({
    Username: Yup.string().required("Username is required."),
    Password: Yup.string().required("Password is required."),
  });

  const handleLogin = async (e) => {
    const response = await dispatch(handelLogin(e));
    if (response?.status === 200) {
      navigate("/");
    }
  };

  return (
    <SliderLayout title="Welcome">
      <div id="login-container">
        <div className="d-block d-sm-none">
          <MoblieNavBar
            rightIcon={icons.backArrowIcon}
            handleRightClick={() => navigate(-1)}
          />
        </div>
        <Card className="m-auto bg-ffff card-padding">
          <div className="text-20-400 mb-4 text-center">
            <p>Hello!</p>
            <p>Nice that you're here.</p>
          </div>
          <p className="text-14-400 text-left">Sign in to continue.</p>
          <p className="text-center text-14-400" style={{ color: "red" }}>
            {authError}
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            {(props) => {
              const { values, errors, handleChange, handleSubmit } = props;
              const { Username, Password } = values;
              return (
                <form>
                  <div className="mb-4">
                    <TextInput
                      placeholder="Username"
                      id="Username"
                      value={Username}
                      error={errors?.Username}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-1">
                    <PasswordInput
                      placeholder="Password"
                      id="Password"
                      value={Password}
                      error={errors?.Password}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className="color-54ca text-14-400 pointer mb-4"
                    onClick={() => {
                      navigate(commonRoute.resetPassword);
                    }}
                  >
                    Forgot Password?
                  </div>
                  <div className="mb-4 ">
                    <Button
                      type="submit"
                      btnText="Log In"
                      btnStyle="GO"
                      className="w-100 m-2 bg-eaea position-relative"
                      text
                      rightIcon={icons.rightGoIcon}
                      iconClass="icon-right-end"
                      onClick={handleSubmit}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
          <p className=" text-14-400 pt-5 ps-2">
            Don't have an account yet? Click here to register.
          </p>
          <Button
            btnText="Register"
            btnStyle="GO"
            className="w-100 bg-eaea m-2"
            text
            // disabled
            onClick={() => {
              navigate(commonRoute.register);
            }}
          />
        </Card>
      </div>
    </SliderLayout>
  );
};

export default Login;
