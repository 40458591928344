import React, { useEffect, useState } from "react";
import "./NewSession.scss";
import Layouts from "pages/Layout/Layouts";
import { commonRoute, icons } from "untils/constants";
import AddDescription from "./AddDescription";
import AddSessionOptions from "./AddSessionOptions";
import OptionLayout from "components/inputs/OptionLayout/OptionLayout";
import { useLocation, useNavigate, useParams } from "react-router";
import SessionDetailModal from "./SessionDetailModal";
import { MobileView, isBrowser, isMobile } from "react-device-detect";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import BottomNavigationBar from "pages/Layout/BottomNavigationBar/BottomNavigationBar";
import { useDispatch, useSelector } from "react-redux";
import { addSessionOpt, fetchUserPreferences } from "store/slices";
import PreLoader from "components/layouts/PreLoader/PreLoader";
import { getDataFromLocalStorage, storeLocalStorageData } from "untils/helpers";
import BrowserNavbar from "pages/Layout/BrowserNavbar";
import { toast } from "react-toastify";
const NewSession = () => {
  const { stepId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const id = location?.state;
  const [showSessionDetail, setShowSessionDetail] = useState(false);
  const [currentSessionId, setCurrentSessionId] = useState();

  const [selectOption, setSelectOption] = useState({
    id: "0",
    value: "",
  });

  const { userDetails, isLoading, sessionQADetails } = useSelector((state) => ({
    userDetails: state.user.userDetails,
    isLoading: state.session.isLoading,
    sessionQADetails: state.session.sessionQADetails,
  }));

  useEffect(() => {
    if (!userDetails?.preferences) {
      dispatch(fetchUserPreferences());
    }
  }, []);

  useEffect(() => {
    stepId && setSelectOption({ ...selectOption, id: stepId });
  }, [stepId]);

  useEffect(() => {
    if (
      location?.state?.selectedTopic &&
      !getDataFromLocalStorage("session_id")
    ) {
      setSelectOption({
        ...selectOption,
        value: location?.state?.selectedTopic,
      });
      saveSessionOption(location?.state?.selectedTopic);
    }
    if (location.state?.sessionId) {
      setCurrentSessionId(location.state?.sessionId);
    }
  }, [location?.state]);

  const saveSessionOption = async (value) => {
    setSelectOption({ ...selectOption, value: value });
    const res = await dispatch(addSessionOpt({ session_title: value }));
    if (res?.status === 201) {
      setCurrentSessionId(res.data.session_id);
      storeLocalStorageData({ session_id: res.data.session_id });
      navigate(commonRoute.sessionMenu + `/${res.data.msg_id}`);
    }
  };
  if (userDetails.sub_status !== "active") {
    navigate(commonRoute.home);
    return;
  }
  return (
    <>
      {userDetails.sub_status === "active" && (
        <Layouts id="new-session-container">
          {isLoading && <PreLoader />}
          <MobileView>
            <MoblieNavBar
              rightIcon={icons.backArrowIcon}
              leftIcon={icons.Profile40Icon}
              handleRightClick={() => navigate(-1)}
              handleLeftClick={() => navigate(commonRoute.home)}
            />
          </MobileView>
          <div className="moblie-container pb-0">
            <div className="container-fluid h-100">
              <div className="row h-100">
                <div className="col-sm-10 mx-auto px-sm-3 px-0">
                  <div className="d-flex flex-column h-100">
                    <div className="position-relative">
                      {isBrowser && <BrowserNavbar />}
                      {!currentSessionId && (
                        <h4 className="text-sm-center text-center mb-4 px-4 my-2">
                          Begin a new session
                        </h4>
                      )}
                    </div>

                    <div className="b-sm-0000 br-sm-10 p-md-5 p-sm-3 px-0 new-session-block flex-grow-1 d-flex flex-column">
                      <div className="px-4">
                        {selectOption.id === "0" && (
                          <>
                            <p className="text-16-400 mb-4">
                              It's good to have you here. With every session,
                              we're getting closer to your goals. Let's keep
                              working on them.
                            </p>
                            <p className="text-16-400 mb-4">
                              What topic should we focus on today?
                            </p>
                            <div className="py-4">
                              <OptionLayout
                                handleClick={() => {
                                  if (userDetails.sub_status !== "active") {
                                    toast.dismiss();
                                    toast.error(
                                      "No active subscription found."
                                    );
                                    return;
                                  }
                                  navigate(commonRoute.newSessions + "/self");
                                }}
                                otpValue="I want to enter my own topic"
                                iconName={icons.arrowNextIcon}
                                iconAlt="arrow"
                              />
                              {userDetails?.preferences?.map((ele, i) => {
                                return (
                                  <OptionLayout
                                    key={i}
                                    isSelected={ele === selectOption.value}
                                    handleClick={() => saveSessionOption(ele)}
                                    otpValue={ele}
                                  />
                                );
                              })}
                            </div>
                          </>
                        )}
                        {selectOption.id === "self" && (
                          <AddDescription setSelectOption={saveSessionOption} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showSessionDetail && (
            <SessionDetailModal handleClose={setShowSessionDetail} />
          )}
        </Layouts>
      )}
    </>
  );
};

export default NewSession;
