// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#browser-navbar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--ffff);
  padding: 6px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Layout/BrowserNavbar/BrowserNavbar.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,6BAAA;EAEA,YAAA;AAAF","sourcesContent":["#browser-navbar {\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-color: var(--ffff);\n\n  padding: 6px;\n  // position: fixed;\n  // top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
