// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#InputOTP-container ul {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: unset;
  padding: unset;
}
#InputOTP-container ul li {
  float: none;
  list-style: none;
  display: inline-block;
  padding: 0px 4px 0px 4px;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
#InputOTP-container ul li input {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  background: #ffffff;
  border: 1px solid #9a9a9a;
  appearance: none;
  height: 52px;
  width: 52px;
  border-radius: 35%;
}
#InputOTP-container ul li input:focus {
  outline: none;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1411764706);
}
#InputOTP-container ul li input::-webkit-outer-spin-button,
#InputOTP-container ul li input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#InputOTP-container ul li input[type=number] {
  -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/InputOtp/inputOtp.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,uBAAA;EACA,qBAAA;EACA,aAAA;EACA,cAAA;AAAJ;AACI;EACE,WAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;EAkBA,gCAAA;EAOA,YAAA;AAtBN;AAFM;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EAGA,gBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAIR;AAHQ;EACE,aAAA;EACA,oDAAA;AAKV;AADM;;EAEE,wBAAA;EACA,SAAA;AAGR;AACM;EACE,0BAAA;AACR","sourcesContent":["#InputOTP-container {\n  ul {\n    display: flex;\n    justify-content: center;\n    align-items: flex-end;\n    margin: unset;\n    padding: unset;\n    li {\n      float: none;\n      list-style: none;\n      display: inline-block;\n      padding: 0px 4px 0px 4px;\n      input {\n        font-size: 18px;\n        text-align: center;\n        font-weight: 600;\n        background: #ffffff;\n        border: 1px solid #9a9a9a;\n        -webkit-appearance: none;\n        -moz-appearance: none;\n        appearance: none;\n        height: 52px;\n        width: 52px;\n        border-radius: 35%;\n        &:focus {\n          outline: none;\n          box-shadow: 0px 4px 16px #00000024;\n        }\n      }\n      /* Chrome, Safari, Edge, Opera */\n      input::-webkit-outer-spin-button,\n      input::-webkit-inner-spin-button {\n        -webkit-appearance: none;\n        margin: 0;\n      }\n\n      /* Firefox */\n      input[type=\"number\"] {\n        -moz-appearance: textfield;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
