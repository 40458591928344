import Button from "components/inputs/Button/Button";
import Dropdown from "components/inputs/Dropdown/Dropdown";
import Label from "components/inputs/Label";
import PillCheckbox from "components/inputs/PillCheckbox/PillCheckbox";
import TextInput from "components/inputs/TextInput/TextInput";
import Modal from "components/layouts/Modal/Modal";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  SavePreferences,
  SaveUserDetails,
  SetIsPreferences,
  UpdatePreferences,
  UpdateUserDetails,
  fetchPreferenceList,
  fetchUserPreferences,
} from "store/slices";
import { commonRoute, countryList, icons } from "untils/constants";
import { storeLocalStorageData } from "untils/helpers";
import * as Yup from "yup";

const Preferences = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchPreferenceList());
    dispatch(fetchUserPreferences());
  }, []);
  const { topicList, isPreferences, userDetails } = useSelector((state) => ({
    topicList: state.user.preferenceList,
    isPreferences: state.user.isPreferences,
    userDetails: state.user.userDetails,
  }));
  const genderOption = [
    { id: "female", label: "Female" },
    { id: "male", label: "Male" },
    { id: "diverse", label: "Diverse" },
  ];

  const yearOptions = [];
  for (let index = moment().year(); index >= moment().year() - 120; index--) {
    yearOptions.push({ id: index, label: index });
  }
  const initialValues = {
    firstName: userDetails?.first_name || "",
    YearOfBirth: userDetails?.year_of_birth || "",
    gender: userDetails?.gender || "",
    age: userDetails?.first_Name || "",
    country: userDetails?.country || "",
    topics: userDetails?.preferences || [],
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    topics: Yup.array()
      .min(1, "Select at least one topic.")
      .max(5, "You can select maximum five topics."),
  });
  const handleSubmit = (e) => {
    const payload = {
      first_name: e.firstName,
      year_of_birth: e.YearOfBirth !== "" ? e.YearOfBirth : 0,
      gender: e.gender,
      country: e.country,
    };
    if (userDetails.first_Name) {
      dispatch(UpdateUserDetails(payload));
    } else {
      dispatch(SaveUserDetails(payload));
    }
    if (userDetails.preferences) {
      dispatch(UpdatePreferences({ preferences: e.topics }));
    } else {
      dispatch(SavePreferences({ preferences: e.topics }));
    }
    dispatch(SetIsPreferences(false));
    storeLocalStorageData({ is_Visit_subscription_details: true });
    navigate(commonRoute.subscription);
  };
  return (
    <>
      {isPreferences && (
        <Modal title="Profile Details">
          <div className="container-fluid">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                } = props;
                const { firstName, YearOfBirth, gender, country, topics } =
                  values;
                return (
                  <form className="row">
                    <div className="mb-2 col-12 col-md-6">
                      <TextInput
                        labelClass="text-16-600 mb-2"
                        isRequired
                        label="First Name"
                        placeholder="First Name"
                        id="firstName"
                        value={firstName}
                        error={errors?.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-2 col-12 col-md-6">
                      <Dropdown
                        options={genderOption}
                        id="gender"
                        onChange={handleChange}
                        value={gender}
                        placeholder="Gender"
                        label="Gender"
                        labelClass="text-16-600 mb-2"
                        isSearchable={false}
                        error={errors?.gender}
                      />
                    </div>
                    <div className="mb-2 col-12 col-md-6">
                      <Dropdown
                        options={yearOptions}
                        id="YearOfBirth"
                        onChange={handleChange}
                        value={YearOfBirth}
                        placeholder="Birth year"
                        label="Year of Birth"
                        labelClass="text-16-600 mb-2"
                        isSearchable={true}
                        error={errors?.YearOfBirth}
                      />
                    </div>
                    <div className="mb-2 col-12 col-md-6">
                      <Dropdown
                        options={countryList}
                        id="country"
                        onChange={handleChange}
                        value={country}
                        placeholder="Country"
                        label="Country"
                        isSearchable={true}
                        error={errors?.country}
                        labelClass="text-16-600 mb-2"
                      />
                    </div>
                    <div className="mb-2 col-12 ">
                      <Label
                        label="Topics"
                        required
                        className="text-16-600 mb-2"
                      />
                      <div>
                        {topics &&
                          topicList?.map((ele) => {
                            return (
                              <PillCheckbox
                                label={ele.topic}
                                isChecked={topics?.includes(ele.topic)}
                                onChange={(isChecked) => {
                                  if (isChecked) {
                                    setFieldValue("topics", [
                                      ...topics,
                                      ele.topic,
                                    ]);
                                  } else {
                                    setFieldValue(
                                      "topics",
                                      topics?.filter(
                                        (pref) => pref !== ele.topic
                                      )
                                    );
                                  }
                                }}
                              />
                            );
                          })}
                      </div>
                      {errors?.topics && (
                        <span className="text-13-400 pt-1 d-flex align-items-center gap-2">
                          <span style={{ color: "red" }}>{errors?.topics}</span>
                        </span>
                      )}
                    </div>
                    <div className="d-flex justify-content-end w-100">
                      <div className="mb-4 w-max-200 w-100">
                        <Button
                          type="submit"
                          btnText="Continue"
                          btnStyle="GO"
                          className="w-100 m-2 bg-eaea position-relative"
                          text
                          disabled={errors?.topics}
                          rightIcon={icons.rightGoIcon}
                          iconClass="icon-right-end"
                          onClick={() => {
                            handleSubmit();
                          }}
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Preferences;
