import { createSlice } from '@reduxjs/toolkit'
import { api } from 'services/api';
import { handelCatch } from './globalSlice';
import { SetLoading, SetSessionQA } from './sessionSlice';

const initialState = {
    chatHistory: [],
    isChatLoading: false
}

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        SetChatHistory(state, action) {
            state.chatHistory = action.payload;
        },
        SetIsChatLoading(state, action) {
            state.isChatLoading = action.payload;
        }
    }
});

export const { SetChatHistory, SetIsChatLoading } = chatSlice.actions;

export const SwitchToChat = (query) => async (dispatch, getState) => {
    try {
        if (getState().chat.isChatLoading) return;
        dispatch(SetIsChatLoading(true));
        const res = await api.get(`/switch/chat?${query}`);
        if (res) {
            dispatch(SetChatHistory(res));
        }
        dispatch(SetIsChatLoading(false));
        return res;
    } catch (error) {
        dispatch(SetIsChatLoading(false));
        return dispatch(handelCatch(error));
    }
}
export const SwitchToMenu = (query, data) => async (dispatch, getState) => {
    try {
        if (getState().session.isLoading) return;
        dispatch(SetLoading(true))
        const res = await api.get(`/switch/menu?${query}`);
        if (res) {
            const newData = {
                mode: "",
                msg_id: data.msgId,
                session_id: data.sessionId,
                question: res.question,
                options: res.options,
            };
            dispatch(SetSessionQA(newData));
        }
        dispatch(SetLoading(false));
        return res;
    } catch (error) {
        dispatch(SetLoading(false));

        return dispatch(handelCatch(error));
    }
}

export default chatSlice.reducer