import { createSlice } from "@reduxjs/toolkit";
import { handelCatch, handelResponse } from "./globalSlice";
import { storeLocalStorageData } from "untils/helpers";
import { api } from "services/api";
import { toast } from "react-toastify";

const initialState = {
  isPreferences: false,
  isAcceptedTC: false,
  preferenceList: [],
  userDetails: {},
  isDetailsLoading: false,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserState: (state) => initialState,
    SetIsPreferences(state, action) {
      state.isPreferences = action.payload;
    },
    SetIsAcceptedTC(state, action) {
      state.isAcceptedTC = action.payload;
    },
    SetPreferenceList(state, action) {
      state.preferenceList = action.payload;
    },
    setUserDetails(state, action) {
      state.userDetails = { ...state.userDetails, ...action.payload };
    },
    setIsDetailsLoading(state, action) {
      state.isDetailsLoading = action.payload;
    },
  },
});

export const {
  resetUserState,
  SetIsPreferences,
  SetIsAcceptedTC,
  SetPreferenceList,
  setUserDetails,
  setIsDetailsLoading,
} = userSlice.actions;

export const fetchPreferenceList = () => async (dispatch) => {
  try {
    const res = await api.get("/preferences");
    if (res) {
      dispatch(SetPreferenceList(res));
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const fetchUserDetails = () => async (dispatch) => {
  try {
    // dispatch(setIsDetailsLoading(true));
    const res = await api.get("/user");
    if (res) {
      storeLocalStorageData({ userInfo: res });
      dispatch(setUserDetails(res));
    }
    if (!res.first_name || res.first_name === "") {
      dispatch(SetIsPreferences(true));
    }
    if (res.isAcceptedTC) {
      dispatch(SetIsAcceptedTC(false));
    } else {
      dispatch(SetIsAcceptedTC(true));
    }
    // dispatch(setIsDetailsLoading(false));
    return res;
  } catch (error) {
    if (error.response?.status === 404) {
      dispatch(SetIsPreferences(true));
      dispatch(SetIsAcceptedTC(true));
    }
    // dispatch(setIsDetailsLoading(false));
    return dispatch(handelCatch(error));
  }
};

export const fetchUserPreferences = () => async (dispatch) => {
  try {
    const res = await api.get("/user/preferences");
    if (res) {
      dispatch(setUserDetails(res));
    }
  } catch (error) {
    if (error.response?.status === 404) {
      dispatch(SetIsPreferences(true));
    }
    return dispatch(handelCatch(error));
  }
};
export const SaveUserDetails = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/user", payload);
    if (res) {
      toast.dismiss();
      toast.success("Details updated successfully");
      dispatch(fetchUserDetails());
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const UpdateUserDetails = (payload) => async (dispatch) => {
  try {
    const res = await api.put("/user", payload);
    if (res) {
      toast.dismiss();
      toast.success("Details updated successfully");
      dispatch(fetchUserDetails());
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const SavePreferences = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/user/preferences", payload);
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const UpdatePreferences = (payload) => async (dispatch) => {
  try {
    const res = await api.put("/user/preferences", payload);
    if (res) {
      dispatch(fetchUserPreferences());
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export default userSlice.reducer;
