import { icons } from "untils/constants";
import "./SliderLayout.scss";
import LeftSlider from "../Login/LeftSlider/LeftSlider";
import { useParams } from "react-router-dom";

const SliderLayout = ({ children , title }) => {
  const params = useParams();
  const userType = params?.type === "organization" || params?.type === "detail";

  return (
    <div id="slider-layout-container">
      {/* <img className="" src={icons.backArrowIcon} alt="img" /> */}
      <div className="content-block">
        <div className="block">
        <div
          className={
            userType
              ? `d-flex align-items-center justify-content-center h-sm-100`
              : `row d-flex align-items-center flex-wrap  h-sm-100`
          }
        >
          {!userType && (
            <div className="col-md-6 d-sm-block d-none">
              <LeftSlider title={title} />
            </div>
          )}
          <div className={userType ? "col-md-8 cpb-50" : "col-md-6 box-height"}>
            {children}
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
export default SliderLayout;
