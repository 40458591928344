import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "pages/App/Home/Home";
import NewSession from "pages/App/NewSession/NewSession";
import SessionChat from "pages/App/SessionChat/SessionChat";
import PastSessions from "pages/App/PastSessions/PastSessions";
import UserSetting from "pages/App/UserSetting/UserSetting";
import SessionInfo from "pages/App/SessionInfo/SessionInfo";
import SessionDetailModal from "pages/App/NewSession/SessionDetailModal";
import AddSessionOptions from "pages/App/NewSession/AddSessionOptions";
import ScrollToTop from "components/layouts/ScrollTop";
import TermsandCondition from "pages/App/UserSetting/TermsandCondition";
import DataProtection from "pages/App/UserSetting/DataProtection";
import Subscription from "pages/App/Subscription/Subscription";
import UserResetPassword from "pages/App/UserSetting/UserResetPassword";
import AccountDetail from "pages/App/UserSetting/AccountDetail";

const AppRoute = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/user-settings" element={<UserSetting />} />
        <Route path="/terms-and-condition" element={<TermsandCondition />} />
        <Route path="/data-protection" element={<DataProtection />} />
        <Route path="/user-reset-password" element={<UserResetPassword />} />
        <Route path="/new-session/:stepId" element={<NewSession />} />
        <Route path="/session-chat/:sessionId" element={<SessionChat />} />
        <Route
          path="/session-menu/:previousMSgId"
          element={<AddSessionOptions />}
        />
        <Route path="/past-session" element={<PastSessions />} />
        <Route path="/session-info/:sessionId" element={<SessionInfo />} />
        <Route
          path="/session-summary/:sessionId"
          element={<SessionDetailModal />}
        />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/user-detail" element={<AccountDetail />} />

        <Route path="*" element={<Navigate replace to="" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
