import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PasswordInput from "components/inputs/PasswordInput";
import "./Registration.scss";
import { useNavigate } from "react-router-dom";
import SliderLayout from "../SliderLayout/SliderLayout";
import { commonRoute, icons } from "untils/constants";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import Button from "../../../components/inputs/Button/Button";
import TextInput from "../../../components/inputs/TextInput/TextInput";
import Card from "../../../components/layouts/Card/Card";
import { useDispatch } from "react-redux";
import { handelRegister } from "store/slices";
import { toast } from "react-toastify";

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadButton, setLoadButton] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required.")
      .email("Email must be a valid email"),
    password: Yup.string()
      .required("Password is required.")
      .min(6, "Password must be at least 6 characters long."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleRegister = async (values, { resetForm }) => {
    setLoadButton(true);
    localStorage.setItem("registerEmail", values.email);

    if (values) {
      const response = await dispatch(
        handelRegister({ email: values?.email, password: values?.password })
      );
      toast.dismiss();
      if (response?.status === 201) {
        toast.success("Varification code sent to your email");
        resetForm();
        navigate(commonRoute.otpModule);
      } else if (response?.status === 403) {
        toast.error("Email already exists");
        resetForm();
      } else if (response?.status === 500) {
        toast.error("Something went wrong");
        resetForm();
      }
    }
    resetForm();
    setLoadButton(false);
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("registerEmail");
    if (storedEmail) {
      setInitialValues((prev) => ({ ...prev, email: storedEmail }));
      localStorage.removeItem("registerEmail");
    }
  }, []);

  const handleTermsConditionClick = () => {
    window.open(commonRoute.TermsandConditions, "_blank");
  };

  const handlePrivacyPolicyClick = () => {
    window.open(commonRoute.DataProtections, "_blank");
  };

  return (
    <SliderLayout title="Welcome">
      <div id="register-container">
        <div className="d-block d-sm-none">
          <MoblieNavBar
            rightIcon={icons.backArrowIcon}
            handleRightClick={() => navigate(-1)}
          />
        </div>
        <Card className="m-auto bg-ffff card-padding">
          <div className="text-20-400 mb-4 text-center">
            <p>Create an account to continue.</p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) =>
              handleRegister(values, { resetForm })
            }
            enableReinitialize
          >
            {(props) => {
              const { values, errors, handleChange, handleSubmit } = props;
              const { email, password, confirmPassword } = values;
              return (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(values);
                    }
                  }}
                >
                  <div className="mb-4">
                    <TextInput
                      placeholder="Email"
                      id="email"
                      value={email}
                      error={errors?.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <PasswordInput
                      placeholder="Password"
                      id="password"
                      value={password}
                      error={errors?.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-1">
                    <PasswordInput
                      placeholder="Confirm Password"
                      id="confirmPassword"
                      value={confirmPassword}
                      error={errors?.confirmPassword}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="text-14-400 my-2">
                    By registering you agree to the{" "}
                    <span
                      className="terms-link pointer text-decoration-underline"
                      onClick={handleTermsConditionClick}
                    >
                      Terms of Service
                    </span>{" "}
                    and{" "}
                    <span
                      className="terms-link pointer text-decoration-underline"
                      onClick={handlePrivacyPolicyClick}
                    >
                      Privacy Policy
                    </span>
                    .
                  </div>
                  <div className="mb-4 ">
                    <Button
                      type="submit"
                      btnText="Join Now"
                      btnStyle="GO"
                      className="w-100 m-2  bg-eaea position-relative"
                      text
                      rightIcon={icons.rightGoIcon}
                      iconClass="icon-right-end"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={loadButton}
                      btnLoading={loadButton}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>

          {/* OTP Verification Modal */}
          {/* <VerifyModel show={showVerifyModal} onHide={handleVerifyModalClose} /> */}

          <p className=" text-14-400 pt-5 ps-2">
            You already have an account? Then click here to log in.
          </p>
          <Button
            btnText="I already have an account"
            btnStyle="GO"
            className="w-100  bg-eaea m-2"
            text
            onClick={() => {
              navigate(commonRoute.login);
            }}
          />
        </Card>
      </div>
    </SliderLayout>
  );
};

export default Registration;
