import React from "react";
import Layouts from "pages/Layout/Layouts";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { commonRoute, icons } from "untils/constants";
import Button from "components/inputs/Button/Button";
import { useDispatch } from "react-redux";
import { handelLogout } from "store/slices";
import { getDataFromLocalStorage } from "untils/helpers";
import "./TermsAndCondition.scss";

const TermAndConditions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <MobileView>
        <MoblieNavBar
          rightIcon={icons.backArrowIcon}
          leftIcon={icons.Profile40Icon}
          handleRightClick={() => navigate(-1)}
          handleLeftClick={() => navigate(commonRoute.home)}
        />
      </MobileView>
      <div>
        <div className="row h-100">
          <div className="col-sm-10 mx-auto px-sm-3 px-0">
            <div className="fb-center border-bottom mt-1 mb-1">
              <div className="f-center">
                <img
                  className="img-logo1 w-120 h-120"
                  src={icons.enpowerLogo}
                  alt="img"
                />
                <span className="text-24-600 cpt-10 logoTest">EmPowerU</span>
              </div>
              <div className="f-center">
                <Button
                  type="submit"
                  btnText="Login"
                  btnStyle="GO"
                  className="w-100 m-2 bg-eaea position-relative cp-15"
                  text
                  //   rightIcon={icons.rightGoIcon}
                  iconClass="icon-right-end"
                  onClick={() => {
                    navigate(commonRoute.login);
                  }}
                />
                <Button
                  type="submit"
                  btnText="Sign Up"
                  btnStyle="GO"
                  className="w-100 m-2 bg-eaea position-relative cp-15"
                  text
                  //   rightIcon={icons.rightGoIcon}
                  iconClass="icon-right-end"
                  onClick={() => {
                    navigate(commonRoute.register);
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-column h-100">
              <h2 className="text-center my-2">Terms and Condition</h2>
              {/* <div className="b-sm-0000 br-sm-10 p-md-5 p-sm-3 px-0 new-session-block flex-grow-1 d-flex flex-column"> */}
              <div className="d-flex flex-column flex-grow-1">
                {/* <div
                    className="p-2 mt-3"
                    style={{ maxHeight: "700px", overflowY: "auto" }}
                  > */}
                <h1
                  style={{
                    paddingLeft: "5pt",
                    textIndent: 0,
                    lineHeight: "22pt",
                    textAlign: "left",
                  }}
                >
                  {/* Terms and Conditions */}
                </h1>
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "5pt",
                    textIndent: 0,
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  By downloading, installing, registering, accessing, or using
                  the EmPowerU App (referred to as the "App"), you automatically
                  agree to abide by the following terms and conditions (referred
                  to as the "Terms"). It's essential that you carefully read and
                  understand these Terms before utilizing the EmPowerU App. The
                  App, powered by an AI algorithm, offers services that enable
                  you to create and
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: 0,
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  share visual content based on the text and images you provide,
                  combined with artistic styles provided by the App
                  (collectively referred to as the "Services"). These Terms
                  grant you the right to use the EmPowerU App solely for
                  accessing and benefiting from the Services within the
                  specified scope.
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: 0,
                    textAlign: "left",
                  }}
                >
                  Please note the following restrictions:
                </p>
                <ol>
                  <li>
                    <p
                      style={{
                        paddingTop: "9pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      You are not permitted to copy, modify, or use our
                      trademarks in any way in connection with the EmPowerU App.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      Any attempt to extract the App's source code, translate it
                      into other languages, or create derivative versions is
                      strictly prohibited.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      All intellectual property rights, including trademarks,
                      copyrights, and database rights, associated with the
                      EmPowerU App, belong to Your Coaching Institute.
                    </p>
                  </li>
                </ol>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: 0,
                    lineHeight: "108%",
                    textAlign: "left",
                  }}
                >
                  Your Coaching Institute is dedicated to ensuring the
                  usefulness and efficiency of the EmPowerU App. Therefore, we
                  retain the right to make changes to the App or introduce
                  charges for its services at any time and for any reason. We
                  will always provide clear information regarding any charges
                  related to the EmPowerU App or Your Coaching Institute
                  Services.
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: 0,
                    lineHeight: "107%",
                    textAlign: "left",
                  }}
                >
                  The EmPowerU App collects and processes personal data provided
                  by you to deliver our Service. It is your responsibility to
                  maintain the security of your phone and access to the EmPowerU
                  App. We strongly advise against jailbreaking or rooting your
                  phone, as this could make it vulnerable to malware, viruses,
                  or other malicious programs, compromising the App's
                  functionality.
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: 0,
                    textAlign: "left",
                  }}
                >
                  Please be aware that Your Coaching Institute cannot assume
                  responsibility for certain aspects:
                </p>
                <ol>
                  <li>
                    <p
                      style={{
                        paddingTop: "9pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      The EmPowerU App requires an active internet connection
                      for specific functions. While this connection can be Wi-Fi
                      or provided by your mobile network, Your Coaching
                      Institute is not liable if the App does not function at
                      full capacity due to a lack of Wi-Fi or data allowance.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      When using the EmPowerU App outside of an area with Wi-Fi,
                      your mobile network provider's terms of agreement still
                      apply. You may incur charges for data usage, and it is
                      your responsibility to cover these costs, including
                      roaming data charges if you use the App outside your home
                      territory.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      If you are not the bill payer for the device on which you
                      use the EmPowerU App, we assume that you have obtained
                      permission from the bill payer.
                    </p>
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "justify",
                      }}
                    >
                      Additionally, Your Coaching Institute cannot accept
                      responsibility for the proper use of the EmPowerU App. It
                      is your responsibility to ensure your device remains
                      charged, as Your Coaching Institute cannot be held liable
                      if your device runs out of battery and prevents access to
                      the App's services.
                    </p>
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      Regarding Your Coaching Institute's responsibility for
                      your use of the EmPowerU App, please note that while we
                      strive to keep it updated and accurate, we rely on third
                      parties to provide information for the App's
                      functionality. Therefore, Your Coaching Institute is not
                      liable for any direct or indirect losses you may
                      experience from relying solely on this functionality of
                      the EmPowerU App.
                    </p>
                    <p
                      style={{
                        paddingLeft: "5pt",
                        textIndent: 0,
                        textAlign: "left",
                      }}
                    >
                      In the future, there may be updates to the EmPowerU App,
                      which is currently available on both iOS and Android
                      platforms. The system requirements for the app may change,
                      and you will need to download these updates if you wish to
                      continue using the EmPowerU App. Please note that Your
                      Coaching Institute cannot guarantee that updates will
                      always align with your device's iOS or Android version or
                      remain relevant to your needs. Nevertheless, you agree to
                      accept and install any offered updates to the application.
                    </p>
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      It's important to be aware that Your Coaching Institute
                      reserves the right to discontinue the EmPowerU App at any
                      time without prior notice. Upon termination, the following
                      will occur unless otherwise specified:
                    </p>
                    <ol id="l3">
                      <li data-list-text="(a)">
                        <p
                          style={{
                            paddingTop: "8pt",
                            paddingLeft: "20pt",
                            textIndent: "-14pt",
                            textAlign: "left",
                          }}
                        >
                          The rights and licenses granted to you under these
                          terms will cease.
                        </p>
                      </li>
                      <li data-list-text="(b)">
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "20pt",
                            textIndent: "-14pt",
                            textAlign: "left",
                          }}
                        >
                          You must cease using the EmPowerU App and, if
                          necessary, delete it from your device.
                        </p>
                      </li>
                    </ol>
                    <h4
                      style={{
                        paddingTop: "9pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        textAlign: "left",
                      }}
                    >
                      User Content:
                    </h4>
                    <p
                      style={{
                        paddingTop: "9pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      You have the option to provide, upload, transmit, create,
                      store, use, edit, or share various types of content, such
                      as data, information, text, questions, and materials,
                      through the EmPowerU App's services. This content includes
                      both what you input (Input) and what you receive as output
                      (Output) from the services, collectively referred to as
                      "User Content." However, you may only upload, transmit,
                      create, store, use, edit, and share User Content in
                      compliance with these Terms and Conditions, including Your
                      Coaching Institute's End-User License Agreement, and all
                      applicable laws.
                    </p>
                    <p
                      style={{
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      services. This content includes both what you input
                      (Input) and what you receive as output (Output) from the
                      services, collectively referred to as "User Content."
                      However, you may only upload, transmit, create, store,
                      use, edit, and share User Content in compliance with these
                      Terms and Conditions,
                    </p>
                    <p
                      style={{
                        paddingLeft: "5pt",
                        textIndent: 0,
                        textAlign: "left",
                      }}
                    >
                      including Your Coaching Institute's End-User License
                      Agreement, and all applicable laws.
                    </p>
                    <p
                      style={{
                        paddingTop: "9pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      By providing User Content, you represent and warrant that
                      you have legal ownership of the Input, possess all
                      necessary consents, permissions, licenses, and rights to
                      provide and license the Input as specified in these Terms
                      and Conditions. Furthermore, you affirm that you have the
                      legal capacity to enter into these Terms and Conditions in
                      your relevant jurisdiction.
                    </p>
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      Upon uploading or transmitting Input, you grant Your
                      Coaching Institute a non-exclusive, irrevocable, fully
                      paid, royalty-free, perpetual, sublicensable,
                      transferrable, worldwide license and right to utilize,
                      broadcast, reproduce, modify, create derivative works
                      from, record, sublicense, translate, transmit, or
                      otherwise exploit the User Content. This license is for
                      the purpose of operating or enhancing existing and future
                      Your Coaching Institute products, including but not
                      limited to EmPowerU training, in any format, medium, or
                      technology, without the need for notice, permission,
                      payment, or additional compensation to you or any third
                      party.
                    </p>
                    <p
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "justify",
                      }}
                    >
                      You are solely responsible for User Content and the
                      consequences of its posting or publication in any manner.
                      This includes ensuring that User Content complies with
                      applicable laws and these Terms and Conditions, including
                      Your Coaching Institute's End-User License Agreement.
                      Additionally, you
                    </p>
                    <p
                      style={{
                        paddingLeft: "5pt",
                        textIndent: 0,
                        textAlign: "justify",
                      }}
                    >
                      acknowledge that Your Coaching Institute holds no
                      responsibility or liability for User Content,
                    </p>
                    <p
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "justify",
                      }}
                    >
                      including copyrightable materials such as literary works,
                      phrases, text, and other content provided within the
                      Services.
                    </p>
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        lineHeight: "108%",
                        textAlign: "left",
                      }}
                    >
                      You are prohibited from uploading, transmitting, creating,
                      storing, editing, using, or sharing any User Content that
                      violates these Terms and Conditions, including Your
                      Coaching Institute's End-User License Agreement, or any
                      other applicable laws. You agree to indemnify and hold the
                      Indemnitees (as defined in Your Coaching Institute's
                      End-User License Agreement) in accordance with Article 15
                      "Indemnification" of the Your Coaching Institute End-User
                      License Agreement from any Claim (as defined in Your
                      Coaching Institute's End-User License Agreement) arising
                      directly or indirectly from allegations that your User
                      Content infringes upon the Intellectual Property Rights
                      (as defined in Your Coaching Institute's End-User License
                      Agreement) of any third party or results from your breach
                      of these Terms and Conditions.
                    </p>
                    <p
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "5pt",
                        textIndent: 0,
                        textAlign: "left",
                      }}
                    >
                      Furthermore, you acknowledge and agree that:
                    </p>
                    <ol id="l4">
                      <li data-list-text="(i)">
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "17pt",
                            textIndent: "-11pt",
                            textAlign: "left",
                          }}
                        >
                          Output is generated by Artificial Intelligence;
                        </p>
                      </li>
                      <li data-list-text="(ii)">
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "19pt",
                            textIndent: "-14pt",
                            textAlign: "left",
                          }}
                        >
                          Output may be inaccurate or misleading;
                        </p>
                      </li>
                      <li data-list-text="(iii)">
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "22pt",
                            textIndent: "-16pt",
                            textAlign: "left",
                          }}
                        >
                          Your Coaching Institute does not guarantee the
                          accuracy or authenticity of Output;
                        </p>
                      </li>
                      <li data-list-text="(iv)">
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "5pt",
                            textIndent: 0,
                            lineHeight: "108%",
                            textAlign: "left",
                          }}
                        >
                          Output should not be considered advice, guidance, or
                          information provided by Your Coaching Institute;
                        </p>
                      </li>
                      <li data-list-text="(v)">
                        <p
                          style={{
                            paddingTop: "8pt",
                            paddingLeft: "19pt",
                            textIndent: "-14pt",
                            textAlign: "left",
                          }}
                        >
                          Your Coaching Institute bears no responsibility or
                          liability for Output.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
                <p
                  style={{
                    "padding-top": "9pt",
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "line-height": "108%",
                    "-webkit-text-align": "left",
                    "text-align": "left",
                  }}
                >
                  Additionally, you understand that Output may not always be
                  unique, as similar input provided by other users could yield
                  similar results.
                </p>
                <h4
                  style={{
                    "padding-top": "8pt",
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "-webkit-text-align": "left",
                    "text-align": "left",
                  }}
                >
                  Changes to This Terms and Conditions
                </h4>
                <p
                  style={{
                    "padding-top": "9pt",
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "line-height": "108%",
                    "-webkit-text-align": "left",
                    "text-align": "left",
                  }}
                >
                  We may unilaterally amend and update our Terms and Conditions
                  from time to time. Thus, you are advised to review this page
                  periodically for any changes. We will notify you of any
                  changes by posting the new Terms and Conditions on this page.
                  These amendments shall be effective on the date when they are
                  published. The User is obliged to follow these amendments and
                  updates and shall be deemed to have agreed with these
                  amendments in case the User confirms these amendments or
                  continues to use the EmPowerU App following the publication.
                </p>
                <p
                  style={{
                    "padding-top": "8pt",
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "-webkit-text-align": "left",
                    "text-align": "left",
                  }}
                >
                  The User acknowledges that it cannot gain a title and/or
                  status of Your Coaching Institute’s
                </p>
                <p
                  style={{
                    "padding-top": "1pt",
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "-webkit-text-align": "left",
                    "text-align": "left",
                  }}
                >
                  representative, authorized person, agent, commercial
                  representative, stakeholder, solution or
                </p>
                <p
                  style={{
                    "padding-top": "1pt",
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "line-height": "108%",
                    "-webkit-text-align": "left",
                    "text-align": "left",
                  }}
                >
                  business partner, dealer, etc., by concluding these Terms and
                  Conditions; therefore, the User shall not be able to disclose
                  a notification to be interpreted as such in any documents,
                  brochures, and
                </p>
                <p
                  style={{
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "line-height": "108%",
                    "-webkit-text-align": "left",
                    "text-align": "left",
                  }}
                >
                  advertisements to be arranged by and between third parties, on
                  its website or in its references. These Terms and Conditions
                  are effective as of 15 February 2023.
                </p>
                <h4
                  style={{
                    "padding-top": "8pt",
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "-webkit-text-align": "left",
                    "text-align": "left",
                  }}
                >
                  Contact Us
                </h4>
                <p
                  style={{
                    "padding-top": "9pt",
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "line-height": "106%",
                    "-webkit-text-align": "left",
                    "text-align": "left",
                  }}
                >
                  <a
                    href="mailto:info@ihrcoachinginstitut.com"
                    className="a"
                    target="_blank"
                  >
                    If you have any questions or suggestions about our Terms and
                    Conditions, do not hesitate to contact us at via
                    {"  "}
                  </a>
                  <a href="mailto:info@ihrcoachinginstitut.com" target="_blank">
                    info@ihrcoachinginstitut.com.
                  </a>
                </p>
                <h4
                  style={{
                    "padding-top": "8pt",
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "-webkit-text-align": "left",
                    "text-align": "left",
                  }}
                >
                  YOUR COACHING INSTITUTE END-USER LICENSE AGREEMENT
                </h4>
                <p
                  style={{
                    "padding-top": "9pt",
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "line-height": "108%",
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  These terms and conditions legally create an agreement between
                  you, as the User, and Your Coaching Institute. Please read all
                  terms and conditions and further Your Coaching Institute
                  Service scope
                </p>
                <p
                  style={{
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "line-height": "108%",
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  carefully. By using (as defined under Article 1.1), you agree
                  with our terms as set out under this Terms and Conditions,
                  including the Your Coaching Institute End-User License
                  Agreement and Privacy Policy. If you do not agree to the Terms
                  and Conditions (including the Your Coaching Institute End-User
                </p>
                <p
                  style={{
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "line-height": "108%",
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  License Agreement and Privacy Policy) do not Use EmPowerU App.
                  Your continued Use will confirm your acceptance of the Terms
                  and Conditions.
                </p>
                <ol id="l5">
                  <li data-list-text={1}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "16pt",
                        "-webkit-text-indent": "-11pt",
                        "text-indent": "-11pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      TERMS OF USE
                    </h4>
                    <ol id="l6">
                      <li data-list-text="1.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "justify",
                            "text-align": "justify",
                          }}
                        >
                          Your Coaching Institute, a German corporation located
                          in Frankfurt, is pleased to provide you the EmPowerU
                          App, as the user (User), its branded mobile
                          applications and any and all related
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "justify",
                            "text-align": "justify",
                          }}
                        >
                          documentation (each, an “Your Coaching Institute App”
                          and collectively the “Your Coaching Institute Apps”) a
                          list of which is available at the Apple App Store
                          and/or Google Play. You hereby agree to be bound by
                          these terms of service any time you use or access
                          EmPowerU App. These terms and
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          conditions are set forth by Your Coaching Institute
                          for any of its products or services located in the
                          Apple App Store and/or Google Play and/or any other
                          online platform (each a “Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute Service” and collectively the “Your Coaching
                          Institute Services”), excluding any provision
                          specifically referring to EmPowerU App. Your use of
                          the Your Coaching Institute Services is subject to
                          your acceptance and compliance with these terms. “Use”
                          or “using” means hereby to access, use,
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          install, download, register with, copy, in-app
                          purchasing or otherwise benefit from using the
                          functionality of the Your Coaching Institute Apps as
                          per the documentation. Please read this End-User
                          License Agreement (the “Agreement”) carefully before
                          accessing, installing, downloading, registering with
                          or using any Your Coaching Institute Apps, for the
                          concerning Agreement governs your Use of the Your
                          Coaching Institute Apps and how we provide the Your
                          Coaching Institute Apps. If you do not agree these
                          terms, do not use the Your Coaching Institute
                          Services. Each time you use or access the Your
                          Coaching Institute Services, the current version of
                          these terms shall apply. This Agreement also
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          includes additional payment terms, in-app purchase
                          options, and other requirements set forth on the
                          download or purchase page/platform through which you
                          purchase or download the Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute Apps.
                        </p>
                      </li>
                      <li data-list-text="1.2.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "24pt",
                            "-webkit-text-indent": "-19pt",
                            "text-indent": "-19pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Your Use or access of the Your Coaching Institute
                          Services is also subject to Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute’s Privacy Policy (the “Privacy Policy”).
                          Additionally, you agree to abide by our rules,
                          policies, and procedures we may publish on the Your
                          Coaching Institute Services from time to time. We
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          reserve the right at any time and without notice to
                          change these terms. Without prejudice to any rights
                          implied by law or under the provisions of this
                          Agreement, you also acknowledge that in-app messages
                          which notify you of such changes when you open up,
                          use, or access the Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute Apps shall constitute reasonable
                          notification means. For the avoidance of doubt, your
                          continued use of the Your Coaching Institute Apps
                          after we post amendments or apply changes to this
                          Agreement shall signify your acceptance of such
                          amendments and/or changes. If you do not agree
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "106%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          with any amendment, you must discontinue using the
                          Your Coaching Institute Apps. If you have any further
                          questions or concerns with respect to the terms
                          herein, please contact us at
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          <a href="mailto:info@ihrcoachinginstitut.de">
                            info@ihrcoachinginstitut.de.
                          </a>
                        </p>
                      </li>
                      <li data-list-text="1.3.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "24pt",
                            "-webkit-text-indent": "-19pt",
                            "text-indent": "-19pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          For the avoidance of doubt, all provisions in this
                          Agreement that refer to the Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute Apps and the Your Coaching Institute
                          Services are general terms and conditions set forth by
                          Your Coaching Institute regarding its products and
                          services. These provisions, while binding for the
                          User, only grant the User the rights specified herein
                          for the EmPowerU App. This Agreement cannot be
                          interpreted as granting any right to the User with
                          regards to other Your Coaching Institute Apps or Your
                          Coaching Institute Services.
                        </p>
                      </li>
                      <li data-list-text="1.4.">
                        <h4
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          NOTICE TO THE USER:{"  "}
                        </h4>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          THIS IS A LEGALLY BINDING AGREEMENT. IF YOU DO NOT
                          UNDERSTAND THIS AGREEMENT, OR DO NOT AGREE TO BE BOUND
                          BY IT OR THE PRIVACY POLICY REFERENCED HEREIN, YOU
                          MUST IMMEDIATELY LEAVE THE APPLE APP STORE AND/OR
                          GOOGLE PLAY AND YOU ARE NOT AUTHORIZED TO USE OR
                          ACCESS ANY OF THE YOUR COACHING INSTITUTE SERVICES.
                          DEPENDING ON THE LAWS OF THE JURISDICTION WHERE YOU
                          LIVE, YOU MAY HAVE CERTAIN RIGHTS THAT CANNOT BE
                          WAIVED THROUGH THIS AGREEMENT AND THAT ARE IN ADDITION
                          TO THE TERMS OF THIS AGREEMENT AND CERTAIN PROVISIONS
                          OF THIS AGREEMENT MIGHT BE UNENFORCEABLE AS TO YOU. TO
                          THE EXTENT THAT ANY TERM OR CONDITION OF THIS
                          AGREEMENT IS
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          UNENFORCEABLE, THE REMAINDER OF THE AGREEMENT SHALL
                          REMAIN IN FULL FORCE AND EFFECT. YOU HEREBY CONFIRM
                          THAT YOU ARE AT LEAST 18 YEARS OLD (OR IF YOU ARE
                          UNDER 18 YEARS OLD OR UNDER THE LEGAL MAJORITY AGE
                          WHERE YOU LIVE, THAT YOU ARE USING THE APPLE APP STORE
                          AND/OR GOOGLE PLAY ONLY WITH THE APPROVAL OF YOUR
                          PARENTS OR LEGAL GUARDIAN), THAT YOU ARE LEGALLY ABLE
                          TO ENTER INTO THIS AGREEMENT, AND THAT YOU HAVE
                          COMPLETELY READ, UNDERSTOOD AND AGREE TO BE BOUND BY
                          THIS AGREEMENT. PLEASE BE AWARE THAT, IN ORDER
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          TO USE GOOGLE PLAY, YOU MUST HAVE A VALID GOOGLE
                          ACCOUNT, SUBJECT TO THE FOLLOWING AGE RESTRICTIONS AND
                          ALSO YOU MUST COMPLY WITH ANY ADDITIONAL AGE
                          RESTRICTIONS THAT MAY APPLY FOR THE USE OF SPECIFIC
                          CONTENT OR FEATURES ON GOOGLE PLAY. DUE TO THE
                          PROVISIONS OF GENERAL DATA PROTECTION REGULATION, WE
                          DO NOT ALLOW USE OF EMPOWERU APP BY THE RESIDENTS OF
                          THE EUROPEAN ECONOMIC AREA THAT ARE YOUNGER THAN 16
                          YEARS OLD TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
                        </p>
                      </li>
                      <li data-list-text="1.5.">
                        <h4
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          NOTICE TO PARENTS AND LEGAL GUARDIANS (continued):
                          {"  "}
                        </h4>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          ERCISING SUPERVISION OVER YOUR CHILD’S ANY ONLINE AND
                          OFF-LINE ACTIVITIES OR OMISSIONS. IF YOU DO NOT AGREE
                          TO THIS AGREEMENT, PLEASE DO NOT LET YOUR CHILD USE
                          THE YOUR COACHING INSTITUTE APPS OR ASSOCIATED
                          FEATURES. IF YOU ARE THE PARENT OR LEGAL GUARDIAN OF A
                          CHILD UNDER 18 AND BELIEVE THAT HE OR SHE IS USING THE
                          YOUR COACHING INSTITUTE APPS WITHOUT YOUR PRIOR
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          <a
                            href="mailto:info@ihrcoachinginstitut.de"
                            className="a"
                            target="_blank"
                          >
                            APPROVAL, PLEASE CONTACT US AT{"  "}
                          </a>
                          <a
                            href="mailto:info@ihrcoachinginstitut.de"
                            target="_blank"
                          >
                            info@ihrcoachinginstitut.de.
                          </a>
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={2}>
                    <h4
                      style={{
                        "padding-top": "9pt",
                        "padding-left": "16pt",
                        "-webkit-text-indent": "-11pt",
                        "text-indent": "-11pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      PARTIES
                    </h4>
                    <ol id="l7">
                      <li data-list-text="2.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          This Agreement is concluded between Your Coaching
                          Institute and you, as the User, who downloaded and/or
                          installed through the Apple App Store and/or Google
                          Play; and executed and mutually entered into force
                          upon the online approval of the User. Your Coaching
                          Institute and the User shall be hereinafter referred
                          to individually as the “Party” and collectively as the
                          “Parties”.
                        </p>
                      </li>
                      <li data-list-text="2.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          By downloading and/or installing the Your Coaching
                          Institute App through the Apple App Store or Google
                          Play, the User agrees, undertakes, and represents that
                          he/she has read all the terms herein, understood all
                          the contents and approved all provisions.
                        </p>
                      </li>
                      <li data-list-text="2.3.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "24pt",
                            "-webkit-text-indent": "-19pt",
                            "text-indent": "-19pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          In case the User is a “consumer” under the Consumer
                          Protection Act of Germany and the
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          applicable consumer legislation, the User can benefit
                          from the rights and powers vested in consumers
                          thereunder to the extent applicable. If the User is
                          considered as a consumer under the German and to the
                          extent applicable, the other relevant legislation
                          he/she should give prior notice to duly inform the
                          Your Coaching Institute to use its consumer rights.
                          The User accepts and declares that he/she understands
                          this situation and that he/she shall act in line with
                          the applicable consumer legislation, if necessary.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={3}>
                    <h4
                      style={{
                        "padding-top": "7pt",
                        "padding-left": "16pt",
                        "-webkit-text-indent": "-11pt",
                        "text-indent": "-11pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      YOUR COACHING INSTITUTE CONTACT INFORMATION
                    </h4>
                    <p
                      style={{
                        "padding-top": "9pt",
                        "padding-left": "5pt",
                        "-webkit-text-indent": "0pt",
                        "text-indent": "0pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      <a
                        href="mailto:info@ihrcoachinginstitut.de"
                        className="a"
                        target="_blank"
                      >
                        Senckenberganlage 10–12 60325 Frankfurt am Main{"{"}" "
                        {"}"}
                      </a>
                      <a
                        href="mailto:info@ihrcoachinginstitut.de"
                        target="_blank"
                      >
                        info@ihrcoachinginstitut.de
                      </a>
                    </p>
                  </li>
                  <li data-list-text={4}>
                    <h4
                      style={{
                        "padding-top": "9pt",
                        "padding-left": "16pt",
                        "-webkit-text-indent": "-11pt",
                        "text-indent": "-11pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      GRANT OF LICENSE
                    </h4>
                    <ol id="l8">
                      <li data-list-text="4.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Subject to your compliance with the terms of the
                          Agreement, Your Coaching Institute grants you a
                          limited, non-exclusive, revocable, non-sublicensable,
                          non-transferable license to access, download and
                          install the most current generally available version
                          of the ASK AI App on a single, authorized mobile
                          device that you own or control solely for your lawful,
                          personal, as an end-user, and non-commercial use.
                        </p>
                      </li>
                      <li data-list-text="4.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "24pt",
                            "-webkit-text-indent": "-19pt",
                            "text-indent": "-19pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          For the avoidance of doubt, all Your Coaching
                          Institute Apps is protected by copyright laws and
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          international copyright treaties, as well as other
                          intellectual laws and treaties. Your Coaching
                          Institute is the exclusive owner of any software,
                          design, source code, target code, directory, image or
                          content available on Your Coaching Institute Apps.
                          Your Coaching Institute reserves all rights not
                          expressly granted to the User as per the provisions of
                          Article 11, hereunder. Having said that, Your Coaching
                          Institute retains the ownership of the copyright in
                          and to the Your Coaching Institute Apps. The User
                          cannot duplicate, copy or distribute or process the
                          advertisements, images and texts, visual and audio
                          images, files, databases, catalogues and lists
                          available on Your Coaching Institute Apps nor rent,
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          lease or lend the Your Coaching Institute Apps to
                          anyone and permanently transfer all of his/her
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          rights under this Agreement. Your Coaching Institute
                          holds no responsibility of the results of using the
                          Your Coaching Institute Apps acquired illegally or
                          through an unauthorized distributor.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={5}>
                    <p
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "16pt",
                        "-webkit-text-indent": "-10pt",
                        "text-indent": "-10pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS
                    </p>
                    <ol id="l9">
                      <li data-list-text="5.1.">
                        <h4
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Restricted Use:{"  "}
                        </h4>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          While using Your Coaching Institute Apps, Users
                          declare and undertake not to engage in actions that
                          are contrary to the law and morality or that may
                          damage the opportunity to benefit from the Your
                          Coaching Institute Services, including but not limited
                          to the examples listed below. Your Coaching Institute
                          shall not assume any responsibility and/or
                          indemnification liability for damages arising from any
                          breach of this article. In the event that the User
                          acts in breach of this
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          article, Your Coaching Institute shall reserve the
                          right to terminate this Agreement pursuant to Article
                          14, to block the User's access to Your Coaching
                          Institute Apps either for a certain period of time or
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          indefinitely. Furthermore, Your Coaching Institute
                          reserves the right to resort to civil law and criminal
                          law remedies.
                        </p>
                      </li>
                      <li data-list-text="5.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The User shall not rent, sell, lease, sublicense,
                          distribute, assign, copy (other than a single copy for
                          your own backup purposes) or in any way transfer Your
                          Coaching Institute Apps or any rights
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          arising from the Your Coaching Institute Apps, and
                          shall not use Your Coaching Institute Apps for the
                          benefit of any third party. Unless expressly
                          authorized by Your Coaching Institute, the User is
                          prohibited from making the Your Coaching Institute
                          Apps available over a network where it could be
                          downloaded or used by multiple users. The User agrees
                          that he/she shall not use any robot, spider, other
                          automatic or manual device or process to interfere or
                          attempt to interfere with the proper working of the
                          Your Coaching Institute Apps, except to uninstall or
                          remove the Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute Apps from a mobile device which the User
                          owns or controls. The User shall not deliver
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "107%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          unlawful information and/or share harmful data such as
                          chain mail, malware, viruses. The User shall not
                          violate or attempt to violate the security of Your
                          Coaching Institute Services except as and only to the
                          extent permitted in this Agreement and by applicable
                          law. The User shall not engage in behaviors and
                          activities that will adversely affect, obstruct, or
                          manipulate the operation of Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute Apps, disable security systems, or make the
                          Your Coaching Institute Apps unusable or make an
                          attempt in this manner by preparing automatic
                          programs.
                        </p>
                      </li>
                      <li data-list-text="5.3.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          User cannot copy, adapt, translate, decompile, reverse
                          engineer, disassemble, modify, recode or create
                          derivative works of the Your Coaching Institute Apps
                          or advertise the Your Coaching Institute Apps in any
                          form. User shall not access, create or modify the
                          source code of any Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute Apps in any way. User does not have the
                          right to and may not create derivative works of any
                          the Your Coaching Institute Apps or any portions
                          thereof. All modifications or enhancements to the Your
                          Coaching Institute Apps remain the sole property of
                          Your Coaching Institute. User agrees and undertakes
                          not to engage in any other act to find, obtain or copy
                          the source code of Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute Apps; shall not in any way try and
                          synchronize Your Coaching Institute Apps with other
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          software or hardware; shall not violate the security
                          of any computer network; shall not hack security
                          passwords and codes, shall not attempt to deliver SPAM
                          mail or upload malware, otherwise agrees, declares and
                          undertakes to assume the liability for any damages of
                          Your Coaching Institute and third parties.
                        </p>
                      </li>
                      <li data-list-text="5.4.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Your Coaching Institute may restrict or terminate
                          access to Your Coaching Institute Apps at any time and
                          without giving any further notice in case the
                          operating security of the network is at risk, in order
                          to ensure the continuity of access to the network, to
                          prevent malfunctions that may occur in network,
                          software or uploaded files, to prevent or reduce the
                          adverse effects of possible disruptions, and in other
                          cases deemed necessary.
                        </p>
                        <ol id="l10">
                          <ol id="l11">
                            <li data-list-text="5.6.">
                              <h4
                                style={{
                                  "padding-top": "1pt",
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Updates:{"  "}
                              </h4>
                              <p
                                style={{
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Your Coaching Institute reserves the right to
                                add or remove features or functions to the
                                existing Your Coaching Institute Apps. When
                                installed on the User’s mobile device, Your
                                Coaching Institute periodically communicates
                                with our servers. Your Coaching Institute may
                                require the updating of the Your Coaching
                                Institute Apps on User’s mobile device when Your
                                Coaching Institute releases a new version of the
                                Your Coaching Institute Apps or when Your
                                Coaching Institute makes new features available.
                                This update may occur automatically or upon
                                prior notice to the User and may occur all at
                                once or over multiple sessions. The User
                                understands that Your Coaching Institute may
                                require User’s review and acceptance of Your
                                Coaching Institute’s then-current Agreement
                                before User will be permitted to use any
                                subsequent versions of the Your Coaching
                                Institute Apps.
                              </p>
                              <p
                                style={{
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                User acknowledges and agrees that any obligation
                                Your Coaching Institute may have to support
                              </p>
                              <p
                                style={{
                                  "padding-top": "1pt",
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                previous versions of the Your Coaching Institute
                                Apps may be ended upon the availability of
                                updates, supplements or subsequent versions of
                                the Your Coaching Institute Apps. User
                                acknowledges and agrees that Your Coaching
                                Institute has no obligation to make available to
                                User any updates,
                              </p>
                              <p
                                style={{
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                supplements or subsequent versions of the Your
                                Coaching Institute Apps. Please be aware that
                                such updates may be necessary for you to use
                                Google Play/App Store or to access, download, or
                                use content. By agreeing to these terms and
                                using Google Play/App Store, you agree to
                                receive such updates automatically. You may be
                                able to manage updates to certain content via
                                settings in Google Play/App Store. However, if
                                it is determined that the update will fix a
                                critical security vulnerability related to the
                                content, the update may be completed
                                irrespective of your update settings in Google
                                Play/App Store or your device. If another app
                                store attempts to update content that was
                                initially downloaded from Google Play/App Store,
                                you may receive a warning, or such updates may
                                be
                              </p>
                              <p
                                style={{
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                prevented entirely.
                              </p>
                            </li>
                            <li data-list-text="5.7.">
                              <h4
                                style={{
                                  "padding-top": "9pt",
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Access:{"  "}
                              </h4>
                              <p
                                style={{
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                The User must provide at his/her expense the
                                equipment, internet connections, devices, and
                                service plans to access and use the Your
                                Coaching Institute Apps. If the User accesses
                                the Your Coaching Institute Apps through a
                                mobile network, your network or roaming
                                provider’s messaging, data and other rates and
                                fees may apply. The User is solely responsible
                                for any costs you incur to access the Your
                                Coaching Institute App from his/her device.
                                Downloading, installing, or using certain the
                                Your Coaching Institute Apps may be prohibited
                                or restricted by your network provider, and not
                                all the Your Coaching Institute Apps may work
                                with your network provider or device. Your
                                Coaching Institute makes no representation that
                                the Your Coaching Institute Apps can be accessed
                                on all devices or wireless service plans. Your
                                Coaching Institute makes no representation that
                                the Your Coaching Institute Apps are available
                                in all languages or that the Your Coaching
                                Institute Apps are appropriate or available for
                                use in any particular location. Please also be
                                aware that you must keep your account details
                                secure and must not share them with anyone else.
                                You must not collect or harvest any personal
                                data of any User of Apple App Store and/or
                                Google Play or of any User of other Apple and/or
                                Google Services via Apple App Store/Google Play,
                                including account names.
                              </p>
                            </li>
                            <li data-list-text="5.8.">
                              <h4
                                style={{
                                  "padding-top": "7pt",
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Purchase and Cancellation Rights:{"  "}
                              </h4>
                              <p
                                style={{
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Certain Your Coaching Institute Apps are
                                available for purchase from a mobile platform
                                owner (e.g., Apple or Google) and/or will allow
                                the User to make inapp purchases. Payment for
                                such purchases may be processed by third parties
                                who act on behalf of Your Coaching Institute or
                                directly by the mobile platform owner. In some
                                countries, there are specific time periods to
                                cancel online purchases after purchasing them
                                provided by law (European Union residents, etc).
                                For these countries, consumers may have a right
                                to cancel in a specific number of days after
                                purchasing products/services online provided by
                                law. Therefore, your right to cancel in-app
                                purchases will rely on the country you reside
                                in. Since payment processes of certain purchases
                                on Your Coaching Institute Apps will be
                                conducted by the mobile platform owner, failure
                                to follow certain local laws regulating the
                                right to cancel will be subject to mobile
                                platform owners’ terms. Please also review the
                                mobile platform owner’s terms in this regard
                                before purchase. You can find further
                              </p>
                              <p
                                style={{
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                information on cancelling orders and any
                                associated refunds on the website of the
                                third-party re-
                              </p>
                              <p
                                style={{
                                  "padding-top": "1pt",
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                seller from whom you purchased the app (the
                                Apple App Store and/or Google Play). Where you
                                purchase from Your Coaching Institute directly:
                                please note and acknowledge that if you are a
                              </p>
                              <p
                                style={{
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                resident in the European Union or your local law
                                regulates a right to cancel and download the
                                Your Coaching Institute Apps from Your Coaching
                                Institute directly, you agree to waive your
                                cancellation and refund right once the download
                                of the app or the relevant purchase is made.
                                Please note that if you are not a resident in
                                the European Union and if your local laws do not
                                regulate mandatory laws otherwise, you have
                                already no right to cancel purchases you made if
                                you download Your Coaching Institute Apps from
                                Your Coaching Institute directly. This means
                                that you will not be able to cancel your order
                                or obtain a refund once the download and
                                delivery of the app is complete. This will also
                                apply to subscriptions and in-app purchases. In
                                this regard, please also take into consideration
                                the provisions of Article 13 of the Agreement.
                              </p>
                            </li>
                            <li data-list-text="5.9.">
                              <h4
                                style={{
                                  "padding-top": "8pt",
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Defective Content:{"  "}
                              </h4>
                            </li>
                            <li data-list-text="5.10.">
                              <p
                                style={{
                                  "padding-top": "8pt",
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Once the Your Coaching Institute Apps are
                                available to you through your account, you are
                                required to check the content as soon as
                                reasonably possible to ensure that the Your
                                Coaching Institute Apps function and perform as
                                stated and notify us or Google Play/App Store as
                                soon as reasonably possible provided that you
                                find any errors or defect. Without prejudice to
                                any other rights, Your Coaching Institute may
                                terminate this Agreement if User fails to comply
                                with the terms of this Agreement and other
                                documents, referred to herein. In such event,
                                the User must uninstall or remove the Your
                                Coaching Institute Apps. In this regard, please
                                also take into consideration the provisions of
                                Article 14 of the Agreement.
                              </p>
                            </li>
                          </ol>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={6}>
                    <h4
                      style={{
                        "padding-top": "7pt",
                        "padding-left": "16pt",
                        "-webkit-text-indent": "-11pt",
                        "text-indent": "-11pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      THIRD-PARTY PARTNERS
                    </h4>
                    <ol id="l12">
                      <li data-list-text="6.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The Your Coaching Institute Apps allow you to enjoy
                          various features, functionalities, and other Your
                          Coaching Institute Services, which may change from
                          time to time (collectively the “Your Coaching
                          Institute Apps Functions”). The Your Coaching
                          Institute Apps Functions are provided by Your Coaching
                          Institute and third-party suppliers who offer content
                          and/or services in conjunction with or through the
                          Your Coaching Institute Apps (the “Third Party
                          Partners”).
                        </p>
                      </li>
                      <li data-list-text="6.2.">
                        <h4
                          style={{
                            "padding-top": "7pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Third-Party Services and Content:{"  "}
                        </h4>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The Your Coaching Institute Apps may integrate, be
                          integrated into, bundled, or be provided in connection
                          with third-party services, advertising, feeds, and/or
                          content. If the User is installing the Your Coaching
                          Institute App that includes third party services and
                          third-party content, such services and content are
                          subject to such third party’s terms of services and
                          privacy policies, which might be found on the relevant
                          Third-Party Partners’ website. Please keep that in my
                          mind that Your Coaching Institute has no control over
                          such websites and resources, and the User acknowledges
                          and agrees that Your Coaching Institute shall not be
                          responsible or liable, directly or indirectly, for any
                          damage or loss caused or alleged to be caused by or in
                          connection with use of or reliance on any such Content
                          (as defined in 6.3.), goods or services available on
                          or through any such website or resource. Having said
                          that, Your Coaching Institute shall not be a party to
                          or in any way be responsible for monitoring any
                          transaction between the User and the Third-Party
                          Partners.
                        </p>
                      </li>
                      <li data-list-text="6.3.">
                        <h4
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "25pt",
                            "-webkit-text-indent": "-19pt",
                            "text-indent": "-19pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Access to the Third Party Services and Content via the
                          Your Coaching Institute Apps:{"  "}
                          <span className="p">All</span>
                        </h4>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          services, advertising, feeds, and content, including
                          without limitation, all data, links, articles, graphic
                          or video messages and all information, text, software,
                          music, sound, graphics, or other materials (the
                          “Content”) made available or accessible via the Your
                          Coaching Institute Apps, whether publicly
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          available or privately transmitted, is the sole
                          responsibility of the entity or person from whom it
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          originated. You, as the User, hereby acknowledge and
                          agree that by using the Your Coaching Institute Apps
                          you may be exposed to Content that may be offensive,
                          indecent, or objectionable in your community. You
                          agree to accept all risks associated with the use of
                          any Content, including any
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          reliance on the accuracy or completeness of such
                          Content. Under no circumstances will Your Coaching
                          Institute be liable in any way for any Content created
                          by or originating with entities other
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          than Your Coaching Institute, including but not
                          limited to, any errors or omissions in any such
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Content, or for loss or damage of any kind incurred as
                          a consequence of the transmission, sharing, or posting
                          of such Content by means of Your Coaching Institute
                          App.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={7}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "16pt",
                        "-webkit-text-indent": "-11pt",
                        "text-indent": "-11pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      SECURITY
                    </h4>
                    <ol id="l13">
                      <li data-list-text="7.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The Your Coaching Institute Apps, like other User
                          technologies in the relevant market, may not be 100%
                          secure. By accepting this Agreement, you acknowledge
                          and accept that the Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute Apps and any information you download or
                          offer to share by means of a Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute App, may be exposed to unauthorized access,
                          interception, corruption, damage or misuse and cannot
                          be regarded as 100% secure.
                        </p>
                      </li>
                      <li data-list-text="7.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          You accept all responsibility for such security risks
                          and any damage resulting therefrom. Further, you are
                          solely responsible for securing your mobile device
                          from unauthorized access or cyber-attacks, including
                          by such means as using complex password protection.
                          You agree that Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute shall not be liable for any unauthorized
                          access to your mobile device or the app data thereon.
                        </p>
                      </li>
                      <li data-list-text="7.3.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          In regard to malware protection, Google may receive
                          information regarding your device’s network
                          connections, potentially harmful URLs, the operating
                          system and apps installed on your device through
                          Google Play or from other sources in order to protect
                          you against malicious third-party software, URLs, and
                          other security issues. Besides, Google may warn you if
                          it considers an app or URL to be unsafe, or Google may
                          remove or block its installation on your device if it
                          is known to be harmful to devices, data, or Users. You
                          may choose to disable some of these protections in the
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          settings on your device; nevertheless, Google may
                          continue to receive information about installed
                          through Google Play, and apps installed on your device
                          from other sources may continue to be analyzed for
                          security issues without sending information to Google.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={8}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "16pt",
                        "-webkit-text-indent": "-11pt",
                        "text-indent": "-11pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      REGISTRATION AND PASSWORDS
                    </h4>
                    <ol id="l14">
                      <li data-list-text="8.1.">
                        <h4
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "106%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Registration:{"  "}
                        </h4>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Most of the Your Coaching Institute Apps will not
                          require registration; nevertheless, some of the Your
                          Coaching Institute Apps may permit or require you to
                          create an account to participate or access additional
                          features or functionalities (the “Registration”). If
                          such Registration is required, it will be made known
                          to you when you attempt to participate or access such
                          additional
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          features or functionalities. Any registration required
                          by Third- Party Partners is not governed by this
                          Agreement, and you should refer to the relevant
                          Third-Party Partners’ website for their policies.
                        </p>
                      </li>
                      <li data-list-text="8.2.">
                        <h4
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Passwords:{"  "}
                        </h4>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          You are the sole and exclusive guardian of any
                          password and ID combination issued or chosen by you.
                          Maintaining the confidentiality and security of your
                          password(s) and ID(s) is solely your responsibility.
                          You are fully responsible for all transactions
                          undertaken by means of any account opened, held,
                          accessed, or used via your password and ID. You shall
                          notify us immediately and confirm in writing any
                          unauthorized use of accounts or any breach of
                          security, including without limitation any loss,
                          theft, leak, or unauthorized use of your password(s),
                          and/or ID(s) or any related account. If we have
                          reasonable grounds to suspect that the security of
                          your password and/or ID has been compromised, we may
                          suspend or terminate your account, refuse any and all
                          current or future use of the services, and pursue any
                          appropriate legal remedies. We shall not be
                          responsible for any
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          losses incurred in connection with any misuse of any
                          password or ID.
                        </p>
                      </li>
                      <li data-list-text="8.3.">
                        <h4
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Provided Information:{"  "}
                        </h4>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          If you provide any information in connection with a
                          Registration, you are required to provide or maintain
                          accurate , complete, and current information. If we
                          have reasonable grounds to suspect that your
                          information is inaccurate, not current, or not
                          complete, we may suspend or terminate your use of the
                          Your Coaching Institute App and pursue any appropriate
                          legal remedies. You agree that we shall have the right
                          to use the information you provide to us for the
                          purposes described in this Agreement and in
                          furtherance of your use of the Your Coaching Institute
                          App and our services, as per the Privacy Policy.
                        </p>
                      </li>
                      <li data-list-text="8.4.">
                        <h4
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Authentications:{"  "}
                        </h4>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          User may benefit from the basic functions and Services
                          of EmPowerU App without Registration. Upon User’s sole
                          discretion, User may register to EmPowerU App using
                          authentication methods provided to Your Coaching
                          Institute by Third-Party Partners (such as logging in
                          through the use of Google, Apple, and email accounts).
                          Such Registration allows the User to use and benefit
                          from additional functions and Services provided by
                          EmPowerU App. The responsibility to ensure the
                          security and to duly store the access tools, if any,
                          (connection by Google, Apple, or email account, etc.)
                          used by the User in order to benefit from the Services
                          offered through EmPowerU App and to keep them away
                          from the reach and use of third parties shall belong
                          to the User. Every transaction made with the User’s
                          authenticated account shall be deemed to be made by
                          the User
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          itself. Your Coaching Institute shall not be
                          responsible for any direct or indirect damages
                          incurred by the Users and/or third parties due to all
                          negligence and faults of the User in matters such as
                          security, storage, keeping away the information of
                          third parties, and use of the User’s means of
                          accessing the system and EmPowerU App. Your Coaching
                          Institute shall reserve its right to recourse. Only
                          the Users registered to EmPowerU App following
                          identity authentication may reach certain features of
                          EmPowerU App such as publishing Materials in EmPowerU
                          App for other Users to see and any other feature that
                          may be included in EmPowerU App at Your Coaching
                          Institute’s discretion. User
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          acknowledges that without registering to EmPowerU App
                          in accordance with this provision, he/she will not be
                          able to reach certain features provided to Users
                          registered to EmPowerU App.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={9}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "16pt",
                        "-webkit-text-indent": "-11pt",
                        "text-indent": "-11pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      UNINSTALL AND REMOVAL OF THE YOUR COACHING INSTITUTE APPS
                    </h4>
                    <p
                      style={{
                        "padding-top": "9pt",
                        "padding-left": "5pt",
                        "-webkit-text-indent": "0pt",
                        "text-indent": "0pt",
                        "line-height": "108%",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      Uninstallation and removal procedures vary depending on
                      your device. To uninstall and remove the Your Coaching
                      Institute Apps, please use the application manager
                      provided with your device or consult your device manual
                      for further reference.
                    </p>
                    <p
                      style={{
                        "padding-top": "7pt",
                        "padding-left": "5pt",
                        "-webkit-text-indent": "0pt",
                        "text-indent": "0pt",
                        "line-height": "108%",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      Please note that the text contains various legal and
                      technical details. Ensure that it complies with the
                      specific laws and regulations relevant to your situation
                      and jurisdiction.
                    </p>
                  </li>
                  <li data-list-text={10}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "22pt",
                        "-webkit-text-indent": "-16pt",
                        "text-indent": "-16pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      CONSENT TO USE OF DATA AND USER REVIEWS
                    </h4>
                    <ol id="l15">
                      <li data-list-text="10.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          You agree that we may collect and use technical data
                          and related information, including but not limited to
                          technical information about your device, system, and
                          application software, and peripherals. This data is
                          gathered periodically to facilitate the provision of
                          software updates, product support, and other services
                          related to the Your Coaching Institute Apps. We may
                          use this information in accordance with our Privacy
                          Policy.
                        </p>
                      </li>
                      <li data-list-text="10.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          If you choose to provide app store reviews or reviews
                          via any social media channel or other similar
                          communication or messaging features or services, such
                          information may be made publicly
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          <a
                            href="mailto:info@ihrcoachinginstitut.de"
                            className="a"
                            target="_blank"
                            rel="noreferrer"
                          >
                            available, including your public-facing username as
                            it appears with the review. If you prefer that we do
                            not use your reviews for promotional purposes, you
                            will be able to opt-out by submitting your request
                            to{"  "}
                          </a>
                          info@ihrcoachinginstitut.de (please also include your
                          name, mailing address, and email address). For
                          security purposes, please do not include any
                          passwords, social security numbers,
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          national ID numbers, payment card information, or
                          other sensitive information via these features. We
                          have the right, but not the obligation, to monitor
                          messages and communications between and among users
                          for security and training purposes. We may, but are
                          not obligated to, remove any content we deem
                          inappropriate.
                        </p>
                      </li>
                      <li data-list-text="10.3.">
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          If you download the Your Coaching Institute Apps
                          through Google Play or the App Store, please be aware
                          that posting reviews shall be subject to the relevant
                          virtual store’s policies.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={11}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "22pt",
                        "-webkit-text-indent": "-16pt",
                        "text-indent": "-16pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      INTELLECTUAL PROPERTY RIGHTS
                    </h4>
                    <ol id="l16">
                      <li data-list-text="11.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Your Coaching Institute and Chris Allard Griessmann
                          are proprietor of products and/or Services, projects,
                          documents used in connection with the Your Coaching
                          Institute Apps, and visuals, texts,
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          bulletins, slogans, videos, designs, know-how, and any
                          business data, illustrations, database, system flow
                          data, logo, emblem, and data, ideas, or the Your
                          Coaching Institute trademarks and trade
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          dressing, flows, source codes, research, codes,
                          methods, statistical figures, and financial and moral
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          rights, and all other intellectual property rights
                          during preparations for the Agreement and during its
                          term for the supply of the Your Coaching Institute
                          Services. All rights vested in it under the Law on
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Intellectual and Artistic Works and the applicable
                          legislation in connection with such contents shall be
                          the exclusive property of Your Coaching Institute.
                          Accordingly, the User agrees and represents that they
                          shall not engage in reverse engineering or attempt to
                          find or acquire the source code of the Your Coaching
                          Institute Apps, nor shall they violate the security of
                          any network or crack security
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          encryption codes. They shall not send SPAM emails or
                          load malicious software; otherwise, the User shall be
                          liable for all losses that Your Coaching Institute and
                          third parties may sustain.
                        </p>
                      </li>
                      <li data-list-text="11.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "30pt",
                            "-webkit-text-indent": "-24pt",
                            "text-indent": "-24pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          For the avoidance of doubt, “intellectual property
                          rights” means, collectively, rights under
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          patent, trademark, copyright, and trade secret laws
                          and any other intellectual property or proprietary
                          rights recognized in any country or jurisdiction
                          worldwide, including, without limitation, moral or
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          similar rights. The User may not delete, alter, or
                          remove any copyright, trademark, or other
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          proprietary rights notice that Your Coaching Institute
                          or Third-Party Partners have placed on or within the
                          Your Coaching Institute Apps. Please be aware that all
                          rights not expressly granted hereunder are expressly
                          reserved to Your Coaching Institute and its licensors.
                          Nothing contained herein should be construed as
                          granting, by implication, estoppel, or otherwise, any
                          license or right to use any of our trade names,
                          trademarks, or service marks without our express prior
                          written consent.
                        </p>
                      </li>
                      <li data-list-text="11.3.">
                        <p
                          style={{
                            "padding-top": "7pt",
                            "padding-left": "30pt",
                            "-webkit-text-indent": "-24pt",
                            "text-indent": "-24pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Unless otherwise agreed between Your Coaching
                          Institute and the User regarding any
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          intellectual property rights arising from any Your
                          Coaching Institute Service prepared and provided to
                          the User by Your Coaching Institute, Your Coaching
                          Institute shall grant the right to use the related
                          Your Coaching Institute Services, which shall be
                          worldwide, indefinite, and exclusive. In any case,
                          Your Coaching Institute has the right to determine the
                          ownership of the aforesaid intellectual property
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "justify",
                            "text-align": "justify",
                          }}
                        >
                          rights and its usage. However, if Your Coaching
                          Institute suggests different conditions other than the
                          provisions in this clause of this Agreement, it should
                          notify the User until the Your Coaching Institute
                          Service is used or until the commencement of the
                          operations for the Your Coaching Institute Service.
                        </p>
                      </li>
                      <li data-list-text="11.4.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The User is solely responsible for any content that
                          they contribute, submit, display, or for any
                          adaptations of works made on or through the use of the
                          Your Coaching Institute Apps. It is the User’s
                          obligation to ensure such content, including photos,
                          texts, documents, videos, and music files, are
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          lawful and do not violate any rights, including
                          copyright or other intellectual property rights of
                          Your Coaching Institute, other Users, or any other
                          third persons. For the avoidance of doubt, the User
                          accepts, declares, and undertakes that they are
                          legally entitled to use any data, information, or
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          content they use and that such use does not violate
                          any applicable law or third-party rights. The User
                          accepts, declares, and undertakes to compensate any
                          damage that may have incurred by both Your Coaching
                          Institute and third parties due to the fact that they
                          are not legally entitled to use any data,
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          information, or content they use.
                        </p>
                      </li>
                      <li data-list-text="11.5.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "30pt",
                            "-webkit-text-indent": "-24pt",
                            "text-indent": "-24pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Your Coaching Institute respects and expects its Users
                          to respect the rights of copyright holders.
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          On notice, Your Coaching Institute will act
                          appropriately to remove content that infringes the
                          copyright rights of others. Your Coaching Institute
                          reserves the right to disable access to the Your
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Coaching Institute Apps or other services by anyone
                          who uses them to repeatedly infringe the intellectual
                          property rights of others.
                        </p>
                      </li>
                      <li data-list-text="11.6.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Your Coaching Institute also acts to remove
                          objectionable content. The decision to remove
                          objectionable content shall be made at Your Coaching
                          Institute’s sole discretion. Objectionable content
                          includes but is not limited to content that is
                          unlawful, harmful, threatening, abusive, harassing,
                          tortuous, defamatory, or libelous; content that is
                          hateful or advocates hate crimes, hate speech, and all
                          types of discrimination, harm, or violence against a
                          person, group, or minority;
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          content that may harm minors in any way; content that
                          has the aim or effect of stalking or otherwise
                          harassing or bullying another; private information
                          regarding any individual such as phone numbers,
                          addresses, national ID numbers, Social Security
                          numbers, or any other information that is invasive of
                          another’s privacy; content that is vulgar, offensive,
                          discriminative, obscene, or pornographic,
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          unsolicited or unauthorized advertising, promotional
                          materials, junk mail, SPAM, chain letters, pyramid
                          schemes, or any other form of solicitation; material
                          that contains software viruses or any kind of
                          malicious software or any other computer code, files,
                          or programs designed to interrupt, destroy, or limit
                          the functionality of any computer or mobile device
                          software or hardware or
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          telecommunications equipment. In this regard, Users
                          agree, accept, and undertake not to use Your Coaching
                          Institute Apps:
                        </p>
                        <ul id="l17">
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "8pt",
                                "padding-left": "5pt",
                                "-webkit-text-indent": "0pt",
                                "text-indent": "0pt",
                                "line-height": "108%",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              In any way that violates any applicable national,
                              federal, state, local, or international law or
                              regulation;
                            </p>
                          </li>
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "7pt",
                                "padding-left": "11pt",
                                "-webkit-text-indent": "-5pt",
                                "text-indent": "-5pt",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              For the purpose of exploiting, harming, or
                              attempting to exploit or harm minors in any way;
                            </p>
                          </li>
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "9pt",
                                "padding-left": "5pt",
                                "-webkit-text-indent": "0pt",
                                "text-indent": "0pt",
                                "line-height": "108%",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              To generate or disseminate verifiably false
                              information and/or content with the purpose of
                              harming others;
                            </p>
                          </li>
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "8pt",
                                "padding-left": "5pt",
                                "-webkit-text-indent": "0pt",
                                "text-indent": "0pt",
                                "line-height": "108%",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              To generate or disseminate personally identifiable
                              information that can be used to harm an
                              individual;
                            </p>
                          </li>
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "8pt",
                                "padding-left": "11pt",
                                "-webkit-text-indent": "-5pt",
                                "text-indent": "-5pt",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              To defame, disparage, or otherwise harass others;
                            </p>
                          </li>
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "8pt",
                                "padding-left": "5pt",
                                "-webkit-text-indent": "0pt",
                                "text-indent": "0pt",
                                "line-height": "108%",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              For fully automated decision making that adversely
                              impacts an individual’s legal rights or otherwise
                              creates or modifies a binding, enforceable
                              obligation;
                            </p>
                          </li>
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "8pt",
                                "padding-left": "5pt",
                                "-webkit-text-indent": "0pt",
                                "text-indent": "0pt",
                                "line-height": "108%",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              For any use intended to or which has the effect of
                              discriminating against or harming individuals or
                              groups based on online or offline social behavior
                              or known or predicted personal or personality
                              characteristics;
                            </p>
                          </li>
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "8pt",
                                "padding-left": "5pt",
                                "-webkit-text-indent": "0pt",
                                "text-indent": "0pt",
                                "line-height": "108%",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              To exploit any of the vulnerabilities of a
                              specific group of persons based on their age,
                              social, physical, or mental characteristics, in
                              order to materially distort the behavior of a
                              person pertaining to that group in a manner that
                              causes or is likely to cause that person or
                              another person physical or
                            </p>
                            <p
                              style={{
                                "padding-left": "5pt",
                                "-webkit-text-indent": "0pt",
                                "text-indent": "0pt",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              psychological harm;
                            </p>
                          </li>
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "9pt",
                                "padding-left": "5pt",
                                "-webkit-text-indent": "0pt",
                                "text-indent": "0pt",
                                "line-height": "108%",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              For any use intended to or which has the effect of
                              discriminating against individuals or groups based
                              on legally protected characteristics or
                              categories;
                            </p>
                          </li>
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "8pt",
                                "padding-left": "11pt",
                                "-webkit-text-indent": "-5pt",
                                "text-indent": "-5pt",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              To provide medical advice and medical results
                              interpretation;
                            </p>
                          </li>
                          <li data-list-text="-">
                            <p
                              style={{
                                "padding-top": "9pt",
                                "padding-left": "5pt",
                                "-webkit-text-indent": "0pt",
                                "text-indent": "0pt",
                                "line-height": "108%",
                                "-webkit-text-align": "left",
                                "text-align": "left",
                              }}
                            >
                              To generate or disseminate information for the
                              purpose to be used for administration of justice,
                              law enforcement, immigration, or asylum processes,
                              such as predicting an individual will commit
                              fraud/crime commitment (e.g., by text profiling,
                              drawing causal relationships between assertions
                              made in documents, indiscriminate and
                              arbitrarily-targeted use).
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="11.7.">
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Your Coaching Institute does not and cannot pre-screen
                          or monitor all content. Nevertheless, our
                          representatives may monitor content submission through
                          the Your Coaching Institute Apps, and you hereby
                          provide irrevocable consent to such monitoring. The
                          User acknowledges and agrees that they have no
                          expectation of privacy concerning the submission of
                          any content. Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute has the right, but not the obligation, in
                          its sole discretion, to edit, modify, and refuse to
                          post or remove any content.
                        </p>
                      </li>
                      <li data-list-text="11.8.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The User may not use Google Play or any content or the
                          Your Coaching Institute Apps in conjunction with any
                          stream-ripping, stream capture, or similar software to
                          record or create a copy of any content or additional
                          in-app features that are presented to you in streaming
                          format, if any.
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Besides, the User may not remove any watermarks,
                          labels, or other legal or proprietary notices included
                          in any content or additional in-app features or
                          attempt to modify any content obtained
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          through Google Play, including modification for the
                          purpose of disguising or changing any indications of
                          the ownership or source of content and/or the Your
                          Coaching Institute Apps.
                        </p>
                      </li>
                      <li data-list-text="11.9.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "justify",
                            "text-align": "justify",
                          }}
                        >
                          Your Coaching Institute shall be entitled to terminate
                          the User’s access to the Your Coaching Institute Apps
                          if, under appropriate circumstances in line with the
                          aforementioned provisions, the User is determined to
                          be a repeat infringer.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={12}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "22pt",
                        "-webkit-text-indent": "-16pt",
                        "text-indent": "-16pt",
                        "-webkit-text-align": "justify",
                        "text-align": "justify",
                      }}
                    >
                      RIGHTS INFRINGEMENTS
                    </h4>
                    <p
                      style={{
                        "padding-top": "9pt",
                        "padding-left": "5pt",
                        "-webkit-text-indent": "0pt",
                        "text-indent": "0pt",
                        "line-height": "108%",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      12.1 Your Coaching Institute attaches great importance to
                      confidentiality, intellectual property rights including
                      copyrights and personal data; takes care to be transparent
                      about them. While using Your Coaching Institute Apps,
                      Users declare and undertake to use Your Coaching Institute
                      Apps following the principles in this Agreement and other
                      texts provided to you by Your Coaching Institute. Users
                      shall only upload materials they produce or are authorized
                      to use to Your Coaching Institute Apps. Users declare and
                      undertake to not infringe on any rights of other Users
                      under this Agreement.
                    </p>
                    <p
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "5pt",
                        "-webkit-text-indent": "0pt",
                        "text-indent": "0pt",
                        "line-height": "107%",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      12.2. However, if you believe in good faith that materials
                      transmitted or created through the EmPowerU App infringe
                      your copyright, your personal right, or privacy; you may
                      send Your Coaching Institute a notice requesting that we
                      remove the material or block access to it by filling out
                      the
                    </p>
                    <p
                      style={{
                        "padding-left": "5pt",
                        "-webkit-text-indent": "0pt",
                        "text-indent": "0pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      infringement form.
                    </p>
                  </li>
                  <li data-list-text={13}>
                    <h4
                      style={{
                        "padding-top": "9pt",
                        "padding-left": "22pt",
                        "-webkit-text-indent": "-16pt",
                        "text-indent": "-16pt",
                        "-webkit-text-align": "justify",
                        "text-align": "justify",
                      }}
                    >
                      IN-APP PURCHASE AND PAYMENT
                    </h4>
                    <ol id="l18">
                      <li data-list-text="13.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The release and distribution of the Your Coaching
                          Institute Apps will take place in the global market
                          through the Apple App Store and/or Google Play. The
                          Your Coaching Institute Apps will nevertheless offer
                          certain features and certain limits to the User as a
                          paid feature through in-app
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          purchases. If the User would like to use such paid
                          features under this Agreement, you will first need to
                          make payment before accessing the paid feature.
                        </p>
                      </li>
                      <li data-list-text="13.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Such in-app purchase features are offered on an
                          annual, semi-annual, quarterly, monthly, or a weekly
                          basis and will be re-billed every year or month by the
                          Apple App Store and/or Google Play, depending upon
                          auto-renewable subscription model, until canceled by
                          the User. The Apple App Store and/or Google Play will
                          send an e-mail well in advance of renewal containing a
                          hyperlink to manage the subscription procedure. App
                          payments will be processed through the Apple App Store
                          and/or Google Play from which you originally
                          downloaded the application. You may access the
                          applicable inapp purchase rules and policies directly
                          from the Apple App Store and/or Google Play. You
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          acknowledge and agree that you are fully responsible
                          for managing your in-app purchases and the amount you
                          spend on in-app purchases within the Your Coaching
                          Institute Apps.
                        </p>
                      </li>
                      <li data-list-text="13.3.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Please be aware that, in order to purchase content or
                          the Your Coaching Institute Apps through Google Play,
                          you are required to have a Google Payments account and
                          agree to the Google Payments
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Terms and Terms of Service. The Google Payments
                          Privacy Notice applies whenever you purchase content
                          using a Google Payments account. You are responsible
                          for all amounts payable associated with purchases made
                          through Google Play on your Google Payments account.
                          Besides, Google may make available to you various
                          payment processing methods in addition to Google
                          Payments to
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          facilitate the purchase of content or the Your
                          Coaching Institute Apps through Google Play. You are
                          required to abide by any relevant terms and conditions
                          or other legal agreement, whether with Google or a
                          third party, that governs your use of a given payment
                          processing method. Google may add or remove payment
                          processing methods at its sole discretion. You are
                          solely responsible for all amounts payable associated
                          with purchase you make on Google Play.
                        </p>
                      </li>
                      <li data-list-text="13.4.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "30pt",
                            "-webkit-text-indent": "-24pt",
                            "text-indent": "-24pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          In order to determine your eligibility to have
                          purchases of content or the Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute Apps that you make through your devices
                          billed to your network provider’s account, when you
                          create a Google Play account on a device, Google Play
                          shall send identifiers of your device to your network
                          provider. To permit this you shall need to accept the
                          network provider’s terms of service.
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The network provider may send us your billing address
                          information. Google Play hold and use this information
                          as per Google’s Privacy Policies and Google Payments
                          Privacy Notice.
                        </p>
                      </li>
                      <li data-list-text="13.5.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          If you are under 18 then you are legally required to
                          have your parents’ or legal guardians’ permission to
                          make any in-app purchases. By completing an in-app
                          purchase, you are confirming to us that you have any
                          and all permission that may be necessary in order to
                          allow you to make that inapp purchase. If you are a
                          parent or legal guardian of someone under the age of
                          18, we recommend that you consider any parental
                          control that may be provided by the Apple App Store
                          and/or Google Play, provided that you are concerned
                          that your child may make excessive in-app purchases.
                        </p>
                      </li>
                      <li data-list-text="13.6.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The in-app purchases are purchased from and billed by
                          the Apple App Store or Google Play, not Your Coaching
                          Institute. These purchases are subject to the terms
                          and conditions of the Apple App Store and/or Google
                          Play. All billing and refund inquiries shall be
                          directed to the Apple App Store and/or Google Play.
                          Having said that, Your Coaching Institute does not
                          have access to the Apple App Store and/or Google Play
                          accounts and transactions.
                        </p>
                      </li>
                      <li data-list-text="13.7.">
                        <p
                          style={{
                            "padding-top": "7pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          If any in-app purchase is not successfully downloaded
                          or does not work once it has been successfully
                          downloaded, we will, after becoming aware of the fault
                          or being notified of the fault by you, investigate the
                          reason for the fault. We will act reasonably in
                          deciding whether to provide you with a replacement
                          in-app purchase or issue you with a patch to repair
                          the fault. In no event we will
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          charge you anything further to replace or repair the
                          in-app purchase. In the unlikely event that we are
                          unable to replace or repair the relevant in-app
                          purchase or are unable to do so within a reasonable
                          period of time and without significant inconvenience
                          to you, we will authorize the Apple App Store and/or
                          Google Play to refund you an amount up to the cost of
                          the relevant in-app purchase.
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Alternatively, if you wish to request a refund, you
                          may do so by contacting the Apple App Store and/or
                          Google Play directly.
                        </p>
                      </li>
                      <li data-list-text="13.8.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          You acknowledge and agree that all billing and
                          transaction processes are handled by the Apple App
                          Store and/or Google Play from whose platform you
                          downloaded the Your Coaching Institute Apps and
                          governed by the Apple App Store and/or Google Play’s
                          terms and conditions/end-user license agreement. If
                          you have any payment-related issues with in-app
                          purchases, then you need to contact the Apple App
                          Store and/or Google Play directly.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={14}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "22pt",
                        "-webkit-text-indent": "-16pt",
                        "text-indent": "-16pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      TERM AND TERMINATION
                    </h4>
                    <ol id="l19">
                      <li data-list-text="14.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "justify",
                            "text-align": "justify",
                          }}
                        >
                          This Agreement shall become effective on the date it
                          is approved and shall remain in force as long as the
                          User continues to use EmPowerU App. It shall continue
                          to be effective and operative as between Your Coaching
                          Institute and the User legally.
                        </p>
                      </li>
                      <li data-list-text="14.2.">
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Your Coaching Institute may unilaterally terminate
                          this Agreement without any obligation of compensation
                          and further notice under any circumstance where the
                          User acts in breach of this Agreement or any other
                          agreements executed or rules applicable to different
                          services offered over the Apple App Store and/or
                          Google Play. In particular, following circumstances:
                          if the User
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          manipulates the operation of the Your Coaching
                          Institute Apps by employing any method; if the User
                          acts in breach of the provisions of this Agreement or
                          any other agreements to be executed over the Apple App
                          Store and/or Google Play; if the User commits any act
                          that violates third-party rights; if data, contents,
                          visuals, texts, and articles shared with EmPowerU App
                          by the User have an unlawful element or even if they
                          are free of unlawful or immoral elements, posting such
                          data, contents,
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          visuals, texts, and articles at EmPowerU App for
                          unlawful or immoral purposes.
                        </p>
                      </li>
                      <li data-list-text="14.3.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The User agrees that Your Coaching Institute shall not
                          be liable to the User or any third-party for any
                          termination or disabling of the Your Coaching
                          Institute Apps. Promptly upon termination of this
                          Agreement, the User must cease all use of EmPowerU App
                          and uninstall, remove, or destroy all
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          copies of EmPowerU App in their possession or control.
                          Having said that, termination shall not limit any of
                          Your Coaching Institute’s other rights or remedies at
                          law.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={15}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "22pt",
                        "-webkit-text-indent": "-16pt",
                        "text-indent": "-16pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      INDEMNIFICATION
                    </h4>
                    <ol id="l20">
                      <li data-list-text="15.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          You agree to indemnify and hold harmless Your Coaching
                          Institute, its affiliates, and Your Coaching
                          Institute’s officers, directors, licensors, partners,
                          shareholders, licensees, contractors,
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          agents, attorneys, employees, and third-party service
                          providers (collectively, the “Indemnitees”) from any
                          and all claims, liabilities, costs, and expenses,
                          including reasonable attorneys’ fees (collectively,
                          “Claims”), whether actual or alleged, directly or
                          indirectly resulting from your information, use of the
                          Your Coaching Institute Services, or your breach of
                          this Agreement.
                        </p>
                      </li>
                      <li data-list-text="15.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "30pt",
                            "-webkit-text-indent": "-24pt",
                            "text-indent": "-24pt",
                            "-webkit-text-align": "justify",
                            "text-align": "justify",
                          }}
                        >
                          You agree to be solely responsible for defending any
                          Claim against or suffered by any
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "107%",
                            "-webkit-text-align": "justify",
                            "text-align": "justify",
                          }}
                        >
                          Indemnitee, subject to the relevant Indemnitee’s right
                          to participate with counsel of its own choosing and
                          for payment of damages or losses resulting from all
                          claims against any Indemnitee, provided that you will
                          not agree to any settlement that imposes any
                          obligation or liability on any Indemnitee
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "justify",
                            "text-align": "justify",
                          }}
                        >
                          without Your Coaching Institute’s prior express
                          written consent.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={16}>
                    <h4
                      style={{
                        "padding-top": "9pt",
                        "padding-left": "22pt",
                        "-webkit-text-indent": "-16pt",
                        "text-indent": "-16pt",
                        "-webkit-text-align": "justify",
                        "text-align": "justify",
                      }}
                    >
                      WARRANTY DISCLAIMER
                    </h4>
                    <ol id="l21">
                      <li data-list-text="16.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          To the extent permitted by applicable law, all the
                          Your Coaching Institute Apps are provided on an “AS
                          IS,” “WITH ALL FAULTS,” and “AS AVAILABLE” basis, and
                          you use them at your sole risk. Subject to applicable
                          law, Your Coaching Institute, on behalf of itself and
                          its affiliates, licensors, distributors, vendors,
                          agents, and suppliers, expressly disclaims any and all
                          warranties of any kind, whether
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          express or implied, including but not limited to the
                          implied warranties of merchantability,
                          non-infringement, and any other warranty arising from
                          the relevant legislation.
                        </p>
                      </li>
                      <li data-list-text="16.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "justify",
                            "text-align": "justify",
                          }}
                        >
                          Without limitation, Your Coaching Institute makes no
                          warranty that the Your Coaching Institute Apps will
                          meet your requirements, that they will be
                          uninterrupted, timely, secure, or error-free, that the
                          results obtained from the use of the Your Coaching
                          Institute products will be accurate or reliable, or
                          that the quality of the Your Coaching Institute Apps
                          will meet your expectations. Your Coaching
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Institute assumes no liability or responsibility for
                          any property damage of any nature whatsoever
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          resulting from your access to and use of the Your
                          Coaching Institute Apps, any unauthorized access to or
                          use of our secure servers and/or any and all personal
                          information and/or financial information stored
                          therein, any interruption or cessation of transmission
                          to or from the Your Coaching Institute Apps or
                          servers, any bugs, viruses, Trojan horses, or similar
                          malicious software transmitted to or through the Your
                          Coaching Institute Apps by any third party, or any
                          errors or omissions in any content
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          or for any loss or damage of any kind incurred as a
                          consequence of the use of any content posted, emailed,
                          transmitted, or otherwise made available via the Your
                          Coaching Institute Apps.
                        </p>
                      </li>
                      <li data-list-text="16.3.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Certain Your Coaching Institute Apps may allow you to
                          record phone conversations on your Android or iOS
                          device. Some local, state, federal, and international
                          laws prohibit the recording of third-party audio
                          without the consent of all parties. You are solely
                          responsible for compliance with all local, state,
                          federal, or international laws regarding call
                          recording and obtaining any necessary
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          consent. In no event shall Your Coaching Institute be
                          responsible to you or any third party for your
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          failure to comply with local, state, federal, or
                          international laws regarding third-party audio
                          recording.
                        </p>
                      </li>
                      <li data-list-text="16.4.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The entire risk arising out of use or performance of
                          the Your Coaching Institute Apps remains solely with
                          you. Your Coaching Institute expressly disclaims all
                          warranties relating to products and/or services
                          provided by Third-Party Partners. This warranty
                          disclaimer constitutes an essential part of this
                          agreement.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={17}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "22pt",
                        "-webkit-text-indent": "-16pt",
                        "text-indent": "-16pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      LIMITATION OF LIABILITY
                    </h4>
                    <ol id="l22">
                      <li data-list-text="17.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          To the extent permitted by applicable laws, you
                          expressly understand and agree that Your Coaching
                          Institute shall not be liable for any direct,
                          indirect, incidental, special, consequential, or
                          exemplary damages, including but not limited to
                          damages for loss of profits, goodwill, use, data, or
                          other intangible losses, resulting from: (i) the use
                          or the inability to use the Your Coaching Institute
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Apps; (ii) unauthorized access to or alteration of
                          your transmission or data; (iii) statements or conduct
                          of any third party; or (iv) any other matter relating
                          to Your Coaching Institute.
                        </p>
                      </li>
                      <li data-list-text="17.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          In no event shall Your Coaching Institute’s total
                          liability to you for all damages, losses, and causes
                          of action (whether in contract, tort, or otherwise)
                          exceed the amount paid by you for accessing the Your
                          Coaching Institute App. The foregoing limitations will
                          apply even if the above-stated remedy fails of its
                          essential purpose.
                        </p>
                      </li>
                      <li data-list-text="17.3.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "30pt",
                            "-webkit-text-indent": "-24pt",
                            "text-indent": "-24pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Nothing contained in this Agreement shall be deemed or
                          construed to create any
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          employer/employee relationship under the Labor law of
                          Germany or applicable legislation.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={18}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "22pt",
                        "-webkit-text-indent": "-16pt",
                        "text-indent": "-16pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      FORCE MAJEURE EVENT AND APPLICABLE LAW
                    </h4>
                    <ol id="l23">
                      <li data-list-text="18.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          In all circumstances that constitute a force majeure
                          event in legal terms, Your Coaching Institute shall
                          not be held liable for its failure to perform its
                          obligations hereunder or to perform them late or
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          incompletely as agreed herein. Such failures shall not
                          be considered a default or incomplete or faulty
                          performance, and no claim of compensation shall be
                          made against Your Coaching Institute.
                        </p>
                      </li>
                      <li data-list-text="18.2.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The term “force majeure events” herein refers to any
                          event that is beyond the reasonable control of the
                          affected party and that cannot be avoided despite the
                          reasonable care and diligence shown by Your Coaching
                          Institute, including but not limited to acts of God,
                          riots, insurgences, turmoil, war, communication
                          interruptions, infrastructural and internet network
                          failures, power failures, mobilization, strike, fire,
                          explosion, terrorism, cyber-attack, long-term and
                          far-reaching power outage, internet outage, computer
                          viruses, legislative amendments, and adverse weather
                          conditions.
                        </p>
                      </li>
                      <li data-list-text="18.3.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          The User shall not be able to accrue default interest
                          or claim indemnification from Your Coaching Institute
                          under any name whatsoever for the delayed, incomplete,
                          or non-performance of any of the provisions in this
                          Agreement due to force majeure events.
                        </p>
                      </li>
                      <li data-list-text="18.4.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          This Agreement and legal relations arising hereunder
                          shall be governed and construed as per German law.
                          German Courts (Frankfurt) and Execution Offices shall
                          have jurisdiction over any actual or potential dispute
                          arising from this Agreement. To the maximum extent
                          permitted by law, you
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          hereby consent to the jurisdiction and venue of such
                          courts and waive any objections to such jurisdiction
                          and venue.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={19}>
                    <h4
                      style={{
                        "padding-top": "8pt",
                        "padding-left": "22pt",
                        "-webkit-text-indent": "-16pt",
                        "text-indent": "-16pt",
                        "-webkit-text-align": "left",
                        "text-align": "left",
                      }}
                    >
                      ENTIRE AGREEMENT AND SEVERABILITY
                    </h4>
                    <ol id="l24">
                      <li data-list-text="19.1.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          These Terms constitute the entire agreement between
                          you, as the User, and Your Coaching Institute relating
                          to the use of EmPowerU App and Services and supersede
                          all prior or
                        </p>
                        <p
                          style={{
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          contemporaneous understandings regarding such subject
                          matter.
                        </p>
                      </li>
                      <li data-list-text="19.2.">
                        <p
                          style={{
                            "padding-top": "9pt",
                            "padding-left": "30pt",
                            "-webkit-text-indent": "-24pt",
                            "text-indent": "-24pt",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          No amendment to or modification of this Agreement will
                          be binding unless in writing and
                        </p>
                        <p
                          style={{
                            "padding-top": "1pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          signed by Your Coaching Institute. The failure of
                          either party to enforce any rights granted hereunder
                          or take action against the other party in the event of
                          any breach herein shall not be deemed a waiver by that
                          party as to subsequent enforcement of rights or
                          subsequent actions in the event of future breaches.
                        </p>
                      </li>
                      <li data-list-text="19.3.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          Any translation of this Agreement is done for local
                          requirements and in the event of a dispute between the
                          English and any non-English versions, the English
                          version of this Agreement shall govern to the extent
                          not prohibited by law.
                        </p>
                      </li>
                      <li data-list-text="19.4.">
                        <p
                          style={{
                            "padding-top": "8pt",
                            "padding-left": "5pt",
                            "-webkit-text-indent": "0pt",
                            "text-indent": "0pt",
                            "line-height": "108%",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                          }}
                        >
                          If any term or provision of this Agreement is declared
                          void or unenforceable in a particular situation by any
                          judicial or administrative authority, this declaration
                          shall not affect the validity or enforceability of the
                          remaining terms and provisions hereof or the validity
                          or enforceability of the offending term or provision
                          in any other situation. To the extent possible, the
                          provision will be
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
                <p
                  style={{
                    "padding-left": "5pt",
                    "-webkit-text-indent": "0pt",
                    "text-indent": "0pt",
                    "line-height": "108%",
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  interpreted and enforced to the greatest extent legally
                  permissible in order to effectuate the original intent, and if
                  no such interpretation or enforcement is legally permissible,
                  it shall be deemed severed from the terms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermAndConditions;
