import React from "react";
import "./PreLoader.scss";
import { icons } from "untils/constants";

const PreLoader = () => {
  return (
    <div className="preloader">
      <div className="preloader-overlay" />
      <div className="preloader-bulb">
        <div className="bulb-icon">
          <svg
            width="70"
            height="70"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="30" cy="30" r="28.5" fill="none" stroke="#f3f3f3" strokeWidth={2} />
            <path
              d="M27.8652 21.0553C27.8652 20.4284 28.3734 19.9201 29.0003 19.9201C29.6272 19.9201 30.1355 20.4284 30.1355 21.0553V23.3256C30.1355 23.9525 29.6272 24.4607 29.0003 24.4607C28.3734 24.4607 27.8652 23.9525 27.8652 23.3256V21.0553ZM35.4786 23.2731C35.0353 22.8298 34.3167 22.8298 33.8734 23.2731L32.268 24.8784C31.8247 25.3217 31.8247 26.0404 32.268 26.4837C32.7113 26.927 33.4301 26.927 33.8734 26.4837L35.4786 24.8784C35.9219 24.4351 35.9219 23.7164 35.4786 23.2731ZM24.1273 23.2731C23.684 22.8298 22.9653 22.8298 22.522 23.2731C22.0787 23.7164 22.0787 24.4351 22.522 24.8784L24.1273 26.4837C24.5706 26.927 25.2893 26.927 25.7326 26.4837C26.1759 26.0404 26.1759 25.3217 25.7326 24.8784L24.1273 23.2731ZM29.0003 13.8665C35.0606 13.8665 39.9733 18.7792 39.9733 24.8394C39.9733 28.0118 38.6101 30.9233 35.9427 33.5299C35.8263 33.6437 35.7361 33.7807 35.6776 33.9314L35.6301 34.0863L33.9182 41.4958C33.5811 42.9547 32.3353 44.0116 30.8622 44.1246L30.6001 44.1346H27.401C25.9033 44.1346 24.5928 43.1583 24.1515 41.7481L24.0827 41.4949L22.3731 34.0866C22.3243 33.8748 22.2157 33.6816 22.0602 33.5297C19.5184 31.0473 18.1602 28.2883 18.0366 25.2908L18.0273 24.8394L18.0332 24.4772C18.2243 18.5847 23.0613 13.8665 29.0003 13.8665ZM32.2014 38.8364H25.7977L26.2949 40.9844C26.4018 41.448 26.7841 41.7899 27.2448 41.8536L27.401 41.8644H30.6001C31.0758 41.8644 31.4947 41.5689 31.6606 41.1346L31.7062 40.9847L32.2014 38.8364ZM29.0003 16.1367C24.3057 16.1367 20.4793 19.8539 20.3039 24.5056L20.2976 24.8394L20.3078 25.2587C20.423 27.6248 21.5194 29.8281 23.6464 31.9056C24.0462 32.2961 24.3427 32.7778 24.5116 33.3076L24.5853 33.5762L25.274 36.5662H27.8652V27.1087C27.8652 26.4818 28.3734 25.9735 29.0003 25.9735C29.6272 25.9735 30.1355 26.4818 30.1355 27.1087V36.5662H32.7251L33.4181 33.5752C33.5437 33.0312 33.8009 32.528 34.165 32.1087L34.356 31.9062C36.4819 29.8288 37.5777 27.6252 37.6929 25.2587L37.703 24.8394L37.6967 24.5056C37.5212 19.8539 33.6949 16.1367 29.0003 16.1367Z"
              fill="#f3f3f3"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
