import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { handelResendSendOTP } from "store/slices";

import { useNavigate } from "react-router-dom";
import InputOTP from "components/inputs/InputOtp";
import Button from "components/inputs/Button/Button";
import SliderLayout from "../SliderLayout";
import MoblieNavBar from "pages/Layout/MoblieNavBar";
import { commonRoute, icons } from "untils/constants";
import Card from "components/layouts/Card";
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(300);
  const [loadButton, setLoadButton] = useState(false);
  const [resetOTP, setResetOTP] = useState(true);
  const [otp, setOTP] = useState("");
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);

  const [initialValues, setInitialValues] = useState({});

  const handelVerifyOTP = async (values) => {
    setLoadButton(true);
    localStorage.setItem("otp", otp);
    const Data = {
      email: localStorage.getItem("resetEmail"),
      otp: otp,
      method: "forgot password",
    };
    // const response = await dispatch(verifyOTP(Data));
    // if (response?.status === 200) {
    //   toast.success("OTP verified successfully");
    //   navigate(commonRoute.setPassword);
    //   setResetOTP(true);
    //   setOTP("");
    // } else if (response?.status === 409) {
    //   toast.dismiss();
    //   toast.error("Invalid Otp. Please enter a valid Otp.");
    //   setResetOTP(true);
    // } else if (response?.status === 410) {
    //   toast.dismiss();
    //   toast.error("Otp expired. Please resend the otp.");
    //   setResetOTP(true);
    // }
    navigate(commonRoute.setPassword);
    setLoadButton(false);
  };

  const handleOtp = (newOtp) => {
    if (newOtp) {
      setOTP(newOtp);
    }
  };

  const handleResendButtonClick = async () => {
    if (!isResendButtonDisabled) {
      setIsResendButtonDisabled(true);
      setCounter(300);

      const response = await dispatch(
        handelResendSendOTP({ email: localStorage.getItem("resetEmail") })
      );

      if (response?.status === 201) {
        toast.success("OTP is sent successfully.");
        setResetOTP(true);
        setOTP("");
      }
      // else if (response?.status === 409) {
      //   toast.error("Invalid Otp. Please enter a valid Otp.");
      //   setResetOTP(true);
      // } else if (response?.status === 410) {
      //   toast.error("Otp expired. Please resend the otp.");
      //   setResetOTP(true);
      // }

      setTimeout(() => {
        setIsResendButtonDisabled(false);
      }, 300000);
    }
  };

  useEffect(() => {
    const timer =
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setIsResendButtonDisabled(false);
    }
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    setResetOTP(true);
    setOTP("");
    setTimeout(() => {
      setResetOTP(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetEmail = localStorage.getItem("resetEmail");
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("resetEmail");
    if (!storedEmail) {
      navigate(commonRoute.resetPassword);
    }
  }, []);

  useEffect(() => {
    const storeOtp = localStorage.getItem("otp");
    if (storeOtp) {
      setInitialValues((prev) => ({ ...prev, otp: storeOtp }));
      localStorage.removeItem("otp");
    }
  }, []);

  return (
    <SliderLayout title="">
      <div id="reset-password-container">
        <div className="d-block d-sm-none">
          <MoblieNavBar
            rightIcon={icons.backArrowIcon}
            handleRightClick={() => navigate(-1)}
          />
        </div>
        <Card className="m-auto bg-ffff card-padding pt-4">
          <div className="position-relative f-center mb-4">
            <div className="text-24-600">Verify Your Email</div>
          </div>
          <div className="text-15-400 color-title-navy f-center">
            Enter the OTP you received on your entered email
          </div>
          <div className="text-15-400 color-title-navy mb-4 f-center">
            ({resetEmail})
          </div>
          <div className="mb-4 text-14-500 color-raisin-black text-center">
            Enter verification Code
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={() => {
              handelVerifyOTP();
            }}
            enableReinitialize
          >
            {(props) => {
              const { handleSubmit } = props;
              return (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <div className="center-flex flex-column mb-1">
                    <InputOTP
                      blockSize={6}
                      resetOTP={resetOTP}
                      onSubmit={handleOtp}
                      setResetOTP={setResetOTP}
                      isDisableTyping={loadButton}
                    />
                  </div>

                  <div className="mt-3 mb-5">
                    <div className="d-flex justify-content-around mt-4">
                      <span
                        className={`text-decoration-underline ps-1 ${
                          isResendButtonDisabled ? "text-muted" : "text-primary"
                        }`}
                        role="button"
                        onClick={handleResendButtonClick}
                        style={{
                          cursor: isResendButtonDisabled
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        Resend
                      </span>
                      <span className="color-new-car text-16-500">{` ${formatTime(
                        counter
                      )}`}</span>
                    </div>
                  </div>

                  <div className="ps-5 pe-5 f-center">
                    <Button
                      type="submit"
                      btnText="Verify"
                      btnStyle="primary-dark rounded-pill mw-135 cp-10 border border-dark"
                      onClick={handelVerifyOTP}
                      disabled={loadButton || !otp || (otp && otp.length !== 6)}
                      btnLoading={loadButton}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        </Card>
      </div>
    </SliderLayout>
  );
};
export default VerifyEmail;
