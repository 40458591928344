import React from "react";
import "./RightContainer.scss";
import { icons } from "untils/constants";
import { getDataFromLocalStorage } from "untils/helpers";
const RightContainer = ({ children, id }) => {
  const handleToggle = () => {
    const element = document.getElementsByClassName("sidebar-wrap");
    element[0].classList.toggle("active");
  };

  const isLogin = getDataFromLocalStorage("token");

  return (
    <div className={`${isLogin ? "main-container" : "container"}`}>
      <button className="btn sidebar-toggle" onClick={handleToggle}>
        <img src={icons.menuIcon} alt="menu icon" />
      </button>
      <div id={id}>{children}</div>
    </div>
  );
};
export default RightContainer;
