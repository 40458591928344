const commonRoute = {
  home: "/",
  login: "/login",
  register: "/register",
  resetPassword: "/reset-password",
  VerifyResetPassword: "/Verify-ResetPassword",
  otpModule: "/otp-module",
  setPassword: "/set-password",
  userSettings: "/user-settings",
  newSessions: "/new-session",
  sessionChat: "/session-chat",
  pastSession: "/past-session",
  sessionInfo: "/session-info",
  sessionSummary: "/session-summary",
  sessionMenu: "/session-menu",
  TermsandCondition: "/terms-and-condition",
  TermsandConditions: "/terms-and-conditions",
  DataProtection: "/data-protection",
  DataProtections: "/data-protections",
  subscription: "/subscription",
  UserResetPassword: "/user-reset-password",
  AccountDetail: "/user-detail",
};

export { commonRoute };
