import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/color.scss";
import "./assets/css/common.scss";
import "./assets/css/typography.scss";
import AuthRoutes from "./route/authRoute";
import AppRoutes from "route/appRoute";
import { useDispatch, useSelector } from "react-redux";
import { storeUserAuth } from "store/slices";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

function App() {
  const isUserLogin = localStorage.userData ? true : false;
  const dispatch = useDispatch();
  dispatch(storeUserAuth(isUserLogin));
  const { isAuthenticate } = useSelector((state) => ({
    isAuthenticate: state.auth.isAuthenticate,
  }));

  // useEffect(() => {
  //   const handleBeforeUnload = async () => {
  //     // Perform your API call here
  //     console.log("hello")

  //     debugger
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
  return (
    <>
      <ToastContainer />
      {isAuthenticate ? <AppRoutes /> : <AuthRoutes />}
    </>
  );
}

export default App;
