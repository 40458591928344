import React from "react";
import "./BottomNavigationBar.scss";

const BottomNavigationBar = ({ iconName, handleClick }) => {
  return (
    <>
      {/* This for space */}
      <div className="navigation-space">
        <div className="bottom-navigation">
          <div className="navigation-img-wrapper">
            <img
              className="navigation-addicon"
              src={iconName}
              alt="Navigation Icon"
            />
          </div>
        </div>
      </div>
      {/* This for View */}
      <div className="bottom-navigation">
        <div className="navigation-img-wrapper">
          <img
            className="navigation-addicon"
            src={iconName}
            alt="Navigation Icon"
            onClick={handleClick}
          />
        </div>
      </div>
    </>
  );
};

export default BottomNavigationBar;
