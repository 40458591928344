import Button from "components/inputs/Button/Button";
import Dropdown from "components/inputs/Dropdown/Dropdown";
import Label from "components/inputs/Label";
import PillCheckbox from "components/inputs/PillCheckbox/PillCheckbox";
import TextInput from "components/inputs/TextInput/TextInput";
import Modal from "components/layouts/Modal/Modal";
import { Formik } from "formik";
import moment from "moment";
import BrowserNavbar from "pages/Layout/BrowserNavbar";
import Layouts from "pages/Layout/Layouts";
import MoblieNavBar from "pages/Layout/MoblieNavBar";
import React, { useEffect, useState } from "react";
import { MobileView, isBrowser } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  SavePreferences,
  SaveUserDetails,
  UpdatePreferences,
  UpdateUserDetails,
  fetchPreferenceList,
  fetchUserPreferences,
} from "store/slices";
import { commonRoute, countryList, icons } from "untils/constants";
import * as Yup from "yup";

const AccountDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchPreferenceList());
    dispatch(fetchUserPreferences());
  }, []);

  const { topicList, userDetails } = useSelector((state) => ({
    topicList: state.user.preferenceList,
    userDetails: state.user.userDetails,
  }));
  const genderOption = [
    { id: "female", label: "Female" },
    { id: "male", label: "Male" },
    { id: "diverse", label: "Diverse" },
  ];

  const yearOptions = [];
  for (let index = moment().year(); index >= moment().year() - 120; index--) {
    yearOptions.push({ id: index, label: index });
  }
  const initialValues = {
    firstName: userDetails?.first_name || "",
    YearOfBirth: userDetails?.year_of_birth || "",
    gender: userDetails?.gender || "",
    age: userDetails?.first_Name || "",
    country: userDetails?.country || "",
    topics: userDetails?.preferences || [],
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    topics: Yup.array()
      .min(1, "Select at least one topic.")
      .max(5, "You can select maximum five topics."),
  });
  const handleSubmit = (e) => {
    const payload = {
      first_name: e.firstName,
      year_of_birth: e.YearOfBirth !== "" ? e.YearOfBirth : 0,
      gender: e.gender,
      country: e.country,
    };
    if (userDetails.first_Name !== "") {
      dispatch(UpdateUserDetails(payload));
    } else {
      dispatch(SaveUserDetails(payload));
    }
    if (userDetails.preferences?.length > 0) {
      dispatch(UpdatePreferences({ preferences: e.topics }));
    } else {
      dispatch(SavePreferences({ preferences: e.topics }));
    }
  };

  return (
    <Layouts>
      <MobileView>
        <MoblieNavBar
          rightIcon={icons.backArrowIcon}
          leftIcon={icons.Profile40Icon}
          handleRightClick={() => navigate(-1)}
          handleLeftClick={() => navigate(commonRoute.home)}
        />
      </MobileView>
      <div id="setting-container">
        <div className="row h-100">
          <div className="col-sm-10 mx-auto px-sm-3 px-0">
            <div className="d-flex flex-column h-100">
              <div className="position-relative">
                {isBrowser && <BrowserNavbar />}
                <h4 className="text-center text-32-400 my-2 ">
                  Account details
                </h4>
              </div>
              <div className="b-sm-0000 br-sm-10 p-md-5 p-sm-3 px-0 new-session-block flex-grow-1 d-flex flex-column">
                <div className="d-flex flex-column flex-grow-1">
                  <div className="container-fluid">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                      enableReinitialize={true}
                    >
                      {(props) => {
                        const {
                          values,
                          errors,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          resetForm,
                        } = props;
                        const {
                          firstName,
                          YearOfBirth,
                          gender,
                          country,
                          topics,
                        } = values;
                        return (
                          <form className="row">
                            <div className="mb-2 col-12 col-md-6">
                              <TextInput
                                labelClass="text-16-600 mb-2"
                                isRequired
                                label="First Name"
                                placeholder="First Name"
                                id="firstName"
                                value={firstName}
                                error={errors?.firstName}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="mb-2 col-12 col-md-6">
                              <Dropdown
                                options={genderOption}
                                id="gender"
                                onChange={handleChange}
                                value={gender}
                                placeholder="Gender"
                                label="Gender"
                                labelClass="text-16-600 mb-2"
                                isSearchable={false}
                                error={errors?.gender}
                              />
                            </div>
                            <div className="mb-2 col-12 col-md-6">
                              <Dropdown
                                options={yearOptions}
                                id="YearOfBirth"
                                onChange={handleChange}
                                value={YearOfBirth}
                                placeholder="Birth year"
                                label="Year of Birth"
                                labelClass="text-16-600 mb-2"
                                isSearchable={true}
                                error={errors?.YearOfBirth}
                              />
                            </div>
                            <div className="mb-2 col-12 col-md-6">
                              <Dropdown
                                options={countryList}
                                id="country"
                                onChange={handleChange}
                                value={country}
                                placeholder="Country"
                                label="Country"
                                isSearchable={true}
                                error={errors?.country}
                                labelClass="text-16-600 mb-2"
                              />
                            </div>
                            <div className="mb-2 col-12 ">
                              <Label
                                label="Topics"
                                required
                                className="text-16-600 mb-2"
                              />
                              <div>
                                {topics &&
                                  topicList?.map((ele) => {
                                    return (
                                      <PillCheckbox
                                        label={ele.topic}
                                        isChecked={topics?.includes(ele.topic)}
                                        onChange={(isChecked) => {
                                          if (isChecked) {
                                            setFieldValue("topics", [
                                              ...topics,
                                              ele.topic,
                                            ]);
                                          } else {
                                            setFieldValue(
                                              "topics",
                                              topics?.filter(
                                                (pref) => pref !== ele.topic
                                              )
                                            );
                                          }
                                        }}
                                      />
                                    );
                                  })}
                              </div>
                              {errors?.topics && (
                                <span className="text-13-400 pt-1 d-flex align-items-center gap-2">
                                  <span style={{ color: "red" }}>
                                    {errors?.topics}
                                  </span>
                                </span>
                              )}
                            </div>
                            <div className="d-flex justify-content-end w-100">
                              <div className="mb-4 w-max-200 w-100">
                                <Button
                                  type="submit"
                                  btnText="Continue"
                                  btnStyle="GO"
                                  className="w-100 m-2 bg-eaea position-relative"
                                  text
                                  disabled={errors?.topics}
                                  rightIcon={icons.rightGoIcon}
                                  iconClass="icon-right-end"
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                />
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default AccountDetail;
