import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { commonRoute } from "../untils/constants/routes";
import Login from "pages/Auth/Login/Login";
import Registration from "pages/Auth/Registration/Registration";
import ResetPassword from "pages/Auth/ResetPassword/ResetPassword";
import VerifyEmail from "pages/Auth/Otp_Modal/VerifyOtp";
import VerifyResetPassword from "pages/Auth/ResetPassword/ResetOtpModal";
import SetNewPassword from "pages/Auth/ResetPassword/SetNewPasswod";
import TermsandConditions from "pages/Auth/TermAndConditions";
import DataProtections from "pages/Auth/TermAndConditions/DataProtections";

const AuthRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={commonRoute.TermsandConditions}
          element={<TermsandConditions />}
        />
        <Route
          path={commonRoute.DataProtections}
          element={<DataProtections />}
        />
        <Route path={commonRoute.login} element={<Login />} />
        <Route path={commonRoute.register} element={<Registration />} />
        <Route path={commonRoute.resetPassword} element={<ResetPassword />} />
        <Route
          path={commonRoute.VerifyResetPassword}
          element={<VerifyResetPassword />}
        />
        <Route path={commonRoute.setPassword} element={<SetNewPassword />} />
        <Route path={commonRoute.otpModule} element={<VerifyEmail />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AuthRoute;
