// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#setting-container {
  padding: 35px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
}
#setting-container .btn-block span {
  justify-content: center;
}

@media screen and (max-width: 768px) {
  #setting-container {
    padding: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/App/UserSetting/UserSetting.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,0BAAA;AACF;AAAE;EACE,uBAAA;AAEJ;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":["#setting-container {\n  padding: 35px;\n  display: flex;\n  flex-direction: column;\n  height: calc(100vh - 70px);\n  .btn-block span {\n    justify-content: center;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  #setting-container {\n    padding: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
