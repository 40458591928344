import React from "react";

const SessionStatus = ({count, title , Textclass}) => {
    return <div className="session-block py-md-5 py-sm-3 py-1 shadow  b-0000 px-sm-2 px-1 br-15 text-center">
        <p className="text-24-400">{count}</p>
        <div className={Textclass}>{title}</div>
  </div>;
};

export default SessionStatus; 
