// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#button-container {
  display: flex;
  height: -moz-fit-content;
  height: fit-content;
}
#button-container .btn-block {
  color: var(--0000) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  border: unset;
  outline: none;
  border-radius: 32px;
  padding: 0px 9px;
  min-height: 32px;
}
#button-container .btn-block:disabled {
  opacity: 0.5;
}
#button-container .btn-block:hover {
  background-color: var(--f3f3);
}
#button-container .GO {
  background-color: var(--ffff);
  border: 1px solid var(--0000);
  color: var(--0000);
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/Button/Button.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,wBAAA;EAAA,mBAAA;AACF;AACE;EACE,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACI;EACE,YAAA;AACN;AACI;EACE,6BAAA;AACN;AAEE;EACE,6BAAA;EACA,6BAAA;EACA,kBAAA;AAAJ","sourcesContent":["#button-container {\n  display: flex;\n  height: fit-content;\n\n  .btn-block {\n    color: var(--0000) !important;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 16.94px;\n    border: unset;\n    outline: none;\n    border-radius:32px;\n    padding: 0px 9px;\n    min-height: 32px;\n    // min-width:150px;\n    &:disabled {\n      opacity: 0.5;\n    }\n    &:hover{\n      background-color: var(--f3f3);\n    }\n  }\n  .GO {\n    background-color: var(--ffff);\n    border: 1px solid var(--0000);\n    color: var(--0000);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
