import { createSlice } from "@reduxjs/toolkit";
import { api } from "services/api";
import { handelCatch } from "./globalSlice";
import { setError } from "./authSlice";
import { toast } from "react-toastify";
import { fetchUserDetails } from "./userSlice";
const initialState = {
  subscriptionData: [],
  isSubLoading: false,
  userSubDetails: {},
  appliedPromoDetails: {},
};

const subscriptionSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    SetSubscriptionData(state, action) {
      state.subscriptionData = action.payload;
    },
    SetIsSubLoading(state, action) {
      state.isSubLoading = action.payload;
    },
    SetUserSubscription(state, action) {
      state.userSubDetails = action.payload;
    },
    setAppliedPromoDetails(state, action) {
      state.appliedPromoDetails = action.payload;
    },
  },
});

export const {
  SetSubscriptionData,
  SetIsSubLoading,
  SetUserSubscription,
  setAppliedPromoDetails,
} = subscriptionSlice.actions;

export const getSubscriptionData = (query) => async (dispatch, getState) => {
  try {
    const res = await api.get(`/sub-tiers`);
    if (res) {
      dispatch(SetSubscriptionData(res));
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const VerifyPromocode = (payload) => async (dispatch) => {
  try {
    const res = await api.get(`/verify-promocode/${payload}`);

    if (res?.promo_id) {
      dispatch(setError(""));
      dispatch(setAppliedPromoDetails(res));
      return res;
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const activeFreeSubscription = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/free-subscription`, payload);
    if (res?.status === 201) {
      toast.dismiss();
      toast.success("Subscription activated successfully.");
      dispatch(setError(""));
      return res;
    }
    return res;
  } catch (error) {
    if (error.response.status === 403) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }
    return dispatch(handelCatch(error));
  }
};
export const startSubscriptionByPromo = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/promo-subscription`, payload);
    if (res?.status === 201) {
      toast.dismiss();
      toast.success("Subscription activated successfully.");
      dispatch(setError(""));
      return res;
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getUserSubscription = (payload) => async (dispatch) => {
  try {
    const res = await api.get(`/subscription`, payload);
    dispatch(SetUserSubscription(res));
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const cancelSubscription = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/end-subscription`, payload);
    toast.dismiss();
    toast.success("Subscription cancelled successfully.");
    dispatch(SetUserSubscription({}));
    dispatch(fetchUserDetails());
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export default subscriptionSlice.reducer;
