import React from "react";
import Layouts from "pages/Layout/Layouts";
import MoblieNavBar from "pages/Layout/MoblieNavBar/MoblieNavBar";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { commonRoute, icons } from "untils/constants";
import Button from "components/inputs/Button/Button";
import { useDispatch } from "react-redux";
import { handelLogout } from "store/slices";
import "./DataProtection.scss";

const DataProtections = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <MobileView>
        <MoblieNavBar
          rightIcon={icons.backArrowIcon}
          leftIcon={icons.Profile40Icon}
          handleRightClick={() => navigate(-1)}
          handleLeftClick={() => navigate(commonRoute.home)}
        />
      </MobileView>
      <div>
        <div className="row h-100">
          <div className="col-sm-10 mx-auto px-sm-3 px-0">
            <div className="fb-center border-bottom mt-1 mb-1">
              <div className="f-center">
                <img
                  className="img-logo1 w-120 h-120"
                  src={icons.enpowerLogo}
                  alt="img"
                />
                <span className="text-24-600 cpt-10 logoTest">EmPowerU</span>
              </div>
              <div className="f-center">
                <Button
                  type="submit"
                  btnText="Login"
                  btnStyle="GO"
                  className="w-100 m-2 bg-eaea position-relative cp-15"
                  text
                  //   rightIcon={icons.rightGoIcon}
                  iconClass="icon-right-end"
                  onClick={() => {
                    navigate(commonRoute.login);
                  }}
                />
                <Button
                  type="submit"
                  btnText="Sign Up"
                  btnStyle="GO"
                  className="w-100 m-2 bg-eaea position-relative cp-15"
                  text
                  //   rightIcon={icons.rightGoIcon}
                  iconClass="icon-right-end"
                  onClick={() => {
                    navigate(commonRoute.register);
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-column h-100">
              <h2 className="text-center my-2 ">Data Privacy</h2>
              {/* <div className="b-sm-0000 br-sm-10 p-md-5 p-sm-3 px-0 new-session-block flex-grow-1 d-flex flex-column"> */}
              <div className="d-flex flex-column flex-grow-1">
                {/* <div
                  className="p-2 mt-3"
                  style={{ maxHeight: "700px", overflowY: "auto" }}
                > */}
                <div>
                  <p
                    style={{
                      "padding-top": "9pt",
                      "padding-left": "5pt",
                      "-webkit-text-indent": "0pt",
                      "text-indent": "0pt",
                      "-webkit-text-align": "left",
                      "text-align": "left",
                    }}
                  >
                    "EmPowerU App" Update Date: 15 February 2023
                  </p>
                  <ol id="l1">
                    <li data-list-text={1}>
                      <h4
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "16pt",
                          "-webkit-text-indent": "-11pt",
                          "text-indent": "-11pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Objective
                      </h4>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Your Coaching Institute ("YCI" or "Company") aims to
                        process personal data in accordance with the fundamental
                        principles of privacy and the provisions of the
                        applicable data protection legislation, particularly the
                        Bundesdatenschutzgesetz (BDSG), which is the Federal
                        Data Protection Act in
                      </p>
                      <p
                        style={{
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Germany, and other relevant laws. The personal data
                        provided or to be provided by users to our Company, or
                        obtained by our Company through external means, may be
                        processed by our Company as the "Data Controller."
                      </p>
                      <ul id="l2">
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            With the objective of processing your personal data
                            and within the scope of this purpose, in a limited
                            and proportionate manner,
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            By maintaining the accuracy and currency of the
                            personal data as reported or notified to our
                            Company,
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Your data may be recorded, stored, preserved,
                            reorganized, and transferred to authorities
                            authorized by law to request such personal data.
                            They may also be transferred, categorized, and
                            shared with third parties, both within Germany and
                            abroad, in accordance with the conditions
                          </p>
                          <p
                            style={{
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            stipulated by legislation and with your explicit
                            consent if necessary. Your data may also be
                            processed through other means outlined in the
                            legislation and subject to other procedures
                            established by the
                          </p>
                          <p
                            style={{
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            law.
                          </p>
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            This Privacy Policy is adopted to support and
                            enhance the activities conducted by Your Coaching
                          </p>
                          <p
                            style={{
                              "padding-top": "1pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Institute in alignment with the principles outlined
                            in the BDSG. This Privacy Policy outlines the data
                            we collect, how we intend to use, store, protect,
                            and share the collected data, how you can withdraw
                            your consent for data processing, and how you can
                            correct and revise your data. Capitalized terms in
                            this Policy shall have the meanings specified in the
                            Terms and Conditions, unless defined separately in
                            this Policy.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text={2}>
                      <h4
                        style={{
                          "padding-top": "7pt",
                          "padding-left": "16pt",
                          "-webkit-text-indent": "-11pt",
                          "text-indent": "-11pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Collection of Personal Data and Methods
                      </h4>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "justify",
                          "text-align": "justify",
                        }}
                      >
                        Your Coaching Institute may process personal data for
                        the purposes specified in this Privacy Policy. The
                        personal data of users collected and used by Your
                        Coaching Institute, in particular, include the
                        following: your order information if you make a purchase
                        through in-app purchases, and identifiers for
                        advertisers designated on your mobile device used to
                        access our services (the Identifier for
                      </p>
                      <p
                        style={{
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "justify",
                          "text-align": "justify",
                        }}
                      >
                        Advertisers-IDFA), identifiers for vendors/developers
                        designated on your mobile device (the Identifier for
                        Vendors-IDVF), and Internet Protocol Address-IP Address.
                      </p>
                      <p
                        style={{
                          "padding-top": "8pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Data Categories and Data Types
                      </p>
                      <p
                        style={{
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        <br />
                      </p>
                      <p
                        style={{
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        <br />
                      </p>
                      <p
                        style={{
                          "padding-left": "7pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        <span />
                      </p>

                      <tabel>
                        <tbody>
                          <tr>
                            <td
                              rowSpan={3}
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                width: "60px",
                              }}
                            >
                              {"        "}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid",
                                borderRight: "1px solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              Process Security
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                borderBottom: "0",
                                borderLeft: "0",
                                borderCollapse: "collapse",
                              }}
                            >
                              Internet traffic data (network movement,IP
                              address, visit data, time and date information),
                              device name .In app purchase history. Token Id
                              (When you allow notification through your device)
                              . Identifier for advertisers designated in your
                              mobile device used in accessing our services (if
                              you give a permission, the identifier for
                              Advertisers-IDFA). identifier for
                              vendors/developers designated your mobile device
                              (The identifier for vendors-IDVF){" "}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                borderLeft: "0",
                              }}
                            >
                              Customer Transaction
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid",
                                borderRight: "1px solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              Order Information
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                borderBottom: "1px solid black",
                                borderCollapse: "collapse",
                              }}
                            >
                              Marketing data
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                              }}
                            >
                              IDFA, IDFA
                            </td>
                          </tr>
                        </tbody>
                      </tabel>

                      <p
                        style={{
                          "padding-top": "1pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        We may directly collect the abovementioned data from you
                        through electronic or physical means, your mobile
                        device, third-party applications, or third-party sources
                        through which you can access our application, such as
                        the Apple App Store, Google Play App Store (together
                        referred to as "App
                      </p>
                      <p
                        style={{
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Stores"). This data is collected for the purpose of
                        complying with legal obligations, enhancing our
                        services, administering your use of our services, and
                        enabling you to easily access and navigate our services.
                        We may also collect log data generated while you use our
                        services/applications, either through our products or
                        third-party products. This log data may include
                        information such as your device's Internet Protocol
                        ("IP") address, device name, operating system version,
                        app configuration when using our service/application,
                        the date and time of your use of the
                        service/application, and other relevant statistics.
                      </p>
                      <p
                        style={{
                          "padding-top": "8pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        General Principles Regarding Personal Data Processing
                      </p>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        In accordance with this Privacy Policy, Your Coaching
                        Institute processes personal data as a data controller
                        in line with the basic principles outlined here: (i)
                        compliance with the law and good faith,
                      </p>
                      <p
                        style={{
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        (ii) accuracy and, when necessary, updating, (iii)
                        processing for specific, explicit, and legitimate
                        purposes, (iv) limitation to the purposes for which they
                        are processed, following the principles of data
                        minimization, and (v) retention for the period
                        stipulated in the relevant legislation or required for
                        the purposes for which they are processed.
                      </p>
                    </li>
                    <li data-list-text={3}>
                      <h4
                        style={{
                          "padding-top": "8pt",
                          "padding-left": "16pt",
                          "-webkit-text-indent": "-11pt",
                          "text-indent": "-11pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Purposes of Processing Personal Data and Legal Grounds
                      </h4>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Your personal data will be processed by automatic or
                        non-automatic means for the purposes outlined below, in
                        accordance with applicable legislation and Articles 5
                        and 6 of the BDSG, where expressly permitted by the law,
                        the establishment of a contract, or a direct relation to
                        the execution or performance of the contract and for the
                        legitimate interests of Your Coaching Institute,
                        provided that your fundamental rights and freedoms are
                        protected.
                      </p>
                      <ol id="l3">
                        <li data-list-text="a)">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "16pt",
                              "-webkit-text-indent": "-11pt",
                              "text-indent": "-11pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Purposes of Processing Personal Data
                          </p>
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            In accordance with this text, your personal data are
                            processed for the following purposes, in line with
                            the general conditions mentioned above:
                          </p>
                          <p
                            style={{
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            <br />
                          </p>
                          <p
                            style={{
                              "padding-left": "8pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            <span />
                          </p>
                          <tabel>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    borderTop: "1px solid",
                                    borderRight: "1px solid",
                                    borderCollapse: "collapse",
                                    borderLeft: "1px solid",
                                  }}
                                >
                                  Process Security
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    borderBottom: "0",
                                    borderLeft: "0",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <ul>
                                    <li>
                                      {" "}
                                      execution of activities in compliance with
                                      legislation
                                    </li>
                                    <li>
                                      execution of company/product/service
                                      commitment operations
                                    </li>
                                    <li>
                                      execution of communications activities
                                    </li>
                                    <li>
                                      execution/auditing of buisness activities
                                    </li>
                                    <li>
                                      conducting after-sales support services
                                      for goods/services
                                    </li>
                                    <li>
                                      execution of goods/services sales
                                      processes
                                    </li>
                                    <li>
                                      conducting storage and archive activities
                                    </li>
                                    <li>execution of agreement processes</li>
                                    <li>
                                      execution of information security
                                      processes
                                    </li>
                                    <li>conducting audit/ethical activities</li>
                                    <li>
                                      execution/audit of buisness activities
                                    </li>
                                    <li>
                                      conducting activities to ensure buisness
                                      continuity
                                    </li>
                                    <li>
                                      providing information to authorized
                                      persons, institutions and organizations
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                    borderLeft: "1px solid",
                                  }}
                                >
                                  Customer Transaction
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid",
                                    borderRight: "1px solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <ul>
                                    <li>
                                      execution/auditing of buisness activities
                                    </li>
                                    <li>
                                      conducting after-sales support services
                                      for goods/services
                                    </li>
                                    <li>
                                      execution of good/services sales processes
                                    </li>
                                    <li>
                                      conducting activities for customer
                                      satisfaction
                                    </li>
                                    <li>execution of agreement processes</li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: "1px solid black",
                                    borderCollapse: "collapse",
                                    borderLeft: "1px solid",
                                  }}
                                >
                                  Marketing data
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <ul>
                                    <li>
                                      conducting marketing analysis studies
                                    </li>
                                    <li>
                                      execution of
                                      advertising/campaign/promotion processes
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </tabel>
                          <p />
                          <p
                            style={{
                              "padding-top": "1pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Furthermore, the purposes of processing personal
                            data may be updated in line with our obligations
                            arising from our company policies and legislation,
                            including but not limited to:
                          </p>
                          <ul id="l4">
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "8pt",
                                  "padding-left": "11pt",
                                  "-webkit-text-indent": "-5pt",
                                  "text-indent": "-5pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Creating user accounts for service
                                recipients/application users,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "9pt",
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Customizing our Services, understanding our
                                users and their preferences to enhance the user
                                experience and enjoyment when using our
                                Services, and improving our users' experience,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "8pt",
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Informing about new products, services, and
                                applications, and delivering information
                                regarding advertisements and promotions,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "8pt",
                                  "padding-left": "11pt",
                                  "-webkit-text-indent": "-5pt",
                                  "text-indent": "-5pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Carrying out digital subscription and in-app
                                purchase processes for service recipients,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "9pt",
                                  "padding-left": "5pt",
                                  "-webkit-text-indent": "0pt",
                                  "text-indent": "0pt",
                                  "line-height": "108%",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Conducting auto-renewable subscriptions to
                                provide users access to content, services, or
                                premium features in our service,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "8pt",
                                  "padding-left": "11pt",
                                  "-webkit-text-indent": "-5pt",
                                  "text-indent": "-5pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Managing information security processes,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "9pt",
                                  "padding-left": "11pt",
                                  "-webkit-text-indent": "-5pt",
                                  "text-indent": "-5pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Carrying out activities in accordance with
                                legislation,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "9pt",
                                  "padding-left": "11pt",
                                  "-webkit-text-indent": "-5pt",
                                  "text-indent": "-5pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Fulfilling requests from competent authorities,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "9pt",
                                  "padding-left": "11pt",
                                  "-webkit-text-indent": "-5pt",
                                  "text-indent": "-5pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Managing financial and accounting transactions,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "9pt",
                                  "padding-left": "11pt",
                                  "-webkit-text-indent": "-5pt",
                                  "text-indent": "-5pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Handling communication activities,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "9pt",
                                  "padding-left": "11pt",
                                  "-webkit-text-indent": "-5pt",
                                  "text-indent": "-5pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Managing contract-related processes,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "9pt",
                                  "padding-left": "11pt",
                                  "-webkit-text-indent": "-5pt",
                                  "text-indent": "-5pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Conducting strategic planning activities,
                              </p>
                            </li>
                            <li data-list-text="-">
                              <p
                                style={{
                                  "padding-top": "9pt",
                                  "padding-left": "11pt",
                                  "-webkit-text-indent": "-5pt",
                                  "text-indent": "-5pt",
                                  "-webkit-text-align": "left",
                                  "text-align": "left",
                                }}
                              >
                                Tracking requests and complaints.
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li data-list-text="b)">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "17pt",
                              "-webkit-text-indent": "-11pt",
                              "text-indent": "-11pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Legal Grounds
                          </p>
                        </li>
                      </ol>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        b) Legal Grounds
                      </p>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Third-Party Websites and Applications
                      </p>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        The EMPOWERU App (EMPOWERU) may contain links to other
                        websites that are not affiliated with Your Coaching
                        Institute and whose content is not controlled by us.
                        These linked websites may have
                      </p>
                      <p
                        style={{
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        terms and conditions that differ from those of Your
                        Coaching Institute. We cannot be held responsible for
                        the use or disclosure of information processed by these
                        websites. Similarly, Your Coaching
                      </p>
                      <p
                        style={{
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Institute shall not bear any responsibility for links
                        from other sites to EMPOWERU, which is owned by Your
                        Coaching Institute. We collect information through fair
                        and lawful means, with your knowledge and consent. We
                        also inform you why we are collecting this information
                        and how it will be used. You are free to refuse our
                        request for this information, but please be aware that
                        we may be unable to provide you with some of your
                        desired services without it.
                      </p>
                      <p
                        style={{
                          "padding-top": "7pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Cookies
                      </p>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Cookies are small text files stored on the browser or
                        hard drive of your computer or mobile device when you
                        visit a webpage or application. Cookies allow a website
                        to operate more efficiently and to provide personalized
                        web pages tailored to your specific needs and
                        preferences, resulting in a faster and more personalized
                        browsing experience. Cookies only contain data related
                        to your internet browsing history on the website and do
                        not collect any information, including your personal
                        data/files stored on your computer or mobile device. We
                        may use cookies when necessary to operate our
                      </p>
                      <p
                        style={{
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        services, improve service performance and functionality,
                        and deliver content, including ads relevant
                      </p>
                      <p
                        style={{
                          "padding-top": "1pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        to your interests, on our sites or third-party sites.
                        You can delete cookies already present on your computer
                        and prevent new cookies from being stored on your
                        internet explorer. Internet browsers are typically set
                        to automatically accept cookies as the default setting.
                        As cookie management varies from one browser or
                        application to another, you may refer to the help menu
                        of your browser or application for detailed information.
                      </p>
                      <p
                        style={{
                          "padding-top": "8pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Push Notifications
                      </p>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Your Coaching Institute may occasionally send you push
                        notifications through its mobile applications regarding
                        application upgrades or notifications about our
                        services. You can always modify such communications and
                        notifications through your device's settings and stop
                        receiving them. Your data will be stored for the
                        duration specified in applicable legislation or for a
                        reasonable period until the purpose of processing ceases
                        to exist, or during legal limitation periods. Your
                        Coaching Institute may continue to store your personal
                        data even after the purpose of its use has expired if
                        required by other laws or with separate permission
                        granted by you in this regard. If you grant Your
                        Coaching Institute permission to store your personal
                        data for an additional period, such data will be
                        promptly deleted, destroyed, or anonymized once the
                        additional time expires, or the processing purpose no
                        longer exists.
                      </p>
                      <p
                        style={{
                          "padding-top": "8pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Technical and Administrative Measures
                      </p>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Your Coaching Institute stores the personal data it
                        processes in accordance with relevant legislation, as
                        stipulated in the respective laws or as required for the
                        processing purposes. Your Coaching
                      </p>
                      <p
                        style={{
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Institute is committed to implementing all necessary
                        technical and administrative measures and taking due
                        care to ensure the confidentiality, integrity, and
                        security of personal data. In this context, measures are
                        taken to prevent unauthorized processing of personal
                        data, unauthorized access to data, unlawful disclosure,
                        modification, or destruction of data. To this end, Your
                        Coaching Institute has implemented the following
                        technical and administrative measures concerning the
                        personal data it processes:
                      </p>
                      <ul id="l5">
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "7pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Anti-virus application: A periodically updated
                            anti-virus application is installed on all computers
                            and servers within Your Coaching Institute's
                            information technology infrastructure.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Firewall: The data center and disaster recovery
                            centers hosting Your Coaching Institute servers are
                            protected by periodically updated software-loaded
                            firewalls. The relevant next-generation firewalls
                            control the internet connections of all staff and
                            provide protection against viruses and similar
                            threats during this control.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            VPN: Suppliers can access Your Coaching Institute
                            servers or systems through SSL-VPN defined on
                            Firewalls. A separate SSL-VPN identification has
                            been created for each supplier, ensuring that the
                            supplier only has access to the systems they should
                            use or are authorized to use.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "11pt",
                              "-webkit-text-indent": "-5pt",
                              "text-indent": "-5pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            User identifications: Your Coaching Institute limits
                            employees' authorization to Your Coaching
                          </p>
                          <p
                            style={{
                              "padding-top": "1pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Institute systems only to the extent necessary based
                            on job descriptions. In case of any changes in
                            authority or duties, systemic authorizations are
                            updated accordingly.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Information security threat and event management:
                            Events occurring on Your Coaching Institute servers
                            and firewalls are transferred to the "Information
                            Security Threat and Event Management"
                          </p>
                          <p
                            style={{
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            system. This system alerts responsible staff when a
                            security threat occurs, enabling them to respond
                            immediately to the threat.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "1pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Encryption: Sensitive data is stored using
                            cryptographic methods, and if required, it is
                            transferred through environments encrypted with
                            cryptographic methods. Cryptographic keys are stored
                            in
                          </p>
                          <p
                            style={{
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            secure and separate environments.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "11pt",
                              "-webkit-text-indent": "-5pt",
                              "text-indent": "-5pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Logging: All transaction records concerning
                            sensitive data are securely logged.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Two-factor authentication: Remote access to
                            sensitive data is allowed only through at least
                            two-factor authentication.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Penetration tests: Periodic penetration tests are
                            conducted on servers in the Your Coaching Institute
                            system. Security gaps identified during these tests
                            are addressed, and a verification test is conducted
                            to confirm that the relevant security gaps have been
                            closed. Additionally, the Information Security
                            Threat and Event Management System automatically
                            performs penetration tests, and the results are
                            recorded.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Information Security Management System (ISMS): At
                            ISMS meetings held within Your Coaching Institute,
                            topics from the control forum are audited monthly by
                            the Director of Information
                          </p>
                          <p
                            style={{
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Technology and the Director of Financial Operations.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Training: Regular training sessions are provided to
                            increase Your Coaching Institute employees'
                            awareness of various information security violations
                            and to minimize the impact of the human factor in
                            information violation incidents.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Physical data security: Personal data on paper is
                            stored in lockers and is accessible only to
                            authorized individuals. Adequate security measures
                            are taken based on the nature of the
                          </p>
                          <p
                            style={{
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            environment where sensitive data is stored, such as
                            protection against electrical leakage, fire,
                            flooding, theft, etc.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Backup: Your Coaching Institute periodically backs
                            up the data it stores. Backup mechanisms include
                            those provided by cloud infrastructure providers and
                            in-house solutions developed in compliance
                          </p>
                          <p
                            style={{
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            with relevant legislation and provisions outlined in
                            this Policy.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "106%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Non-disclosure agreements: non-disclosure agreements
                            are concluded with employees involved in processing
                            sensitive personal data.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Transfer of sensitive personal data: If the transfer
                            of sensitive personal data is required via email,
                            such transfers are conducted either through (i)
                            encrypted corporate email or (ii) Registered E-mail.
                            In case personal data is compromised due to attacks
                            on EMPOWERU or the Your Coaching Institute
                          </p>
                        </li>
                      </ul>
                      <p
                        style={{
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        system, despite the necessary information security
                        measures, or if personal data is accessed by
                        unauthorized third parties, Your Coaching Institute
                        promptly informs Users of this situation and, if
                        necessary, informs the relevant data protection
                        authority and takes necessary measures.
                      </p>
                    </li>
                    <li data-list-text={4}>
                      <h4
                        style={{
                          "padding-top": "8pt",
                          "padding-left": "16pt",
                          "-webkit-text-indent": "-11pt",
                          "text-indent": "-11pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Transferring Personal Data to Third Parties
                      </h4>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        The procedures and principles governing the transfer of
                        personal data are regulated by Articles 8 and 9 of the
                        BDSG. The personal and special categories of data may be
                        transferred to third parties, both within Germany and
                        abroad, as we may utilize servers and cloud systems
                        located abroad. Your personal data may be transferred
                        abroad for the following purposes:
                      </p>
                      <ul id="l6">
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "11pt",
                              "-webkit-text-indent": "-5pt",
                              "text-indent": "-5pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Conducting storage and archiving activities
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "11pt",
                              "-webkit-text-indent": "-5pt",
                              "text-indent": "-5pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Conducting business activities
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "11pt",
                              "-webkit-text-indent": "-5pt",
                              "text-indent": "-5pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Providing after-sales support services for
                            goods/services
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "11pt",
                              "-webkit-text-indent": "-5pt",
                              "text-indent": "-5pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Managing customer relationship management processes
                          </p>
                          <p
                            style={{
                              "padding-top": "1pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Your Coaching Institute may also transfer your
                            personal data to service providers of our Company
                            and third parties such as Facebook SDK, Adjust, and
                            Firebase Analytics, which are integrated into our
                          </p>
                          <p
                            style={{
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            service for the following purposes:
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "11pt",
                              "-webkit-text-indent": "-5pt",
                              "text-indent": "-5pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Sharing identity, communication, and transaction
                            security information with authorized public
                          </p>
                          <p
                            style={{
                              "padding-top": "1pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            institutions and organizations for the purpose of
                            complying with legislation, monitoring and executing
                            legal affairs, and informing authorized individuals,
                            institutions, and organizations.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Sharing customer transaction information for
                            managing after-sales support services, conducting
                            business activities, and managing customer
                            relationship management processes.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text={5}>
                      <h4
                        style={{
                          "padding-top": "8pt",
                          "padding-left": "16pt",
                          "-webkit-text-indent": "-11pt",
                          "text-indent": "-11pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Your Rights as the Data Subject
                      </h4>
                      <p
                        style={{
                          "padding-top": "9pt",
                          "padding-left": "5pt",
                          "-webkit-text-indent": "0pt",
                          "text-indent": "0pt",
                          "line-height": "108%",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        In accordance with Article 11 of the BDSG, you may
                        request the following regarding your personal data by
                        applying to Your Coaching Institute:
                      </p>
                      <ul id="l7">
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "11pt",
                              "-webkit-text-indent": "-5pt",
                              "text-indent": "-5pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Determine whether or not your personal data has been
                            processed.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "11pt",
                              "-webkit-text-indent": "-5pt",
                              "text-indent": "-5pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Request information on whether your personal data
                            has been processed.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Learn the purpose of processing personal data and
                            whether data are used in accordance with their
                            purpose.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Identify the third parties, both in Germany and
                            abroad, to whom your personal data has been
                            transferred.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            In the event that personal data is processed
                            incompletely or inaccurately, request notification
                            of the transactions made under this scope to third
                            parties to whom personal data have been transferred.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Request deletion, destruction, or anonymization of
                            personal data if the reasons for processing no
                            longer exist and request notification of the
                            transactions made under this scope to third parties
                            to whom personal data have been transferred.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Object to any result that is to your detriment due
                            to the analysis of personal data exclusively through
                            automated systems.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "107%",
                              "-webkit-text-align": "justify",
                              "text-align": "justify",
                            }}
                          >
                            Request compensation for damages incurred as a
                            result of unlawful processing of your personal data.
                            Where the General Data Protection Regulation (GDPR)
                            is applicable, data subjects have the following
                            rights:
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Right of access: Learn whether personal data is
                            being processed and, if so, access your personal
                            data and information regarding the processing of
                            your personal data.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Right to correction: Request the correction of
                            information that you believe is inaccurate or the
                            completion of information that you believe is
                            incomplete by Your Coaching Institute.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "11pt",
                              "-webkit-text-indent": "-5pt",
                              "text-indent": "-5pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Right to delete: Request the deletion of personal
                            data under the conditions stipulated in GDPR.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "9pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "justify",
                              "text-align": "justify",
                            }}
                          >
                            Right to restrict processing: Request the
                            restriction of the processing of personal data under
                            the conditions stipulated in GDPR.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            Right to object to processing: Object to the
                            processing of personal data under the conditions
                            stipulated in GDPR.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "justify",
                              "text-align": "justify",
                            }}
                          >
                            Right to data portability: Request the data
                            collected by Your Coaching Institute to be
                            transferred directly to another organization or
                            under certain conditions.
                          </p>
                        </li>
                        <li data-list-text="-">
                          <p
                            style={{
                              "padding-top": "1pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "justify",
                              "text-align": "justify",
                            }}
                          >
                            Objection to the occurrence of a result against
                            yourself, by analyzing the processed data
                            exclusively through automatic systems, including
                            profiling.
                          </p>
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "justify",
                              "text-align": "justify",
                            }}
                          >
                            In your application, which should include your
                            explanations about your rights as a data subject and
                            your request to exercise these rights, your request
                            must be explicit and understandable. If your
                          </p>
                          <p
                            style={{
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "justify",
                              "text-align": "justify",
                            }}
                          >
                            request pertains to someone else, you must be
                            specifically authorized to act on their behalf, and
                            your authority must be documented. The application
                            should contain identity and address information, and
                            you should attach documents proving your identity to
                            the application. Our Company will facilitate the
                            submission of such requests through the "Data
                            Subject Application Form" at
                          </p>
                          <p
                            style={{
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            info@ihrCoachinginstitute.de. According to Article
                            13 of the BDSG, our Company will process your
                            requests free of charge and, depending on the nature
                            of the request, will conclude them within 30
                            (thirty) days at the latest. In case your request is
                            denied, the reasons for the rejection, along with
                            justification, will be provided to you in writing or
                            electronically.
                          </p>
                          <p
                            style={{
                              "padding-top": "8pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            If you believe that we or a third party to whom we
                            have transferred your data are violating your
                          </p>
                          <p
                            style={{
                              "padding-top": "1pt",
                              "padding-left": "5pt",
                              "-webkit-text-indent": "0pt",
                              "text-indent": "0pt",
                              "line-height": "108%",
                              "-webkit-text-align": "left",
                              "text-align": "left",
                            }}
                          >
                            rights, you have the option to file a complaint with
                            the data protection authority in your country and
                            other relevant supervisory authorities.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text={6}>
                      <h4
                        style={{
                          "padding-top": "8pt",
                          "padding-left": "16pt",
                          "-webkit-text-indent": "-11pt",
                          "text-indent": "-11pt",
                          "-webkit-text-align": "left",
                          "text-align": "left",
                        }}
                      >
                        Storage and Processing of your data
                      </h4>
                    </li>
                  </ol>
                  <p
                    style={{
                      "padding-top": "9pt",
                      "padding-left": "5pt",
                      "-webkit-text-indent": "0pt",
                      "text-indent": "0pt",
                      "line-height": "108%",
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    Your data is being stored in a database hosted by
                    DigitalOcean (https://www.digitalocean.com) with servers
                    located in Bangalore, India. As we use OpenAI
                    (https://openai.com) to provide the inputs for the coaching
                    interaction, the data provided by you is processed by
                    DigitalOcean as well as OpenAI.
                  </p>
                  <p
                    style={{
                      "padding-top": "8pt",
                      "padding-left": "5pt",
                      "-webkit-text-indent": "0pt",
                      "text-indent": "0pt",
                      "line-height": "108%",
                      "-webkit-text-align": "left",
                      "text-align": "left",
                    }}
                  >
                    In addition to this, please note that your data may also be
                    subject to storage and processing in accordance with the
                    policies of DigitalOcean (https://www.digitalocean.com) and
                    OpenAI (https://openai.com), which may involve data centers
                    and servers located in various regions. For more details
                    regarding the storage and processing of your data by
                    DigitalOcean and OpenAI, you can refer to their respective
                    privacy policies.
                  </p>
                  <p
                    style={{
                      "padding-top": "8pt",
                      "padding-left": "5pt",
                      "-webkit-text-indent": "0pt",
                      "text-indent": "0pt",
                      "line-height": "107%",
                      "-webkit-text-align": "left",
                      "text-align": "left",
                    }}
                  >
                    This Privacy Policy may be revised by our Company when
                    deemed necessary. If you continue to access EMPOWERU and use
                    or access EMPOWERU without benefiting from the Services
                    offered by Your Coaching Institute after the notification
                    period, you shall be deemed to have allowed the changes in
                    this Privacy Policy.
                  </p>
                  <p
                    style={{
                      "padding-top": "8pt",
                      "padding-left": "5pt",
                      "-webkit-text-indent": "0pt",
                      "text-indent": "0pt",
                      "-webkit-text-align": "left",
                      "text-align": "left",
                    }}
                  >
                    Company Title:
                  </p>
                  <p
                    style={{
                      "padding-top": "1pt",
                      "padding-left": "5pt",
                      "-webkit-text-indent": "0pt",
                      "text-indent": "0pt",
                      "-webkit-text-align": "left",
                      "text-align": "left",
                    }}
                  >
                    Your Coaching Institute
                  </p>
                  <p
                    style={{
                      "padding-top": "9pt",
                      "padding-left": "5pt",
                      "-webkit-text-indent": "0pt",
                      "text-indent": "0pt",
                      "-webkit-text-align": "left",
                      "text-align": "left",
                    }}
                  >
                    Address:
                  </p>
                  <p
                    style={{
                      "padding-top": "1pt",
                      "padding-left": "5pt",
                      "-webkit-text-indent": "0pt",
                      "text-indent": "0pt",
                      "-webkit-text-align": "left",
                      "text-align": "left",
                    }}
                  >
                    Senckenberganlage 10–12, 60325 Frankfurt am Main
                  </p>
                  <p
                    style={{
                      "padding-top": "9pt",
                      "padding-left": "5pt",
                      "-webkit-text-indent": "0pt",
                      "text-indent": "0pt",
                      "-webkit-text-align": "left",
                      "text-align": "left",
                    }}
                  >
                    Email:
                  </p>
                  <p
                    style={{
                      "padding-top": "1pt",
                      "padding-left": "5pt",
                      "-webkit-text-indent": "0pt",
                      "text-indent": "0pt",
                      "line-height": "168%",
                      "-webkit-text-align": "left",
                      "text-align": "left",
                    }}
                  >
                    <a
                      href="mailto:info@ihrCoachinginstitute.de"
                      className="a"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@ihrCoachinginstitut
                    </a>
                    e.de Tel: +491741614254
                  </p>
                </div>
              </div>

              {/* {isMobile && (
                    <div className="mt-auto p-2">
                      <Button
                        btnText="Log out"
                        className="b-0000 bg-f3f3  text-16-400 py-3 px-3 br-15 w-100 justify-content-center"
                        onClick={() => dispatch(handelLogout())}
                      ></Button>
                    </div>
                  )} */}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default DataProtections;
